export const stationFragment = `
  id
  title
  order
  gamesCount
  location {
    id
  }
  experience {
    id
  }
`;
export const stationDetailFragment = `
  id
  bookingEnabled
  launcherContentPreviewEnabled
  defaultDashboardElementsEnabled
  themeParkModeEnabled
  timerEnabled
  launcherEnabled
  quitGameOnTimeUp
  idleMode
  idleModeVolume
  steamPath
  locale
  stationGames {
    id
    station { id }
    game {
      id
      title
    }
    status
    targetStatus
    progress
    eta
    enabled
    errorType
    errorMessage
    key {
      id
      key
    }
    updatedAt
  }
  hardware {
    name
    decoratedValue
  }
`;
