<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'UIFormItem',
  props: {
    title: String,
    instructions: {},
    required: Boolean,
    error: Boolean,
    errorMsg: String,
    root: Boolean,
  },
  functional: true,
})
export default class UIFormItem extends Vue {
  render (h, ctx) {
    const getSlot = 'scopedSlots' in ctx ? (name) => ctx.scopedSlots[name] && ctx.scopedSlots[name]() : (name) => ctx.slots()[name]; // for backward compat : some Vue version might not have this API available
    const els = [
      ...(ctx.props.title || getSlot('title')
        ? [
          h('span', { ...ctx.data, staticClass: 'label' }, (
            (ctx.props.required)
              ? [
                h('span', getSlot('title') || ctx.props.title),
                h('span', { staticClass: 'required' }, '*'),
              ]
              : getSlot('title') || ctx.props.title
          )),
        ]
        : []
      ),
      ...(ctx.children || []),
      ...(ctx.props.error && ctx.props.errorMsg
        ? [h('small', { ...ctx.data, staticClass: 'error' }, ctx.props.errorMsg)]
        : []
      ),
      ...(ctx.props.instructions || getSlot('instructions')
        ? [h('small', { ...ctx.data, staticClass: 'instructions' }, getSlot('instructions') || ctx.props.instructions)]
        : []
      ),
    ];
    let vnode = els;
    if (ctx.props.root) vnode = h('div', ctx.data, els);
    return vnode;
  }
}
</script>

<style scoped lang="postcss">
@import "../../styles";

.label {
  @apply --f8;
  /* margin-bottom: .25rem; */
  margin-bottom: var(--spacingXS);
  display: block;
}
.required {
  color: var(--colorSBRedFlat);
}
.instructions {
  @apply --f7;
  display: block;
  margin-top: var(--spacingXS);
}
.error {
  @apply --f7;
  color: var(--colorErrorFlat);
}
</style>
