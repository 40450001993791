import DateHelper from '@/core/shared/helpers/DateHelper';
import moment from 'moment';

const firstOfNextMonth = moment(DateHelper.getClientNowMoment())
  .startOf('month')
  .add(1, 'months')
  .format('LL')
  .replace(/\s/g, '&nbsp;');

export default {
  state: {
    firstOfNextMonth,
  },
};
