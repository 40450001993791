import { gql } from '@/core/shared/api';

const waiverContentFragment = `
id
location {
  id
}
startsAt
endsAt
language
content
askForMarketingConsent
`;

export const getLocationWaiverContents = async ({ commit, dispatch }, locationId) => {
  commit('setLoading', true);
  commit('waiverContents/set', { path: 'isFetchingWaiverContents', value: true });

  const query = `{
    waiverContents (location: { id: "${locationId}" }) {
      ${waiverContentFragment}
    }
  }`;

  try {
    const data = await gql.request(query);

    dispatch('insertEntities', {
      name: 'WaiverContent',
      payload: data.waiverContents,
    });

    return data.waiverContents;
  } finally {
    commit('setLoading', false);
    commit('waiverContents/set', { path: 'isFetchingWaiverContents', value: false });
  }
};

export const storeWaiverContent = async ({ getters, dispatch, commit }, waiverContent) => {
  commit('setLoading', true);
  const query = `
    mutation($waiverContent: WaiverContentInput) {
      storeWaiverContent(waiverContent: $waiverContent) {
        ${waiverContentFragment}
      }
    }
  `;

  try {
    const data = await gql.request(query, { waiverContent });
    dispatch('removeEntities', {
      name: 'WaiverContent',
      payload: waiverContent,
    });
    dispatch('insertEntities', {
      name: 'WaiverContent',
      payload: data.storeWaiverContent,
    });
    waiverContent = data.storeWaiverContent;
    return data.storeWaiverContent;
  } finally {
    commit('setLoading', false);
  }
};

export const deleteWaiverContent = async ({ dispatch }, waiverContent) => {
  const query = `
    mutation($waiverContent: WaiverContentInput) {
      deleteWaiverContent(waiverContent: $waiverContent) {
        id
      }
    }
  `;

  dispatch('removeEntities', {
    name: 'WaiverContent',
    payload: waiverContent,
  });

  const data = await gql.request(query, { waiverContent });
  return data.deleteWaiverContent;
};
