import { gql } from '@/core/shared/api';

const gameFragment = `
id
title
description
imageUrl
videoUrl
keysTotal
keysUsed
keysAvailable
cds
license {
  endsAt
  startsAt
  id
  upcomingInvoiceAmount {
    displayValue
  }
  currentTotalUsage {
    displayValue
  }
}
ownership {
  id
  cds
}
costEstimateMonth {
  displayValue
  value
}
headsetHtcVive,
headsetWindowsMr,
headsetOculusRift,
headsetValveIndex,
installErrorMessage
cdsTransitionError
agreementError
updatedAt
__typename
`;
const contentOwnershipFragment = `
id
title
description
shortDescription
cds
imageUrl
videoWebmUrl
videoMp4Url
responsiveImageUrls {
  url
  width
}
freeDownload
headsetHtcVive
headsetOculusRift
headsetWindowsMr
headsetValveIndex
displayExperienceType
ageRating
difficulty
singleplayer
multiplayer
developers
publishers
genres {
  id
  title
}
minPlayers
maxPlayers
upcomingPrice {
  displayPriceMinute
  priceMinute
  startsAt
}
currentPrice {
  displayPriceMinute
  priceMinute
}
minutePrice {
  current {
    startsAt
    endsAt
    price
    displayPrice
  }
  upcoming {
    startsAt
    price
    displayPrice
  }
}
monthlyPrice {
  current {
    startsAt
    endsAt
    price
    displayPrice
  }
  upcoming {
    startsAt
    price
    displayPrice
  }
}
yearlyPrice {
  current {
    startsAt
    endsAt
    price
    displayPrice
  }
  upcoming {
    startsAt
    price
    displayPrice
  }
}
operatorGame {
  ${gameFragment}
}
operatorLicense {
  id
  promptForCurrentLicense
  promptForUpcomingLicense
  disabledForOutOfDateAgreement
}
lbeVersion
communityUrl
contentGuideUrl
reviewPros
reviewCons
currentAgreement {
  id
  content
  endsAt
  startsAt
  updatedAt
  isCurrent
}
upcomingAgreement {
  id
  content
  endsAt
  startsAt
  updatedAt
  isCurrent
}
latestVersionDate
marketingAssetsUrl
releaseDate
updatedAt
rating {
  percentage
  text
  count
}
developerSupportEmail
badgeTop10
badgeRecentlyUpdated
percentageOff
systemRequirements
contentOwnershipLanguages {
  displayLanguage
  interface
  fullAudio
  subtitles
}
regionalPricing
__typename
bundle {
  id
}
`;
const bundleFragment = `
  id
  title
  slug
  description
  imageUrl
  contentOwnership {
    ${contentOwnershipFragment}
    organization {
      id
      name
    }
  }
  contents {
    discountPercentage
    contentOwnership {
      ${contentOwnershipFragment}
      organization {
        id
        name
      }
    }
  }
`;
export async function marketplaceQuery ({
  page = 0,
  filterCds,
  sortBy,
  sortDirection,
  search,
  filterContentTarget,
  filterFeatured,
  filterPromoted,
  filterRegional,
  filterBundles,
  filterGenres,
} = {}, { cancelKey = 'getMarketplace' } = {}) {
  const query = `
  query (
    $page: Int
    $filterCds: Boolean
    $filterContentTarget: String
    $sortBy: String
    $sortDirection: String
    $search: String
    $filterFeatured: Boolean
    $filterPromoted: Boolean
    $filterRegional: Boolean
    $filterBundles: Boolean
    $filterGenres: [GenreInput]
  ) {
    marketplaceContentOwnerships (
      page: $page
      filterCds: $filterCds
      filterContentTarget: $filterContentTarget
      sortBy:$sortBy
      sortDirection: $sortDirection
      search: $search
      filterFeatured: $filterFeatured
      filterPromoted: $filterPromoted
      filterRegional: $filterRegional
      filterBundles: $filterBundles
      filterGenres: $filterGenres
    ) {
        paginatorInfo {
          currentPage
          hasMorePages
          lastPage
          perPage
          total
        }
        contentOwnerships: data {
          ${contentOwnershipFragment}
        }
      }
    }
  `;
  return gql.request(
    query,
    {
      page,
      filterCds,
      sortBy,
      sortDirection,
      search,
      filterContentTarget,
      filterFeatured,
      filterPromoted,
      filterRegional,
      filterBundles,
      filterGenres,
    },
    { cancelKey },
  );
}
export const fetchContentOwnership = async (
  { commit, dispatch, state },
  id,
) => {
  commit('setLoading', true);
  const query = `{
    contentOwnership (
      contentOwnership: { id: ${JSON.stringify(id)} }
    ) {
      ${contentOwnershipFragment}
    }
  }`;
  try {
    const data = await gql.request(query, undefined, {
      cancelKey: 'contentOwnership',
    });
    if (data) {
      const { contentOwnership } = data;
      await dispatch('insertEntities', {
        name: 'ContentOwnership',
        payload: contentOwnership,
      });
      return data;
    }
  } finally {
    commit('setLoading', false);
  }
};
export const getMarketplace = async (
  { commit, dispatch, state },
  { page = 0 } = {},
) => {
  commit('setLoading', true);
  try {
    const data = await marketplaceQuery(
      {
        page,
        filterCds: state.filterOptions.filterCds,
        sortBy: state.filterOptions.sortType,
        sortDirection: state.filterOptions.sortDirection,
        search: state.filterOptions.search,
        filterContentTarget: state.filterOptions.filterContentTarget,
        filterGenres: state.filterOptions.filterGenres && state.filterOptions.filterGenres.map(id => ({ id })),
        filterPromoted: state.filterOptions.filterPromoted,
        filterRegional: state.filterOptions.filterRegional,
        filterBundles: state.filterOptions.filterBundles,
      },
    );
    if (data) {
      const {
        contentOwnerships,
        paginatorInfo,
      } = data.marketplaceContentOwnerships;
      commit('marketplace/set', {
        path: 'paginatorInfo',
        value: paginatorInfo,
      });
      const result = await dispatch('insertEntities', {
        name: 'ContentOwnership',
        payload: contentOwnerships,
      });
      commit('marketplace/set', {
        path: 'contentOwnershipIds',
        value: result,
        merge: true,
      });
      return data;
    }
  } finally {
    commit('setLoading', false);
  }
};

export const clearMarketplace = async ({ commit, dispatch, state }) => {
  commit('marketplace/set', {
    path: 'paginatorInfo',
    value: null,
  });
  commit('marketplace/set', {
    path: 'contentOwnershipIds',
    value: [],
  });
};

export const fetchBundleWithOperatorLicense = async (
  { commit, dispatch },
  id,
) => {
  commit('setLoading', true);
  const query = `{
    bundleWithOperatorLicense (id: ${JSON.stringify(id)}) {
      ${bundleFragment}
      licenses {
        id
        operator {
          id
        }
      }
    }
  }`;
  try {
    const data = await gql.request(query, undefined, {
      cancelKey: 'bundle',
    });
    if (data) {
      const { bundleWithOperatorLicense } = data;
      await dispatch('insertEntities', { name: 'Bundle', payload: bundleWithOperatorLicense });
      return bundleWithOperatorLicense;
    }
  } finally {
    commit('setLoading', false);
  }
};

export const fetchBundle = async (
  { commit, dispatch, state },
  id,
) => {
  commit('setLoading', true);
  const query = `{
    bundle (id: ${JSON.stringify(id)}) {
      ${bundleFragment}
    }
  }`;
  try {
    const data = await gql.request(query, undefined, {
      cancelKey: 'bundle',
    });
    if (data) {
      const { bundle } = data;
      await dispatch('insertEntities', { name: 'Bundle', payload: bundle });
      return bundle;
    }
  } finally {
    commit('setLoading', false);
  }
};

// Used for Game model websocket event
export const getGame = async ({ dispatch }, gameInput) => {
  const query = `query game ($id: ID) {
    game (id: $id) {
      ${gameFragment}
    }
  }`;
  const { game } = await gql.request(query, { id: gameInput.id });

  dispatch('insertEntities', {
    name: 'Game',
    payload: game,
  });

  return game;
};

export const createGameLicense = async (
  { commit, dispatch },
  { gameId, startsAt },
) => {
  commit('setLoading', true);
  const query = `
    mutation {
      createGameLicense (gameId: "${gameId}", startsAt: "${startsAt}") {
        game {
          ${gameFragment}
        }
      }
    }
  `;

  const data = await gql.request(query);
  commit('setLoading', false);
  dispatch('getMyLicenses');
  if (data) {
    commit('addToGame', { id: gameId, game: data.createGameLicense.game });
  }
  return data;
};

export const deactivateGameLicense = async (
  { commit, dispatch },
  licenseId,
) => {
  commit('setLoading', true);
  const query = `
    mutation {
      deactivateGameLicense (licenseId: "${licenseId}") {
        game {
          ${gameFragment}
        }
      }
    }
  `;

  const data = await gql.request(query);
  commit('setLoading', false);
  dispatch('getMyLicenses');
  if (data) {
    commit('addToGame', {
      id: data.deactivateGameLicense.game.id,
      game: data.deactivateGameLicense.game,
    });
  }
  return data;
};

export const reactivateGameLicense = async (
  { commit, dispatch },
  licenseId,
) => {
  commit('setLoading', true);
  const query = `
    mutation {
      reactivateGameLicense (licenseId: "${licenseId}") {
        game {
          ${gameFragment}
        }
      }
    }
  `;

  const data = await gql.request(query);
  commit('setLoading', false);
  dispatch('getMyLicenses');
  if (data) {
    commit('addToGame', {
      id: data.reactivateGameLicense.game.id,
      game: data.reactivateGameLicense.game,
    });
  }
  return data;
};
// TODO remove
export const requestKeys = async (
  { commit, dispatch },
  { gameId, locationId, stationId },
) => {
  commit('setLoading', true);
  let args = `gameId: "${gameId}"`;
  if (locationId) args = args + `, locationId: "${locationId}"`;
  if (stationId) args = args + `, stationId: "${stationId}"`;
  const query = `
    mutation {
      allocateGameKey(${args}) {
        id
        key
      }
    }
  `;
  const data = await gql.request(query);
  commit('setLoading', false);
  dispatch('getMyLicenses');
  dispatch('getLocations');
  return data.allocateGameKey;
};
