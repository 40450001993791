import { crossEnv } from '@/core/shared/setup/configureEnvironment';

function invoiceTypeToText (invoiceType) {
  if ((invoiceType & 32) !== 0) {
    return 'Credit Note';
  } else if ((invoiceType & 1) !== 0) {
    return 'Subscription Fees';
  } else if ((invoiceType & 2) !== 0) {
    return 'License Purchase';
  } else if ((invoiceType & 4) !== 0) {
    return 'License Renewal';
  } else if ((invoiceType & 8) !== 0) {
    return 'Monthly Licensing Fees';
  } else if ((invoiceType & 16) !== 0) {
    return 'Payout';
  }
}

export default {
  strict: true,
  actions: {
    getNetToken: async ({ rootState, getters, commit }) => {
      if (rootState.auth.netToken) {
        return rootState.auth.netToken;
      }
      const email = getters.currentUser.email;
      const uuid = getters.currentOrganization.id;

      const axios = require('axios');
      const r = await axios.get(`https://${crossEnv.API_URL}/v1/public/net/token/${email}/${uuid}`, {
        headers: {
          Authorization: `Bearer ${rootState.auth.token}`,
          'Content-Type': 'application/json',
          'X-Tenant-Id': rootState.auth.tenantId,
          'X-Transaction-ID': crossEnv.TRANSACTION_ID,
        },
      }).then(function (response) {
        if (response.status === 200) {
          commit('setNetToken', response.data.token);
          return response.data.token;
        } else {
          commit('setNetToken', null);
          return '';
        }
      });

      return r;
    },
    netGetRequest: async ({ dispatch, commit }, { url }) => {
      const axios = require('axios');
      const netToken = await dispatch('getNetToken');
      let baseUrl = `https://${crossEnv.MAIN_NET_URL}`;
      if (crossEnv.ENV === 'development') {
        baseUrl = `http://${crossEnv.MAIN_NET_URL.replace(':7', ':5')}`;
      }

      return axios.get(`${baseUrl}${url}`, {
        headers: {
          Authorization: `Bearer ${netToken}`,
          'Content-Type': 'application/json',
        },
      }).catch(error => {
        if (error.response && error.response.status === 500) {
          commit('setFlash', {
            type: 'error',
            message: 'An error has occurred. If problems persist, please contact support.',
          });
        } else if (error.response) {
          commit('setFlash', {
            type: 'error',
            message: error.response.message,
          });
        };
      });
    },
    netPostRequest: async ({ dispatch, commit }, { url, data }) => {
      const axios = require('axios');

      const netToken = await dispatch('getNetToken');
      let baseUrl = `https://${crossEnv.MAIN_NET_URL}`;
      if (crossEnv.ENV === 'development') {
        baseUrl = `http://${crossEnv.MAIN_NET_URL.replace(':7', ':5')}`;
      }

      return axios.post(`${baseUrl}${url}`, data, {
        headers: {
          Authorization: `Bearer ${netToken}`,
          'Content-Type': 'application/json',
        },
      }).catch(error => {
        if (error.response && error.response.status === 500) {
          commit('setFlash', {
            type: 'error',
            message: 'An error has occurred. If problems persist, please contact support.',
          });
        } else if (error.response) {
          commit('setFlash', {
            type: 'error',
            message: error.response.message,
          });
        };
      });
    },
    getNetInvoices: async ({ dispatch, commit, getters }) => {
      const token = await dispatch('getNetToken');

      const organization = getters.currentOrganization;
      const axios = require('axios');
      // TODO: Rewrite to use netGetRequest
      let baseUrl = `https://${crossEnv.NET_URL}`;
      if (crossEnv.ENV === 'development') {
        baseUrl = `http://${crossEnv.NET_URL.replace(':7', ':5')}`;
      }
      await axios.get(`${baseUrl}/organization/invoices/${organization.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(function (response) {
        const result = [];
        response.data.forEach(i => {
          result.push({
            id: i.id,
            invoiceNumber: i.number,
            date: i.date,
            type: invoiceTypeToText(i.type),
            status: i.status,
            paidAt: i.paidAt ? Date.parse(i.paidAt) : '',
            invoiceDate: Date.parse(i.date),
            total: '$' + i.amountInclVat.toLocaleString(
              undefined, // Leave undefined to use the visitor's browser locale or a string like 'en-US' to override it.
              { minimumFractionDigits: 2 },
            ),
            negTotal: '$' + (-i.amountInclVat).toLocaleString(
              undefined, // Leave undefined to use the visitor's browser locale or a string like 'en-US' to override it.
              { minimumFractionDigits: 2 },
            ),
            downloadLink: `https://${crossEnv.API_URL}/invoice/${i.id}`,
          });
        });
        commit('setNetInvoices', result);
        return result;
      });
    },
  },
  state: {
    netToken: null,
    netInvoices: [],
  },
  mutations: {
    setNetToken: (state, value) => {
      state.netToken = value;
    },
    setNetInvoices: (state, value) => {
      state.netInvoices = value;
    },
  },
  getters: {
    netToken: state => {
      return state.netToken;
    },
    netInvoices: state => {
      return state.netInvoices;
    },
  },
};
