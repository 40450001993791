import { gql } from '@/core/shared/api';
import EventBus from '@/core/shared/services/EventBus';
import { initSBVRPermissions } from '@/core/shared/setup/permissions';
import { initLaunchDarkly } from '@/core/shared/setup/launchDarkly';

export const initUser = async ({
  commit,
  state,
  dispatch,
  rootState,
  rootGetters,
}) => {
  const organization = await dispatch('getOrganizationBasics', `
      contactEmail
      subType
      billingStatus
      contentOnly
      locations {
        id
        title
        address
        address2
        city
        country
        state
        postalCode
        waiversRequired
        integrations {
          type
        }
      }
      isActive
      isAccessSuspended
      stripePaymentMethod
      isOnTrial
      hasMarketplaceAccess
      hasOrderedMonthlyYearly
      trialExpiry
      currentStationCount
      subscriptionExclusion
      creditCardExpired
      paymentErrorDetail {
        message
        failedCount
        suspended
      }
  `,
  );

  const user = rootGetters.currentUser;

  if (organization.type === 'developer') {
    commit('setFlash', {
      type: 'error',
      message: 'You are not an Operator',
    });
    dispatch('logout');
  }

  initSBVRPermissions(user, organization);
  initLaunchDarkly(user, organization);

  const query = `{
    currentOrganization {
      contentOwnershipLicensesPendingAgreements {
        id
        operatorGame { id }
      }
      contentOwnershipLicensesPendingAgreementDate
    }
  }`;

  const afterInitData = gql.request(query);

  dispatch('insertEntities', {
    name: 'Organization',
    payload: afterInitData.currentOrganization,
  });
};

export function goToBilling () {
  EventBus.$emit('router:push', { name: 'billing' });
}

export const goToLibrary = async ({ commit }) => {
  EventBus.$emit('router:push', { name: 'content-library' });
};

export const goRequireEula = async ({ commit }, game) => {
  EventBus.$emit('router:push', {
    name: 'content-library',
    query: { requireEula: game.id },
  });
};

export const fetchAllTags = async ({ dispatch }) => {
  const query = `{
    tags {
      id
      text
    }
  }`;
  const { tags } = await gql.request(query);
  dispatch('insertEntities', {
    name: 'Tag',
    payload: tags,
  });
};

export const onStationGameUpdate = async ({ dispatch }, stationGame) => {
  const game = { id: stationGame.gameId };
  const station = { id: stationGame.stationId };
  stationGame.game = game;
  stationGame.station = station;
  delete stationGame.gameId;
  delete stationGame.stationId;
  dispatch('insertEntities', {
    name: 'StationGame',
    payload: stationGame,
  });
};
