import { gql } from '@/core/shared/api';

export const createCurrencyModel = schema => {
  return new schema.Entity('Currency', {}, {
    idAttribute: 'code',
  });
};

export default {
  strict: true,
  actions: {
    getCurrencies: async ({ commit, dispatch }) => {
      commit('setLoading', true);
      commit('currency/set', { path: 'isFetchingCurrencies', value: true });

      try {
        const query = `{
          currencies {
            code
            name
          }
        }`;

        const { currencies } = await gql.request(query);

        dispatch('insertEntities', {
          name: 'Currency',
          payload: currencies,
        });
      } finally {
        commit('setLoading', false);
        commit('currency/set', { path: 'isFetchingCurrencies', value: false });
      }
    },
  },
  state: {
    isFetchingCurrencies: false,
  },
  mutations: {
    'currency/set': (state, { path, value }) => {
      state[path] = value;
    },
  },
};
