/**
 * Generate a unique id
 */
export function generateId (numbers = 7) {
  const uniqueKey = Math.random()
    .toString(36)
    .substr(2, 2 + numbers); // should be unique because of the seed
  return `_${uniqueKey}`;
}
/**
 * Generate a UUID
 */
export function generateUUID (a) {
  return a
    ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
    : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, generateUUID);
}
/**
 * Shallow copy an object keeping property descriptors aka getters/setters
 */
export function cloneWithGetters (obj) {
  return Object.create(
    Object.getPrototypeOf(obj),
    Object.getOwnPropertyDescriptors(obj),
  );
}
