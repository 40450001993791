export const stationFragment = `
  id
  title
  order
  gamesCount
  locale
  idleModeVolume
  launcherEnabled
  idleMode
  location {
    id
  }
  experience {
    id
  }
`;
