import { gql } from '@/core/shared/api';
import { debounce, difference, get } from 'lodash-es';
import { catchQueryErrors } from '@/core/shared/helpers/ErrorHelper';
import { gameFragmentWithDefer, gameFragmentWithDetails } from './fragments';
import { i18n } from '@/core/setup/i18n';

export const fetchBundleLicenses = async ({ commit, dispatch, getters }) => {
  commit('setLoading', true);
  commit('library/set', {
    path: 'isFetchingBundleLicenses',
    value: true,
  });

  const query = `
    {
      bundleLicenses {
        id
        quantity
        operator {
          id
          name
        }
        bundle {
          id
          title
          contentOwnership {
            id
            imageUrl
            responsiveImageUrls {
              url
              width
            }
            upcomingPrice {
              displayPriceMonth
              priceMonth
              startsAt
            }
            currentPrice {
              displayPriceMonth
              priceMonth
            }
          }
          contents {
            id
            discountPercentage
            contentOwnership {
              id
              title
            }
          }
        }
      }
    }`;
  try {
    const { bundleLicenses } = await gql.request(query);
    dispatch('insertEntities', { name: 'BundleLicense', payload: bundleLicenses });
    return bundleLicenses;
  } finally {
    commit('library/set', {
      path: 'isFetchingBundleLicenses',
      value: false,
    });
    commit('setLoading', false);
  }
};

const fetchGames = (vars = {}, throwAbortError = false) => {
  const { search = '' } = vars;
  const query = `
    {
      games (
        search: ${JSON.stringify(search)}
      ) {
        id
        title
        imageUrl
        responsiveImageUrls {
          url
          width
        }
        __typename
      }
    }
  `;
  return gql.request(query, vars, {
    cancelKey: 'getMyLicenses',
    throwAbortError,
  });
};

export const lazyLoadGames = debounce(async ({ state, commit, dispatch }) => {
  if (!state.gamesLoading.length) return;
  const query = `query ($ids: [ID]) {
    games: organizationGamesById(ids: $ids) {
      ${gameFragmentWithDefer}
    }
  }`;

  async function callback (data, done) {
    const { games } = data;
    const result = await dispatch('insertEntities', { name: 'Game', payload: games });
    if (done) {
      commit('library/set', {
        path: 'gamesLoading',
        value: difference(state.gamesLoading, result),
      });
      commit('library/set', {
        path: 'gamesFullyLoaded',
        value: [...state.gamesFullyLoaded, ...result],
      });
    }
    return result;
  }

  await gql.requestWithDefer(
    query,
    { ids: state.gamesLoading },
    { cancelKey: 'lazyLoadGames' },
    callback,
  );
}, 500);

export const getGamesCount = async ({ commit, dispatch }) => {
  const query = `
    {
      games: organizationGames(count:-1, page:1){
        paginatorInfo {
          perPage
        }
      }
    }
  `;
  const { games } = await gql.request(query);
  commit('library/set', {
    path: 'paginatorInfo',
    value: games.paginatorInfo,
  });
  return games;
};
export const getMyLicenses = async ({ commit, dispatch }) => {
  commit('setLoading', true);
  commit('library/set', {
    path: 'isFetchingLicenses',
    value: true,
  });
  try {
    dispatch('getGamesCount');
    const data = await fetchGames();
    if (data) {
      const result = await dispatch('insertEntities', {
        name: 'Game',
        payload: data.games,
      });
      commit('library/set', {
        path: 'games',
        value: result,
      });
      return data.games;
    }
  } finally {
    commit('library/set', {
      path: 'isFetchingLicenses',
      value: false,
    });
    commit('setLoading', false);
  }
};
const debouncedSearchLicenses = debounce(
  async ({ commit, dispatch, state }) => {
    let isCanceled;
    try {
      const data = await fetchGames({ search: state.search }, true);
      if (data) {
        const result = await dispatch('insertEntities', {
          name: 'Game',
          payload: data.games,
        });
        commit('library/set', {
          path: 'searchResults',
          value: result,
        });
        return data.games;
      }
    } catch (e) {
      if (e.name === 'AbortError') {
        isCanceled = true;
      }
    } finally {
      if (!isCanceled) {
        commit('setLoading', false);
        commit('library/set', {
          path: 'isFetchingLicenses',
          value: false,
        });
      }
    }
  },
  300,
);
export const searchLicenses = async ({ commit, dispatch, state }) => {
  commit('setLoading', true);
  commit('library/set', {
    path: 'isFetchingLicenses',
    value: true,
  });
  return debouncedSearchLicenses({ commit, dispatch, state });
};

export const saveGamesOrder = async ({ commit, dispatch, state }) => {
  commit('setLoading', true);
  const gameIds = state.games;
  const games = gameIds.map((id, i) => ({ id, order: i }));
  const query = `mutation ($games: [GameInput]) {
    games: updateGamesOrder(games: $games) {
      id
      order
    }
  }`;
  try {
    const data = await gql.request(
      query,
      { games },
      { cancelKey: 'saveGamesOrder' },
    );
    if (data) {
      const result = Object.values(data.games).map(({ id }) => id);
      commit('library/set', {
        path: 'games',
        value: result,
      });
      return result;
    }
  } finally {
    commit('setLoading', false);
  }
};

export const getAllLocations = async ({ commit, dispatch }) => {
  const query = `{
    currentOrganization {
      locations {
        id
        stations {
          id
          title
          experience { id }
        }
        experiences {
          id
          title
          location { id }
        }
      }
    }
  }`;
  const data = await gql.request(query);
  const result = await dispatch('insertEntities', {
    name: 'Location',
    payload: data.currentOrganization.locations,
  });
  return result;
};

export const fetchGame = async ({ commit, dispatch }, { id }) => {
  const query = `
    {
      game (id: ${JSON.stringify(id)}) {
        ${gameFragmentWithDetails}
      }
      locations {
        id
        stations {
          id
          title
        }
        experiences {
          id
          title
        }
      }
    }
  `;
  const data = await gql.request(query, null, { cancelKey: 'fetchGame' });
  if (data) {
    await dispatch('insertEntities', {
      name: 'Location',
      payload: data.locations,
    });
    const result = await dispatch('insertEntities', {
      name: 'Game',
      payload: data.game,
    });
    return result;
  }
};

export const purchaseBundle = async (
  { commit, dispatch },
  order,
) => {
  const result = await dispatch('netPostRequest', { url: '/bundle/purchase', data: order }).then(function (response) {
    // GenericResult enum
    if (response.data === 1) {
      commit('setFlash', { type: 'info', message: i18n.t('The titles in the bundle are being registered and will appear in your library shortly.') });
    } else if (response.data === 2) {
      commit('setFlash', { type: 'info', message: i18n.t('The bundle has been successfully added.') });
    }
    return true;
  }).catch(error => {
    commit('setFlash', { type: 'error', message: i18n.t('An error occurred: "{error}". Please contact our support team.', { error: error.message }) });
    return false;
  });

  return result;
};

export const bundlePurchaseMinimumLicenseQuantities = async (
  { commit, dispatch },
  bundleId,
) => {
  const result = await dispatch('netGetRequest', { url: `/bundle/get-purchase-model/${bundleId}` }).then(function (response) {
    return response.data;
  }).catch(error => {
    commit('setFlash', { type: 'error', message: i18n.t('An error occurred: {error} Please contact our support team.', { error: error.message }) });
    return false;
  });

  return result;
};

export const addContentOwnershipToLibrary = async (
  { commit, dispatch },
  contentOwnership,
) => {
  const query = `
    mutation ($contentOwnership: ContentOwnershipInput) {
      contentOwnershipLicense: createContentOwnershipLicense(contentOwnership: $contentOwnership) {
        id
        promptForCurrentLicense
        promptForUpcomingLicense
        operatorGame {
          ${gameFragmentWithDetails}
        }
      }
    }
  `;
  const { contentOwnershipLicense } = await gql.request(query, {
    contentOwnership: { id: contentOwnership.id },
  });
  await dispatch('insertEntities', {
    name: 'ContentOwnershipLicense',
    payload: contentOwnershipLicense,
  });
  const { operatorGame } = contentOwnershipLicense;
  return operatorGame;
};

export const removeGameFromLibrary = async (
  { commit, dispatch, rootGetters, state },
  game,
) => {
  let result;
  const sucessMsg = i18n.t(
    'The content was successfully deleted from your Library.',
  );
  const sucessMsgCDS = `${sucessMsg} ${i18n.t('Uninstalling is in progress.')}`;
  const failMsg = i18n.t('There was an error, please try again.');
  try {
    const query = `
        mutation ($game: GameInput) {
          game: deleteGame(game: $game) {
            id
          }
        }
      `;
    const { game: deletedGame } = await gql.request(query, {
      game: { id: game.id },
    });
    // optimistic update
    commit('library/set', {
      path: 'games',
      value: state.games.filter(id => id !== game.id),
    });
    result = await dispatch('removeEntities', {
      name: 'Game',
      payload: deletedGame,
    });
    commit('setFlash', {
      type: 'success',
      message: game.cds ? sucessMsgCDS : sucessMsg,
    });
    return result;
  } catch (e) {
    catchQueryErrors(e, () => {
      commit('setFlash', {
        type: 'error',
        message: failMsg,
      });
    });
  }
};

export const persistGame = async ({ dispatch }, game) => {
  const { _imageBlob, _videoBlob, ...gameToSave } = game;
  const query = `
    mutation ($game: GameInput) {
      game: storeGame(game: $game) {
        ${gameFragmentWithDetails}
      }
    }
  `;
  const variables = {
    game: {
      ...gameToSave,
      imageBlobFieldName: _imageBlob && _imageBlob.name,
      videoBlobFieldName: _videoBlob && _videoBlob.name,
    },
  };
  const blobs = [_imageBlob, _videoBlob].filter(v => !!v);
  const data = await gql.requestWithBlobs(query, variables, blobs);
  const result = await dispatch('insertEntities', {
    name: 'Game',
    payload: data.game,
  });
  return result;
};

export const searchPresets = async ({ state, dispatch }, search) => {
  const query = `
        query ($search: String) {
          games: presetGames (search: $search) {
            id
            title
            imageUrl
          }
        }
      `;
  const variables = {
    search,
  };
  const data = await gql.request(query, variables, {
    cancelKey: 'searchPresets',
  });
  if (data) {
    const { games } = data;
    return games;
  }
};

export const acceptContentOwnershipAgreement = async (
  { commit, dispatch },
  agreement,
) => {
  const query = `
    mutation ($contentOwnershipAgreement: ContentOwnershipAgreementInput) {
      contentOwnershipLicense: acceptContentOwnershipAgreement (contentOwnershipAgreement: $contentOwnershipAgreement) {
        id
        promptForCurrentLicense
        promptForUpcomingLicense
        disabledForOutOfDateAgreement
        ownership {
          id
          title
        }
      }
    }
  `;
  const variables = {
    contentOwnershipAgreement: {
      id: agreement.id,
    },
  };
  let hasSucceeded;
  let flashMsg = i18n.t('There was an error');
  try {
    const { contentOwnershipLicense } = await gql.request(query, variables);
    const { ownership } = contentOwnershipLicense;
    flashMsg = i18n.t('You accepted {name}’s new End User License Agreement.', {
      name: ownership.title,
    });
    dispatch('insertEntities', {
      name: 'ContentOwnershipLicense',
      payload: contentOwnershipLicense,
    });
    hasSucceeded = true;
    return contentOwnershipLicense;
  } finally {
    commit('setFlash', {
      type: hasSucceeded ? 'success' : 'error',
      message: flashMsg,
    });
  }
};

export const getGameAgreement = async ({ commit, dispatch }, game) => {
  const { id } = game;
  const query = `
    query ($id: ID) {
      game (id: $id) {
        id
        title
        ownership {
          id
          currentAgreement {
            id
            content
            startsAt
            endsAt
            updatedAt
            isCurrent
          }
          upcomingAgreement {
            id
            content
            startsAt
            endsAt
            updatedAt
            isCurrent
          }
          operatorLicense {
            id
            promptForCurrentLicense
            promptForUpcomingLicense
            disabledForOutOfDateAgreement
          }
          operatorGame {
            id
          }
        }
      }
    }
  `;
  const variables = {
    id,
  };
  const data = await gql.request(query, variables);
  await dispatch('insertEntities', {
    name: 'Game',
    payload: data.game,
  });
  return data.game;
};

export const getCreditCardLast4 = async ({ commit, dispatch, getters }) => {
  const alreadyInStore = get(
    getters,
    'currentOrganization.defaultCreditCard.last4',
  );
  if (alreadyInStore) return alreadyInStore;
  const query = `
    query {
      currentOrganization {
        id
        defaultCreditCard {
          last4
        }
      }
    }
    `;
  const data = await gql.request(query);
  await dispatch('insertEntities', {
    name: 'Organization',
    payload: data.currentOrganization,
  });
  return data.currentOrganization;
};

export const storeLicensesOrder = async (
  { commit, dispatch },
  licensesOrder,
) => {
  const query = `
    mutation ($licensesOrder: LicensesOrderInput!) {
      order: storeLicensesOrder (licensesOrder: $licensesOrder) {
        id
        subtotal { displayValue }
        total {
          displayValue
          value
        }
        items {
          id
          amount {
            displayValue
          }
          unitAmount {
            displayValue
          }
          fullUnitAmount {
            displayValue
          }
          type
          classification
          proration
          quantity
          expiryDate
          renewalDate
        }
        yearlyCancelItemsCount
        yearlyRenewalItemsCount
      }
    }
  `;
  const variables = {
    licensesOrder,
  };
  const data = await gql.request(query, variables);
  return data.order;
};

export const createNetLicensePurchaseInvoice = async (
  { commit, dispatch },
  licensesOrder,
) => {
  const query = `
    mutation ($licensesOrder: LicensesOrderInput!) {
      invoice: createNetLicensePurchaseInvoice (licensesOrder: $licensesOrder) {
        id
        number
        amountExclVat
        amountVat
        amountInclVat
      }
    }
  `;
  const variables = {
    licensesOrder,
  };
  const invoice = await gql.request(query, variables);
  return invoice;
};

export const completeLicensesOrder = async (
  { commit, dispatch },
  licensesOrder,
) => {
  const query = `
    mutation ($licensesOrder: LicensesOrderInput!) {
      order: completeLicensesOrder (licensesOrder: $licensesOrder) {
        id
        subtotal { displayValue }
        total {
          displayValue
          value
        }
        items {
          id
        }
      }
    }
  `;
  const variables = {
    licensesOrder,
  };
  const data = await gql.request(query, variables);
  return data.order;
};

export const getGameLicenseDetails = async ({ commit, dispatch }, game) => {
  const query = `
    query ($id: ID) {
      game (id: $id) {
        license {
          id
          currentMinuteCost {
            total {
              displayValue
            }
          }
          currentMonthlyCost {
            quantity
            total {
              displayValue
            }
          }
          currentMinuteUsage {
            displayValue
            value
          }
          currentMonthlyUsage {
            displayValue
            value
          }
          currentYearlyUsage {
            displayValue
            value
          }
          upcomingInvoiceAmount {
            displayValue
          }
          monthlyLicenses {
            id
            startsAt
            endsAt
            renewable
          }
          yearlyLicenses {
            id
            startsAt
            endsAt
            renewsAt
            renewable
          }
        }
      }
      currentOrganization {
        id
        hasOrderedMonthlyYearly
      }
    }
  `;
  const variables = {
    id: game.id,
  };
  const data = await gql.request(query, variables);
  await dispatch('insertEntities', {
    name: 'Game',
    payload: data.game,
  });
  await dispatch('insertEntities', {
    name: 'Organization',
    payload: data.currentOrganization,
  });
  return data.game;
};
