import Vue from 'vue';
import Router from 'vue-router';
import { sync } from 'vuex-router-sync';
import deepmerge from 'deepmerge';
import authRoutes from '@/core/shared/auth/auth-routes';
import contentNewModule from '@/content/library/store/modules/content-new';

import store from '@/core/store';

Vue.use(Router);

const appRoutes = [
  {
    name: 'home',
    path: '/home',
    component: () => import('@/Home'),
  },
  {
    name: 'my-station',
    path: '/my-station',
    component: () => import(/* webpackChunkName: "my-station" */ '@/my-station/MyStationRoute'),
    children: [
      {
        name: 'my-station-index',
        path: 'index',
        component: () => import(/* webpackChunkName: "my-station" */ '@/my-station/MyStationIndexRoute'),
      },
      {
        name: 'my-station-show',
        path: ':stationId',
        props: true,
        redirect: { name: 'my-station-show-details' },
        component: () => import(/* webpackChunkName: "my-station" */ '@/my-station/MyStationShowRoute'),
        children: [
          {
            name: 'my-station-show-details',
            path: 'details',
            props: true,
            component: () => import(/* webpackChunkName: "my-station" */ '@/my-station/show/MyStationShowDetailsRoute'),
          },
          {
            name: 'my-station-show-content',
            path: 'content',
            props: true,
            component: () => import(/* webpackChunkName: "my-station" */ '@/my-station/show/MyStationShowContentRoute'),
          },
        ],
      },
    ],
  },
  {
    name: 'users',
    path: '/users',
    component: () => import(/* webpackChunkName: "admin" */ '@/admin/users/UsersRoute'),
    redirect: { name: 'user-index' },
    children: [
      {
        name: 'user-index',
        path: 'index',
        component: () => import(/* webpackChunkName: "admin" */ '@/admin/users/UsersIndexRoute'),
      },
      {
        name: 'user-show',
        path: ':userId',
        props: true,
        component: () => import(/* webpackChunkName: "admin" */ '@/admin/users/UserShowRoute'),
      },
    ],
  },
  {
    name: 'location',
    path: '/:locationId(\\w{8}-\\w{4}-\\w{4}-\\w{4}-\\w{12})',
    component: () => import('@/location/Location'),
    props: true,
    children: [
      {
        name: 'station',
        path: 'station',
        props: true,
        component: () => import(/* webpackChunkName: "station" */ '@/location/stations/StationRoute'),
        children: [
          {
            name: 'station-index',
            path: 'index',
            props: true,
            component: () => import(/* webpackChunkName: "station" */ '@/location/stations/StationIndexRoute'),
          },
          {
            name: 'station-show',
            path: ':stationId',
            props: true,
            redirect: { name: 'station-show-details' },
            component: () => import(/* webpackChunkName: "station" */ '@/location/stations/StationShowRoute'),
            children: [
              {
                name: 'station-show-details',
                path: 'details',
                props: true,
                component: () => import(/* webpackChunkName: "station" */ '@/location/stations/show/StationShowDetailsRoute'),
              },
              {
                name: 'station-show-content',
                path: 'content',
                props: true,
                component: () => import(/* webpackChunkName: "station" */ '@/location/stations/show/StationShowContentRoute'),
              },
            ],
          },
        ],
      },
      {
        name: 'experiences-index',
        path: 'experiences',
        props: true,
        component: () =>
          import(/* webpackChunkName: "experience" */ '@/location/experiences/components/ExperiencesIndex'),
      },
      {
        name: 'experiences-new',
        path: 'experiences/new',
        beforeEnter (to, from, next) {
          next({ name: 'experiences-show', params: { id: 'new' } });
        },
      },
      {
        name: 'experiences-show',
        path: 'experiences/:id',
        props (to) {
          return {
            ...to.params,
            id: to.params.id === 'new' ? null : to.params.id,
          };
        },
        redirect: { name: 'experience-details' },
        component: () =>
          import(/* webpackChunkName: "experience" */ '@/location/experiences/components/ExperiencesShow'),
        children: [
          {
            name: 'experience-details',
            path: 'details',
            component: () =>
              import(/* webpackChunkName: "experience" */ '@/location/experiences/components/ExperiencesShow/ExperiencesShowDetails'),
          },
          {
            name: 'experience-pricing',
            path: 'pricing',
            component: () =>
              import(/* webpackChunkName: "experience" */ '@/location/experiences/components/ExperiencesShow/ExperiencesShowPricing'),
          },
          {
            name: 'experience-refunds',
            path: 'refunds',
            component: () =>
              import(/* webpackChunkName: "experience" */ '@/location/experiences/components/ExperiencesShow/ExperiencesShowRefunds'),
          },
          {
            name: 'experience-confirmation-text',
            path: 'confirmation',
            component: () =>
              import(/* webpackChunkName: "experience" */ '@/location/experiences/components/ExperiencesShow/ExperiencesShowConfirmationText'),
          },
          {
            name: 'booking-config',
            path: 'bookingConfig',
            component: () =>
              import(/* webpackChunkName: "experience" */ '@/location/experiences/components/ExperiencesShow/ExperiencesShowBookingConfig'),
          },
          {
            name: 'experience-launcher-settings',
            path: 'launcher',
            component: () =>
              import(/* webpackChunkName: "experience" */ '@/location/experiences/components/ExperiencesShow/ExperienceShowLauncherSettings'),
          },
        ],
      },
      {
        name: 'promos',
        path: 'promos',
        props: true,
        component: () =>
          import(/* webpackChunkName: "promos" */ '@/location/promos/PromosRoute'),
        children: [
          {
            name: 'coupon-index',
            path: 'coupons',
            props: true,
            component: () =>
              import(/* webpackChunkName: "promos" */ '@/location/promos/coupon/CouponIndexRoute'),
          },
          {
            name: 'coupon-show',
            path: 'coupon/:couponId',
            props: true,
            component: () =>
              import(/* webpackChunkName: "promos" */ '@/location/promos/coupon/CouponShowRoute'),
          },
          {
            name: 'discount-index',
            path: 'discounts',
            props: true,
            component: () =>
              import(/* webpackChunkName: "promos" */ '@/location/promos/discount/DiscountIndexRoute'),
          },
          {
            name: 'discount-show',
            path: 'discount/:discountId',
            props: true,
            component: () =>
              import(/* webpackChunkName: "promos" */ '@/location/promos/discount/DiscountShowRoute'),
          },
        ],
      },
      {
        name: 'widget-index',
        path: 'widgets',
        props: true,
        component: () =>
          import(/* webpackChunkName: "widget" */ '@/location/widgets/WidgetIndexRoute'),
      },
      {
        name: 'widget-show',
        path: 'widget/:widgetId',
        props: true,
        component: () =>
          import(/* webpackChunkName: "widget" */ '@/location/widgets/WidgetShowRoute'),
      },
      {
        name: 'location-analytics',
        path: 'analytics',
        props: true,
        component: () => import(/* webpackChunkName: "location-analytics" */ '@/location/analytics/LocationAnalyticsRoute'),
      },
      {
        name: 'location-bookings',
        path: 'bookings',
        props: true,
        component: () => import(/* webpackChunkName: "location-bookings" */ '@/location/bookings/LocationBookingsIndexRoute'),
      },
      {
        name: 'waiver-content-index',
        path: 'waiver',
        props: true,
        component: () =>
          import(/* webpackChunkName: "waiver-content" */ '@/location/waiver-contents/WaiverContentIndexRoute'),
      },
      {
        name: 'waiver-content-show',
        path: 'waiver/:waiverContentId',
        props: true,
        component: () =>
          import(/* webpackChunkName: "waiver-content" */ '@/location/waiver-contents/WaiverContentShowRoute'),
      },
      {
        name: 'waivers',
        path: 'waivers',
        props: true,
        component: () =>
          import(/* webpackChunkName: "waivers" */ '@/location/waivers/WaiversIndexRoute'),
      },
    ],
  },
  {
    name: 'content',
    path: '/content',
    component: {
      render (h) {
        return h('router-view', undefined, this.$slots.default);
      },
    },
    children: [
      {
        path: 'marketplace',
        component: {
          created () {
            const { q, filters } = store.state.route.query;
            if (q) {
              this.$store.commit('marketplace/set', {
                path: 'filterOptions.search',
                value: q,
              });
            }
            if (filters) {
              const [
                filterContentTarget,
                filterCds,
                sortType,
                sortDirection,
                filterGenres,
                filterPromoted,
                filterRegional,
                filterBundles,
              ] = filters.split(':');
              const filterQueries = {
                sortType,
                sortDirection,
                filterCds: filterCds && JSON.parse(filterCds),
                filterContentTarget,
                filterGenres: filterGenres && JSON.parse(filterGenres),
                filterPromoted: filterPromoted && JSON.parse(filterPromoted),
                filterRegional: filterRegional && JSON.parse(filterRegional),
                filterBundles: filterBundles && JSON.parse(filterBundles),
              };
              Object.keys(filterQueries).forEach(k => {
                this.$store.commit('marketplace/set', {
                  path: `filterOptions.${k}`,
                  value: filterQueries[k] || undefined,
                });
              });
            } else {
              if (
                this.$store.getters.currentOrganization.type === 'entertainment'
              ) {
                this.$store.commit('marketplace/set', {
                  path: 'filterOptions.filterContentTarget',
                  value: 'all',
                });
              } else if (
                this.$store.getters.currentOrganization.type === 'education'
              ) {
                this.$store.commit('marketplace/set', {
                  path: 'filterOptions.filterContentTarget',
                  value: 'education',
                });
              }
            }
          },
          beforeRouteLeave (to, from, next) {
            this.$store.commit('marketplace/set', {
              path: 'filterOptions.search',
              value: '',
            });
            next();
          },
          render (h) {
            return h('keep-alive', { props: { include: 'MarketplaceRoute' } }, [
              h('router-view'),
            ]);
          },
        },
        children: [
          {
            name: 'marketplace',
            path: '',
            component: () =>
              import(/* webpackChunkName: "content" */ '@/content/marketplace/MarketplaceRoute'),
          },
          {
            name: 'marketplace-content-show',
            path: ':contentOwnershipId',
            props: true,
            component: () =>
              import(/* webpackChunkName: "content" */ '@/content/marketplace/MarketplaceContentShowRoute'),
          },
          {
            name: 'marketplace-bundle-show',
            path: 'bundle/:bundleId',
            props: true,
            component: () =>
              import(/* webpackChunkName: "content" */ '@/content/marketplace/MarketplaceBundleShowRoute'),
          },
        ],
      },
      {
        path: 'library',
        component: {
          beforeRouteLeave (to, from, next) {
            this.$store.commit('library/set', { path: 'search', value: '' });
            next();
          },
          render (h) {
            return h('keep-alive', { props: { include: 'LibraryRoute' } }, [
              h('router-view'),
            ]);
          },
        },
        children: [
          {
            name: 'content-library',
            path: '',
            component: () =>
              import(/* webpackChunkName: "content" */ '@/content/library/LibraryRoute'),
          },
          {
            name: 'content-library-show',
            path: ':gameId',
            props: true,
            beforeEnter (to, from, next) {
              const { gameId } = to.params;
              const game = store.getters['entities/Game'](gameId);
              if (
                game &&
                game.ownership &&
                game.ownership.operatorLicense &&
                game.ownership.operatorLicense.disabledForOutOfDateAgreement
              ) {
                next({
                  name: 'content-library',
                  query: { requireEula: gameId },
                });
              } else {
                next();
              }
            },
            redirect: {
              name: 'content-station-management',
            },
            component: () =>
              import(/* webpackChunkName: "content" */ '@/content/library/ContentShowRoute'),
            children: [
              {
                name: 'content-station-management',
                path: 'station-management',
                component: () =>
                  import(/* webpackChunkName: "content" */ '@/content/library/subroutes/ContentShowManagementRoute'),
              },
              {
                name: 'content-details',
                path: 'details',
                component: () =>
                  import(/* webpackChunkName: "content" */ '@/content/library/subroutes/ContentShowDetailsRoute'),
              },
              {
                name: 'content-settings',
                path: 'launch-settings',
                component: () =>
                  import(/* webpackChunkName: "content" */ '@/content/library/subroutes/ContentShowSettingsRoute'),
              },
              {
                name: 'content-controllers',
                path: 'controllers',
                component: () =>
                  import(/* webpackChunkName: "content" */ '@/content/library/subroutes/ContentShowControllersRoute'),
              },
            ],
          },
        ],
      },
      {
        name: 'content-create',
        path: 'create',
        component: {
          render (h) {
            return h('router-view', undefined, this.$slots.default);
          },
          beforeRouteEnter (to, from, next) {
            store.registerModule('contentNew', contentNewModule);
            next();
          },
          beforeRouteLeave (to, from, next) {
            store.unregisterModule('contentNew');
            next();
          },
        },
        children: [
          {
            name: 'content-add',
            path: 'add',
            component: () => import(/* webpackChunkName: "content" */ '@/content/library/ContentAddRoute'),
          },
          {
            name: 'content-new',
            path: 'new',
            component: () => import(/* webpackChunkName: "content" */ '@/content/library/ContentNewRoute'),
          },
        ],
      },
      {
        name: 'tags',
        path: '/tag',
        component: () => import(/* webpackChunkName: "content" */ '@/content/tags/TagsRoute'),
        redirect: { name: 'tag-index' },
        children: [
          {
            name: 'tag-index',
            path: 'index',
            component: () => import(/* webpackChunkName: "content" */ '@/content/tags/TagsIndexRoute'),
          },
          {
            name: 'tag-show',
            path: ':tagId',
            props: true,
            component: () => import(/* webpackChunkName: "content" */ '@/content/tags/TagShowRoute'),
          },
        ],
      },
    ],
  },
  {
    name: 'admin',
    path: '/admin',
    component: {
      created () {
        this.$store.commit('setTitle', this.$t('Admin'));
        this.$store.dispatch('getCountries');
      },
      render (h) {
        return h('router-view');
      },
    },
    children: [
      {
        name: 'organization-analytics',
        path: 'organization-analytics',
        component: () =>
          import(/* webpackChunkName: "org" */ '@/admin/analytics/OrganizationAnalyticsRoute'),
      },
      {
        name: 'organization-settings',
        path: 'organization-settings',
        component: () =>
          import(/* webpackChunkName: "org" */ '@/admin/OrganizationSettings'),
      },
      {
        name: 'billing',
        path: 'billing',
        redirect: { name: 'billing-account' },
        component: () =>
          import(/* webpackChunkName: "billing" */ '@/admin/Billing'),
        children: [
          {
            name: 'billing-account',
            path: 'account',
            props: to => {
              return { openSubscription: Boolean(to.query.subscribe) };
            },
            component: () =>
              import(/* webpackChunkName: "billing" */ '@/admin/billing/BillingAccount'),
          },
          {
            name: 'billing-invoices',
            path: 'invoices',
            component: () =>
              import(/* webpackChunkName: "billing" */ '@/admin/billing/BillingInvoices'),
          },
        ],
      },
      {
        name: 'location-index',
        path: 'locations',
        component: () =>
          import(/* webpackChunkName: "location" */ '@/admin/location/LocationIndexRoute'),
      },
      {
        name: 'location-show',
        path: 'location/:editingLocationId',
        props: true,
        component: () =>
          import(/* webpackChunkName: "location" */ '@/admin/location/LocationShowRoute'),
      },
      {
        name: 'content-only-user-settings',
        path: '/content-only-user-settings',
        component: () => import('@/admin/ContentOnlyUserSettings'),
      },
    ],
  },
  {
    name: 'demo',
    path: '/demo',
    component: () => import('@/demo/ValidationDemo'),
  },
  {
    path: '*',
    redirect: { name: 'home' },
  },
];

const router = new Router({
  mode: 'history',
  routes: [
    ...authRoutes,
    ...appRoutes.map(route =>
      deepmerge(route, {
        meta: {
          isAppRoute: true,
        },
      }),
    ),
  ],
  scrollBehavior (to, from, savedPosition) {
    if (to.name && from && to.name === from.name) {
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

sync(store, router);

// setup route permissions

const ALLLOWED_ROUTES_WHEN_DISABLED = ['billing', 'user-settings'];
router.beforeEach(async (to, from, next) => {
  const toAppRoute = to.matched.some(({ meta }) => meta.isAppRoute);
  const fromTOSRoute = from?.name?.includes('tos');
  if (toAppRoute && !fromTOSRoute) {
    if (!store.getters.currentOrganization) {
      await new Promise((resolve, reject) => {
        const unwatch = store.watch(
          (state, getters) => getters.currentOrganization,
          val => {
            if (val) {
              unwatch();
              resolve();
            }
          },
        );
      });
    }
    const { isActive, isAccessSuspended } = store.getters.currentOrganization;
    const shouldLockPanel = !isActive || isAccessSuspended;
    if (shouldLockPanel) {
      if (
        !to.matched.some(({ name }) =>
          ALLLOWED_ROUTES_WHEN_DISABLED.includes(name),
        )
      ) {
        next({ name: 'billing' });
        return;
      }
    }
  }
  next();
});
router.afterEach(async (to, from) => {
  window._$previousRoute = from;
});
export default router;
