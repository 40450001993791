<template>
  <div class="main-navigation" :class="{'-panel-open': activeSection}">
    <ul class="main-list">
      <li
        class="navigation-group-item"
        v-for="navigationGroup in navigationGroups"
        :key="navigationGroup.name"
      >
        <ul class="unstyled-list">
          <li
            v-for="(navigationItem, i) in navigationGroup"
            :key="navigationItem.name + i"
            class="menuitem"
            :class="{
            bottom: navigationItem.bottom,
            '-disable': navigationItem.disable
          }"
          >
            <router-link
              v-if="navigationItem.routerLink && !navigationItem.children"
              tag="div"
              class="click-area"
              :to="navigationItem.routerLink"
              :class="{'-disable': navigationItem.disable}"
            >
              <div class="icon">
                <svg v-if="navigationItem.icon">
                  <use :xlink:href="'#' + navigationItem.icon"></use>
                </svg>
                <span v-else>{{ navigationItem.name.charAt(0).toUpperCase() }}</span>
              </div>
              <span>{{ navigationItem.name }}</span>
              <div class="badge" v-if="navigationItem.badge">{{ navigationItem.badge }}</div>
              <div class="notification" v-if="navigationItem.notification">
                <span
                  v-if="typeof navigationItem.notification === 'number'"
                >{{ navigationItem.notification }}</span>
              </div>
            </router-link>

            <a
              class="click-area"
              v-if="navigationItem.externalLink"
              tag="div"
              rel="noopener"
              :href="navigationItem.externalLink"
            >
              <div class="icon">
                <svg v-if="navigationItem.icon">
                  <use :xlink:href="'#' + navigationItem.icon"></use>
                </svg>
                <span v-else>{{ navigationItem.name.charAt(0).toUpperCase() }}</span>
              </div>
              <span>{{ navigationItem.name }}</span>
              <div class="badge" v-if="navigationItem.badge">{{ navigationItem.badge }}</div>
              <div class="notification" v-if="navigationItem.notification">
                <span
                  v-if="typeof navigationItem.notification === 'number'"
                >{{ navigationItem.notification }}</span>
              </div>
            </a>

            <router-link
              tag="div"
              class="click-area"
              :to="navigationItem.routerLink || '/'"
              event
              active-class="-route-active"
              :class="{'-active': navigationItem.name === activeSection}"
              v-if="navigationItem.children"
              @click.native.stop="openChildren(navigationItem)"
            >
              <div class="icon">
                <svg v-if="navigationItem.icon">
                  <use :xlink:href="'#' + navigationItem.icon"></use>
                </svg>
                <span v-else>{{ navigationItem.name.charAt(0).toUpperCase() }}</span>
              </div>
              <span>{{ navigationItem.name }}</span>
              <div class="badge" v-if="navigationItem.badge">{{ navigationItem.badge }}</div>
              <div class="notification" v-if="navigationItem.notification">
                <span
                  v-if="typeof navigationItem.notification === 'number'"
                >{{ navigationItem.notification }}</span>
              </div>
              <svg class="chevron">
                <use xlink:href="#icon-chevron-right"></use>
              </svg>
            </router-link>

            <div
              class="click-area"
              v-if="navigationItem.action"
              @click="navigationItem.action"
              :class="{
                'router-link-active': navigationItem.activeCondition
              }"
            >
              <div class="icon">
                <svg v-if="navigationItem.icon">
                  <use :xlink:href="'#' + navigationItem.icon"></use>
                </svg>
                <span v-else>{{ navigationItem.name.charAt(0).toUpperCase() }}</span>
              </div>
              <span>{{ navigationItem.name }}</span>
              <div class="badge" v-if="navigationItem.badge">{{ navigationItem.badge }}</div>
              <div class="notification" v-if="navigationItem.notification">
                <span
                  v-if="typeof navigationItem.notification === 'number'"
                >{{ navigationItem.notification }}</span>
              </div>
            </div>
          </li>
        </ul>
      </li>
    </ul>

    <div class="children-panel" :class="{open: activeSection}">
      <h1>{{ childTitle }}</h1>
      <ul>
        <li
          v-for="child in filterChildren"
          :key="child.name"
          class="menuitem"
          :class="{'-disable': child.disable}"
        >
          <router-link class="click-area" tag="div" v-if="child.routerLink" :to="child.routerLink">
            <span>{{ child.name }}</span>
            <div class="notification" v-if="child.notification">
              <span v-if="typeof child.notification === 'number'">{{ child.notification }}</span>
            </div>
          </router-link>

          <a
            class="click-area"
            v-if="child.externalLink"
            tag="div"
            rel="noopener"
            :href="child.externalLink"
          >
            <span>{{ child.name }}</span>
          </a>

          <div
            class="click-area"
            v-if="child.action"
            @click="child.action"
            :class="{
              'router-link-active': child.activeCondition
            }"
          >
            <span>{{ child.name }}</span>
            <div class="notification" v-if="child.notification">
              <span v-if="typeof child.notification === 'number'">{{ child.notification }}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import handleClickOutside from '../../helpers/handleClickOutside.js';

export default {
  props: {
    navigation: {
      type: Array,
    },
    activeSection: {
      validator (val) { return val === null || typeof val === 'string'; },
    },
  },
  data () {
    return {
      childTitle: '',
      children: [],
    };
  },
  computed: {
    navigationGroups () {
      return this.navigation.every(item => Array.isArray(item))
        ? this.navigation
        : [this.navigation];
    },
    filterChildren () {
      const currentOrganization = this.$store.getters.currentOrganization;
      if (!currentOrganization) return;
      return this.children;
    },
  },
  methods: {
    openChildren ({ name, children }) {
      handleClickOutside('.children-panel', this.activeSection, this.closeChildren);
      this.$emit('setActiveSection', name);
      this.children = children;
      this.childTitle = name;
    },
    closeChildren () {
      this.$emit('setActiveSection', null);
    },
  },
};
</script>

<style scoped lang="postcss">
@import "../../styles";

.main-navigation {
  & .unstyled-list {
    padding-left: 0;
    margin: 0;
    list-style-type: none;
  }

  &.-panel-open,
  &.collapsed {
    z-index: 5 !important;
    & .navigation-group-item {
      &:after {
        width: 3.2rem;
        margin: 0 1rem;
      }
    }
  }

  & .navigation-group-item {
    position: relative;

    &:after {
      content: "";
      transition: all 0.3s ease-in-out;
      margin: 0 1.5rem;
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - 3rem);
      bottom: 0;
      pointer-events: none;
      border-bottom: solid 1px color-mod(var(--colorCloud) alpha(25%));
    }
  }
  & .navigation-group-item:last-child {
    &:after {
      border-bottom: none;
    }
  }

  & .main-list {
    overflow-y: scroll !important;
    padding-left: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    height: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      visibility: hidden;
      display: none;
    }

    & .bottom {
      margin-top: auto;
    }

    & li .click-area {
      @apply --f4;

      display: flex;
      color: var(--colorCloud);
      align-items: center;
      padding: var(--spacingSm);
      margin: 0;
      cursor: pointer;
      transform: translateZ(0);
      text-decoration: none;

      & .icon {
        position: relative;
        top: 0.3rem;
        opacity: 0.5;
        transition: opacity 0.3s ease;
        will-change: opacity;

        & span {
          display: block;
          text-align: center;
          font-size: 2rem;
          font-weight: 700;
          height: 2rem;
          width: 2rem;
          margin-right: var(--spacingSm);
          line-height: 1;
        }
      }

      & svg {
        height: 2rem;
        width: 2rem;
        fill: var(--colorCloud);
        margin-right: var(--spacingMd);
        transition: fill 0.3s ease;
        will-change: fill;
        flex-shrink: 0;
        transform: translateZ(0);
        opacity: 0.5;
        transition: opacity 0.3s ease;
        will-change: opacity;

        &.chevron {
          margin-right: 0;
        }
      }

      & > span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1 1 auto;
        opacity: 0.5;
        transition: opacity 0.3s ease;
        will-change: opacity;
      }

      & .badge {
        @apply --f6;

        color: var(--colorErrorFlat);
        border: 0.1rem solid var(--colorErrorFlat);
        padding: 0.3rem var(--spacingXS);
        line-height: 1;
        margin: 0;
        text-transform: uppercase;
      }

      &.router-link-active,
      &:hover,
      &.-active,
      &.-route-active {
        & .icon,
        & svg,
        & span {
          opacity: 1;
        }
      }
      &.-disable {
        pointer-events: none;
        cursor: not-allowed;
      }

      &:hover,
      &.-active,
      &.-route-active {
        color: var(--colorWhite);

        & svg {
          fill: var(--colorWhite);
        }
      }
    }
  }

  & .notification {
    @apply --f8;

    margin: 0;
    border-radius: 50%;
    background: var(--colorSBRed);
    color: var(--colorCloud);
    width: 2rem;
    height: 2rem;
    overflow: hidden;
    text-align: center;
    line-height: 2rem;
    opacity: 1;
  }

  & .children-panel {
    position: absolute;
    background-color: color-mod(var(--colorSlate) b(78%));
    right: 0;
    top: 0;
    bottom: 0;
    width: calc(100% - var(--spacingLg));
    padding: 2.2rem 0 0;
    transform: translateX(100%) translateZ(0);
    transition: transform 0.3s ease;
    will-change: transform;

    &.open {
      transform: translateX(0) translateZ(0);
      z-index: 2;
    }

    & h1 {
      @apply --f3;

      margin: 0;
      color: var(--colorCloud);
      margin-bottom: var(--spacingSm);
      padding: 0 var(--spacingSm);
    }

    & ul {
      margin: 0;
      padding: 0;
      list-style-type: none;

      & li {
        & .click-area {
          @apply --f4;

          cursor: pointer;
          padding: var(--spacingSm);
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: var(--colorCloud);
          text-decoration: none;
          opacity: 0.5;
          transition: opacity 0.3s ease;
          will-change: opacity;

          &:hover,
          &.router-link-active {
            opacity: 1;
          }
        }
      }
    }
  }
  & .menuitem.-disable {
    cursor: not-allowed;
    & > * {
      pointer-events: none;
    }
  }
}
</style>
