import { gql } from '@/core/shared/api';
const gatewayFragment = `
id
bookingEnabled
squareLocationId
type
squareLocations {
  id
  name
}
`;
const integrationFragment = `
type
category
integrationSettings {
  key
  value
  experience {
    id
  }
}
`;
const locationFragment = `
id
title,
address
address2
city
country
state
postalCode
email
phone
locale
timezone
twentyFourHourTime
currency
taxExclusive
taxAmount
waiversRequired
waiverHostProxyUrl
waiverGuestProxyUrl
usesHaze
darkMode
stations {
  id
}
experiences {
  id
  title
}
gateways {
  ${gatewayFragment}
}
integrations {
  ${integrationFragment}
}
`;

export const getOrganizationStats = async ({ commit }) => {
  const query = `
    {
      currentOrganization {
        currentMonthCost {
          displayValue
        }
        currentLicensedGamesCount
        invoices {
          id
          paidAt
          displayInvoiceTotal
          downloadLink
          invoiceDate
          type
          invoiceNumber
        }
      }
    }
  `;

  const data = await gql.request(query);
  if (data) commit('setOrganizationField', data.currentOrganization);
  return data;
};

export const getLocationsDetail = async ({ commit, dispatch }) => {
  commit('setLoading', true);
  commit('admin/set', {
    path: 'isFetchingLocations',
    value: true,
  });

  const query = `{
    locations {
      ${locationFragment}
    }
  }`;

  try {
    const { locations } = await gql.request(query);
    dispatch('insertEntities', {
      name: 'Location',
      payload: locations,
    });
  } finally {
    commit('setLoading', false);
    commit('admin/set', {
      path: 'isFetchingLocations',
      value: false,
    });
  }
};

export const deleteLocation = async ({ getters, dispatch, commit }, { id }) => {
  const location = { id };
  const query = `
      mutation($location: LocationInput) {
        deleteLocation(location: $location) {
          id
        }
      }
      `;
  await gql.request(query, { location });
  dispatch('removeEntities', {
    name: 'Location',
    payload: location,
  });
};

export const storeLocation = async ({ commit, dispatch }, location) => {
  commit('setLoading', true);

  const mutation = `mutation ($location: LocationInput) {
    storeLocation (location: $location) {
      ${locationFragment}
    }
  }`;

  try {
    const { storeLocation } = await gql.request(mutation, { location });

    dispatch('insertEntities', {
      name: 'Location',
      payload: storeLocation,
    });
  } finally {
    commit('setLoading', false);
  }
};
export const storeGateway = async (
  { commit, dispatch, rootGetters },
  gateway,
) => {
  commit('setLoading', true);
  const locationId = gateway.location.id;
  const gatewayType = gateway.type;
  const location = rootGetters['entities/Location'](locationId);
  const currentGateway = location.gateways.find(
    ({ type }) => gatewayType === type,
  );
  const deleteCurrentGateway = `
   deletedGateway: deleteGateway (gateway: $currentGateway) {
      id
    }
  `;
  const mutation = `mutation (
    $gateway: GatewayInput
    $locationId: ID
    ${currentGateway ? '$currentGateway: GatewayInput' : ''}
  ) {
    ${currentGateway ? deleteCurrentGateway : ''}
    updatedGateway: storeGateway (gateway: $gateway) {
      id
    }
    location: storeLocation(location: { id: $locationId }) {
      id
      gateways {
        ${gatewayFragment}
      }
    }
  }`;

  try {
    const { location } = await gql.request(mutation, {
      gateway,
      locationId,
      currentGateway: {
        id: currentGateway && currentGateway.id,
      },
    });

    dispatch('insertEntities', {
      name: 'Location',
      payload: location,
    });
  } finally {
    commit('setLoading', false);
  }
};
