import * as getters from './getters';
import * as actions from './actions';
import * as mutations from './mutations';

export default {
  strict: true,
  state: {
    token: null,
    tenantId: null,
    currentUserId: '',
  },
  getters,
  actions,
  mutations,
};
