const isServer = typeof window === 'undefined';
const root = isServer ? global : window;
if (!isServer && !window.sbvrenv) {
  window.sbvrenv = process.env || {};
}
export const crossEnv = isServer ? global.process.env : window.sbvrenv;

export default function configureEnvironment (url) {
  // Locale!
  if (typeof navigator !== 'undefined') {
    crossEnv.BROWSER_LOCALE =
      navigator.browserLanguage || navigator.language || 'en-US';
  } else {
    crossEnv.BROWSER_LOCALE = 'en-US';
  }

  let env = process.env.NODE_ENV;
  const hostname =
      typeof window !== 'undefined' &&
      window.location &&
      window.location.hostname;
  const devUrls = [/^.*.springboardvr.docker/, /^.*localhost.*/];
  const reviewUrls = [/^.*.review.springboardvr.com/, /-review-.*\./];
  const stagingUrls = [/^(https?:\/\/)?staging-?.*.springboardvr.com/, /-staging.*\./];
  const testPatterns = patterns =>
    patterns.some(pattern => pattern.test(url || hostname));

  if (testPatterns(devUrls)) env = 'development';
  else if (testPatterns(stagingUrls)) env = 'staging';
  else if (testPatterns(reviewUrls)) env = 'review';
  else env = 'production';

  if (root.Cypress) {
    const CYPRESS_ENV = root.Cypress.env('ENV');
    env = CYPRESS_ENV || 'development';
    crossEnv.CYPRESS = true;
  }
  if (process.env.VUE_APP_ENV) {
    env = process.env.VUE_APP_ENV;
  }

  crossEnv.NODE_ENV = env;
  crossEnv.ENV = env;
  if (env === 'development') {
    crossEnv.API_URL = 'api.springboardvr.docker';
    crossEnv.ACCOUNT_URL = 'account.springboardvr.docker';
    crossEnv.PUBLIC_API_URL = 'public-api.springboardvr.docker';
    crossEnv.PANEL_URL = 'app.springboardvr.docker';
    crossEnv.MONITOR_URL = 'monitor.springboardvr.docker';
    crossEnv.SOCKET_URL = 'websocket.springboardvr.docker';
    crossEnv.OPERATOR_URL = 'operator.springboardvr.docker';
    crossEnv.CUSTOMER_URL = 'customer.springboardvr.docker';
    crossEnv.PUBLIC_URL = 'springboardvr.docker';
    crossEnv.LD_CLIENT_ID = '5d4c4e26a23b3b07c0f2ef95';
    crossEnv.NET_URL = 'net.springboardvr.kestrel:5000'; // Forward to your Windows machine. Erik tried with "net.springboardvr.kestrel and put that in the hosts file, but it should be in the hosts file of the containers too, which is a hassle *
    crossEnv.MAIN_NET_URL = 'main-net.springboardvr.kestrel:5176'; // Forward to your Windows machine *
    // * https://weblog.west-wind.com/posts/2016/sep/28/external-network-access-to-kestrel-and-iis-express-in-aspnet-core#override-the-default-urls
    // * https://support.mozilla.org/en-US/questions/1198894 Enable mixed content in Firefox
    // crossEnv.NET_URL = 'staging-net.springboardvr.com';
    // crossEnv.NET_URL = 'staging-net.springboardvr.com';
  } else if (env === 'staging' || env === 'review') {
    crossEnv.API_URL = 'staging-api.springboardvr.com';
    crossEnv.ACCOUNT_URL = 'staging-account.springboardvr.com';
    crossEnv.PUBLIC_API_URL = 'staging-public-api.springboardvr.com';
    crossEnv.PANEL_URL = 'staging-app.springboardvr.com';
    crossEnv.MONITOR_URL = 'staging-monitor.springboardvr.com';
    crossEnv.SOCKET_URL = 'staging-websocket.springboardvr.com';
    crossEnv.OPERATOR_URL = 'staging-operator.springboardvr.com';
    crossEnv.CUSTOMER_URL = 'staging-customer.springboardvr.com';
    crossEnv.PUBLIC_URL = 'staging.springboardvr.com';
    crossEnv.LD_CLIENT_ID = '5d4c4e26a23b3b07c0f2ef95';
    crossEnv.NET_URL = 'staging-net.springboardvr.com';
    crossEnv.MAIN_NET_URL = 'staging-main-net.springboardvr.com';
  } else if (env === 'production') {
    crossEnv.API_URL = 'api.springboardvr.com';
    crossEnv.ACCOUNT_URL = 'account.springboardvr.com';
    crossEnv.PUBLIC_API_URL = 'public-api.springboardvr.com';
    crossEnv.PANEL_URL = 'app.springboardvr.com';
    crossEnv.MONITOR_URL = 'monitor.springboardvr.com';
    crossEnv.SOCKET_URL = 'websocket.springboardvr.com';
    crossEnv.OPERATOR_URL = 'operator.springboardvr.com';
    crossEnv.CUSTOMER_URL = 'customer.springboardvr.com';
    crossEnv.PUBLIC_URL = 'springboardvr.com';
    crossEnv.LD_CLIENT_ID = '5d4c4e26a23b3b07c0f2ef96';
    crossEnv.MAIN_NET_URL = 'main-net.springboardvr.com';
    crossEnv.NET_URL = 'net.springboardvr.com';
  }
  if (process.env.VUE_APP_NODE_ENV) {
    crossEnv.NODE_ENV = process.env.VUE_APP_NODE_ENV;
  }
  // override for review prefixed URLs
  const prefix = process.env.VUE_APP_REVIEW_URL_PREFIX;
  if (env === 'review' && prefix) {
    crossEnv.REVIEW_PREFIX = prefix;
    crossEnv.API_URL = prefix + '-api.review.springboardvr.com';
    crossEnv.PUBLIC_API_URL = prefix + '-public-api.review.springboardvr.com';
    crossEnv.PANEL_URL = prefix + '-app.review.springboardvr.com';
    crossEnv.SOCKET_URL = prefix + '-socket.review.springboardvr.com';
  }
  crossEnv.TRANSACTION_ID = Math.random()
    .toString(36)
    .substr(2, 9);

  crossEnv.COMMITHASH = typeof COMMITHASH !== 'undefined' && COMMITHASH;

  if (
    process.env.NODE_ENV !== 'production' &&
    // eslint-disable-next-line no-console
    console &&
    // eslint-disable-next-line no-console
    console.table &&
    !isServer &&
    process.env.NODE_ENV !== 'test'
  ) {
    // eslint-disable-next-line no-console
    setTimeout(() => console.table(crossEnv), 0);
  }
}
