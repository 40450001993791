import Vue from 'vue';
import { experienceIndexById } from './getters';
import deepmerge from 'deepmerge';

export const setExperience = (state, experience) => {
  const experienceIndex = experienceIndexById(state)(experience.id);

  if (~experienceIndex) {
    Vue.set(
      state,
      experienceIndex,
      deepmerge(state[experienceIndex], experience),
    );
  } else {
    Vue.set(state, state.length, experience);
  }
};

export const unsetExperience = (state, experience) => {
  const experienceIndex = experienceIndexById(state)(experience.id);

  if (~experienceIndex) {
    Vue.delete(
      state,
      experienceIndex,
      [
        ...state.slice(0, experienceIndex),
        ...state.slice(experienceIndex + 1),
      ],
    );
  }
};
