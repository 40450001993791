<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  inheritAttrs: false,
  props: {
    value: {},
  },
  model: {
    event: 'update-model',
  },
})
export default class ModelMixin extends Vue {
  internalValue = null
  get listeners () {
    const { input, ...rest } = this.$listeners;
    return rest;
  }

  updateInternalValue (val) {
    this.internalValue = val;
  }

  updateModel (val) {
    const coercedVal = this.$attrs.type === 'number' ? Number(val) : val;
    this.$emit('update-model', coercedVal);
  }

  created () {
    this.$watch('value', this.updateInternalValue, { immediate: true });
    this.$watch('internalValue', this.updateModel);
  }
}
</script>
