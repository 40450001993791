import { gql } from '@/core/shared/api';
import Vue from 'vue';

export default {
  strict: true,
  namespaced: true,
  state: () => ({
    newGame: {},
    presetId: null,
  }),
  actions: {
    async createGame ({ state, dispatch }) {
      const { _imageBlob, _videoBlob, ...gameToSave } = state.newGame;
      const mutationName = state.presetId
        ? 'createGameFromPreset'
        : 'storeGame';
      const varName = state.presetId ? 'preset' : 'game';
      const query = `
        mutation ($game: GameInput) {
          game: ${mutationName} (${varName}: $game) {
            id
            title
          }
        }
      `;
      const variables = {
        game: {
          ...gameToSave,
          imageBlobFieldName: _imageBlob && _imageBlob.name,
          videoBlobFieldName: _videoBlob && _videoBlob.name,
        },
      };
      if (state.presetId) variables.game.id = state.presetId;
      const blobs = [_imageBlob, _videoBlob].filter(v => !!v);
      const { game } = await gql.requestWithBlobs(query, variables, blobs);
      return game;
    },
    async prefillWithPreset ({ state, dispatch, commit }, preset) {
      const query = `
            query ($id: ID) {
              preset: gamePreset (id: $id) {
                id
                title
                ageRating
                imageUrl
                videoUrl
                description
                difficulty
                organizationTags {
                  id
                  text
                }
                singleplayer
                multiplayer
                launchMethod
                arguments
                filePath
                uri
              }
            }
          `;
      const variables = {
        id: preset.id,
      };
      const { preset: newPreset } = await gql.request(query, variables);
      dispatch(
        'insertEntities',
        {
          name: 'Game',
          payload: newPreset,
        },
        { root: true },
      );
      commit('setPreset', newPreset);
      return newPreset;
    },
  },
  mutations: {
    setLicensingSource (state, licensingSource) {
      state.newGame.licensingSource = licensingSource;
    },
    setPreset (state, preset) {
      state.presetId = preset.id;
    },
    updateGame (state, game) {
      Object.entries(game).forEach(([k, val]) => {
        Vue.set(state.newGame, k, val);
      });
    },
  },
};
