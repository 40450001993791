
import Vue from 'vue';
import VueMq from 'vue-mq';
import VueCompositionAPI from '@vue/composition-api';

Vue.use(VueCompositionAPI);
Vue.use(VueMq, {
  breakpoints: {
    phone: 768,
    tablet: 1024,
    desktop: 1440,
    desktopXL: Infinity,
  },
});

Vue.config.productionTip = false;
if (window.sbvrenv.NODE_ENV !== 'production') Vue.config.devtools = true;
