export const state = {
  isDisableAccountModalVisible: false,
  disableAccountModalType: null,
  billingPlan: null,
  billingPlans: [],
  proratedAmount: null,
  loadings: {
    invoices: false,
    account: false,
    updateCC: false,
    subscription: false,
    proratedAmount: false,
  },
  netInvoices: [],
};

// Location stuff is in core/store
