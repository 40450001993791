import { gql } from '@/core/shared/api';

export default {
  strict: true,
  state: {
    isFetchingLocation: false,
  },
  mutations: {
    'location/set': (state, { path, value }) => {
      state[path] = value;
    },
  },
  actions: {
    getLocationDetails: async ({ dispatch, commit }, locationId) => {
      commit('location/set', { path: 'isFetchingLocation', value: true });
      commit('setLoading', true);

      try {
        const query = `{
          location (id: "${locationId}") {
            id
            timezone
            locale
            waiversRequired
          }
        }`;

        const { location } = await gql.request(query);

        dispatch('insertEntities', {
          name: 'Location',
          payload: location,
        });

        return location;
      } finally {
        commit('location/set', { path: 'isFetchingLocation', value: true });
        commit('setLoading', true);
      }
    },
  },
};
