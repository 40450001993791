export default class DOMHelper {
  static addClass (el, className) {
    if (el.classList) { el.classList.add(className); } else { el.className += ' ' + className; }
  }

  static removeClass (el, className) {
    if (el.classList) { el.classList.remove(className); } else { el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' '); }
  }

  static matches (el, ...args) {
    const fn = Element.prototype.matches || Element.prototype.msMatchesSelector;
    return fn.apply(el, args);
  }

  static closest (el, selector) {
    let parent;
    // traverse parents
    while (el) {
      parent = el.parentElement;
      if (parent && this.matches(parent, selector)) {
        return parent;
      }
      el = parent;
    }
    return null;
  }
}
export function addScript (src, callback) {
  const s = document.createElement('script');
  s.setAttribute('src', src);
  s.onload = callback;
  document.body.appendChild(s);
}
