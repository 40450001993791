import Vue from 'vue';

const permissions = {};

function contentOnly (organization) {
  return organization.contentOnly;
}

function education (organization) {
  return organization.type === 'education';
};

function subscriptionExclusion (organization) {
  return organization.subscriptionExclusion;
};

function enterpriseDeveloper (organization) {
  return organization?.tenant?.enterpriseContentOwnerships;
}

function enterpriseOperator (organization) {
  return organization?.type === 'enterprise';
}

function VueSBVRPermissions () {
  Vue.util.defineReactive(Vue.prototype, '$permissions', permissions);
}

export function initSBVRPermissions (user, organization) {
  Vue.set(permissions, 'content-only', contentOnly(organization));
  Vue.set(permissions, 'education', education(organization));
  Vue.set(permissions, 'subscription-exclusion', subscriptionExclusion(organization));
  Vue.set(permissions, 'enterprise-developer', enterpriseDeveloper(organization));
  Vue.set(permissions, 'enterprise-operator', enterpriseOperator(organization));
}

if (process.env.NODE_ENV !== 'test' && Vue.use) {
  Vue.use(VueSBVRPermissions);
}

export default VueSBVRPermissions;

export function usePermissions () {
  return permissions;
}
