// import * as getters from './getters';
import * as actions from './actions';
// import * as mutations from './mutations';

export default {
  strict: true,
  state: {
    games: [],
    bundles: [],
    gamesLoading: [],
    gamesFullyLoaded: [],
    searchResults: [],
    search: '',
    paginatorInfo: null,
    isFetchingLicenses: false,
    isFetchingBundleLicenses: false,
  },
  // getters,
  actions,
  mutations: {
    'library/set' (state, { path, value }) {
      state[path] = value;
    },
  },
};
