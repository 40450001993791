// import * as getters from './getters';
import * as actions from './actions';
import { state } from './state';
import { set, get } from 'lodash-es';
import deepmerge from 'deepmerge';

export default {
  strict: true,
  state,
  // getters,
  actions,
  mutations: {
    'marketplace/set' (state, { path, value, merge = false }) {
      if (merge) {
        const currentValue = get(state, path);
        set(state, path, deepmerge(currentValue, value));
      } else {
        set(state, path, value);
      }
    },
  },
};
