<script>
export default {
  name: 'UIIcon',
  functional: true,
  props: {
    name: { required: true },
  },
  render (h, { props, data }) {
    const { staticClass, attrs } = data;
    return h('svg',
      {
        ...data,
        staticClass: `${staticClass || ''} icon width-text fill-inherit`,
        attrs: {
          viewBox: '0 0 100 100',
          ...attrs,
        },
      }, [
        h('use', {
          attrs: {
            'xlink:href': `#icon-${props.name}`,
          },
        }),
      ]);
  },
};
</script>

<style>
.width-text {
  width: 1em;
}
</style>
