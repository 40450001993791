import { gql } from '@/core/shared/api';
import { userFragment } from './fragments';

export const getUsers = async ({ commit, dispatch }) => {
  const query = `
    {
      users {
        ${userFragment}
      }
    }
  `;

  const data = await gql.request(query);
  if (data) {
    const ids = await dispatch('insertEntities', {
      name: 'User',
      payload: data.users,
    });
    commit('users/set', (state) => { state.userIds = ids; });
  }
  return data;
};

export const getUser = async ({ commit, dispatch }, user) => {
  const query = `
    query ($user: UserInput) {
      user: user(user: $user) {
        ${userFragment}
      }
    }
  `;
  const variables = {
    user,
  };
  const data = await gql.request(query, variables);
  if (data) {
    dispatch('insertEntities', {
      name: 'User',
      payload: data.user,
    });
  }
  return data;
};

export const storeUser = async ({ commit, dispatch }, user) => {
  commit('setLoading', true);

  const query = `mutation (
    $user: UserInput
  ) {
    storeUser (user: $user) {
      ${userFragment}
    }
  }`;

  try {
    const { storeUser } = await gql.request(query, { user });
    dispatch('insertEntities', {
      name: 'User',
      payload: storeUser,
    });
  } finally {
    commit('setLoading', false);
  }
};

export const storeNewUser = async ({ commit, dispatch }, user) => {
  commit('setLoading', true);

  const query = `mutation (
    $user: UserInput
  ) {
    storeUser (user: $user) {
      ${userFragment}
    }
  }`;

  try {
    const { storeUser } = await gql.request(query, { user });
    dispatch('insertEntities', {
      name: 'User',
      payload: storeUser,
    });
  } finally {
    commit('setLoading', false);
  }
};

export const deleteUser = async ({ commit, dispatch }, user) => {
  commit('setLoading', true);

  const query = `mutation (
    $user: UserInput
  ) {
    deleteUser (user: $user) {
      ${userFragment}
    }
  }`;

  try {
    const data = await gql.request(query, { user });
    if (data) return data;
  } finally {
    commit('setLoading', false);
  }
};
