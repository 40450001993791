export default function handleClickOutside (selector, currentState, action) {
  let removeClickListener; // eslint-disable-line
  const outsideClickListener = (event) => {
    if (!(event.target).closest(selector)) {
      action();
      removeClickListener();
    }
  };

  removeClickListener = () => {
    document.removeEventListener('click', outsideClickListener);
  };

  if (!currentState) {
    setTimeout(() => {
      document.addEventListener('click', outsideClickListener);
    }, 0);
  }

  return removeClickListener;
}

export function setupClickOutside (selector, action) {
  let removeClickListener; // eslint-disable-line
  const outsideClickListener = (event) => {
    if (!(event.target).closest(selector)) {
      action();
      removeClickListener();
    }
  };
  removeClickListener = () => {
    document.removeEventListener('click', outsideClickListener);
  };
  document.addEventListener('click', outsideClickListener);
  return removeClickListener;
}
