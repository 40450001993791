import * as actions from './actions';
import { state } from './state';

export default {
  strict: true,
  state,
  mutations: {
    'users/set' (state, fn) {
      fn(state);
    },
  },
  actions,
};
