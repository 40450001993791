import { gql } from '../../api';

export const getOrganizationBasics = async ({ commit, dispatch }, extra = '') => {
  commit('setLoading', true);
  commit('organization/set', {
    path: 'isFetchingOrganization',
    value: true,
  });

  const query = `{
    currentOrganization {
      id
      name
      type
      country
      ${extra}
    }
  }`;

  try {
    const { currentOrganization } = await gql.request(query);

    commit('organization/set', {
      path: 'currentOrganizationId',
      value: currentOrganization.id,
    });

    dispatch('insertEntities', {
      name: 'Organization',
      payload: currentOrganization,
    });

    return currentOrganization;
  } finally {
    commit('setLoading', false);
    commit('organization/set', {
      path: 'isFetchingOrganization',
      value: false,
    });
  }
};

export const getOrganizationDetailsForForm = async ({ commit, dispatch }, extra = '') => {
  commit('setLoading', true);
  commit('organization/set', {
    path: 'isFetchingOrganization',
    value: true,
  });

  const query = `{
    currentOrganization {
      id
      name
      type
      address
      address2
      country
      state
      city
      postalCode
      contactFirstName
      contactLastName
      contactPosition
      contactEmail
      contactPhone
      taxNumber
      vatNumberExempt
      financeEmail
      ${extra}
    }
  }`;

  try {
    const { currentOrganization } = await gql.request(query);

    commit('organization/set', {
      path: 'currentOrganizationId',
      value: currentOrganization.id,
    });

    dispatch('insertEntities', {
      name: 'Organization',
      payload: currentOrganization,
    });

    return currentOrganization;
  } finally {
    commit('setLoading', false);
    commit('organization/set', {
      path: 'isFetchingOrganization',
      value: false,
    });
  }
};

export const storeOrganization = async ({ commit, dispatch }, organization) => {
  commit('setLoading', true);

  const mutation = `
    mutation updateOrganization (
      $organization: OrganizationInput
    ) {
      updateOrganization (
        organization: $organization
      ) {
        id
      }
    }
  `;

  try {
    const { updateOrganization } = await gql.request(mutation, { organization });

    dispatch('insertEntities', {
      name: 'Organization',
      payload: organization,
    });

    return updateOrganization;
  } finally {
    commit('setLoading', false);
  }
};
