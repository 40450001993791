import Echo from 'laravel-echo';
window.Pusher = require('pusher-js');

export const initEcho = async store => {
  const {
    host,
    key,
    broadcaster,
  } = store.getters.currentUser.pusherConfiguration;
  const echoConfiguration = {
    broadcaster: 'pusher',
    wsHost: host,
    key,
    disableStats: true,
    encrypted: true,
    authEndpoint: `https://${window.sbvrenv.API_URL}/v1/broadcasting/auth`,
    auth: {
      headers: {
        XBroadcaster: broadcaster,
        Authorization: `Bearer ${store.state.auth.token}`,
      },
    },
  };
  window.echo = new Echo(echoConfiguration);
  window.echo
    .private(`App.Organization.${store.getters.currentOrganization.id}`)
    .listen('.StationGame', ({ stationGame }) => {
      if (stationGame) {
        store.dispatch('onStationGameUpdate', stationGame);
      }
    })
    .listen('.Game', ({ game }) => {
      if (game) {
        store.dispatch('getGame', game);
      }
    })
    .listen('.Organization', ({ organization }) => {
      if (organization) {
        store.dispatch('getOrganizationDetailsForForm');
      }
    });
};
