<script>
import Vue from 'vue';
import UiIcon from './UIIcon';
import Component from 'vue-class-component';

const themes = [
  'tiny',
  'primary',
  'secondary',
  'ghost',
  'link',
  'warning',
  'unstyled',
];
const themeModifiers = [
  // color modifiers
  'light',
  'white',
  'dark',
];
const themeProps = [...themes, ...themeModifiers].reduce((acc, theme) => ({
  ...acc,
  [theme]: Boolean,
}), {});
@Component({
  name: 'UIButton',
  functional: true,
  props: {
    icon: String,
    fullWidth: Boolean,
    ...themeProps,
  },
})
export default class UIButton extends Vue {
  render (h, ctx) {
    let children = ctx.children;
    if (ctx.props.icon) {
      children = [h(UiIcon, {
        props: { name: ctx.props.icon },
        staticClass: 'icon v-mid',
      }), children ? h('span', { staticClass: 'copy' }, children) : undefined];
    }
    let styleClasses = themes.filter((theme) => ctx.props[theme]);
    if (!styleClasses.length) styleClasses = ['primary'];
    const themeStyleClass = `-${styleClasses.join('-')}`;
    const modiferStyleClasses = themeModifiers.reduce((acc, theme) => {
      const val = ctx.props[theme];
      if (val) acc[`-${theme}`] = val;
      return acc;
    }, {});
    let inheritedClass;
    if (typeof ctx.data.class === 'string') {
      inheritedClass = { [ctx.data.class]: true };
    } else {
      inheritedClass = ctx.data.class;
    }
    const mergedData = {
      ...ctx.data,
      class: {
        dib: true,
        'w-100': ctx.props.fullWidth,
        ...inheritedClass,
        [themeStyleClass]: true,
        ...modiferStyleClasses,
      },
      staticClass: `ui-button ${ctx.data.staticClass || ''}`,
    };
    mergedData.attrs = mergedData.attrs || {};
    if (ctx.parent.$options._scopeId) {
      mergedData.attrs[ctx.parent.$options._scopeId] = '';
    }
    if (ctx.data.attrs && ctx.data.attrs.to) {
      if (mergedData.on && mergedData.on.click) {
        mergedData.nativeOn = mergedData.nativeOn || {}; // forward click events because vue-router don't do it. Which if you ask me is stupid :()
        mergedData.nativeOn.click = mergedData.on.click; // forward click events because vue-router don't do it. Which if you ask me is stupid :()
        delete mergedData.on.click;
      }
      return h('router-link', {
        ...mergedData,
        props: {
          ...ctx.data.attrs,
        },
      }, children);
    } else if (ctx.data.attrs && ctx.data.attrs.href) {
      return h('a', mergedData, children);
    } else {
      if (!mergedData.attrs.type) {
        mergedData.attrs.type = 'button';
      }
      return h('button', mergedData, children);
    }
  }
}
</script>

<style scoped lang="postcss">
@import "../../styles";

.ui-button {
  cursor: pointer;
  text-decoration: none;

  & .icon + .copy {
    margin-left: var(--spacingXS);
  }
  &.-ghost {
    @apply --buttonGhostTemplate;
    fill: var(--colorSlate);
  }
  &.-tiny {
    @apply --f9;
    border: none;
    outline: none;
    appearance: none;
    text-shadow: none;
    box-shadow: none;
    cursor: pointer;
    background: none;
    color: var(--colorSBRedFlat);
    fill: var(--colorSBRedFlat);
    padding: 0;

    transition: color 0.3s ease;
    transform: translateZ(0);
    will-change: color;

    &:hover {
      color: var(--colorSBRedFlatHover);
      fill: var(--colorSBRedFlatHover);
    }
    &.disabled,
    &.-disabled,
    &[disabled] {
      fill: color-mod(var(--colorSlate) alpha(50%));
      cursor: not-allowed !important;
      pointer-events: none;
      color: color-mod(var(--colorSlate) alpha(50%));
    }
  }
  &.-link {
    background: none;
    border: none;
    padding: 0;
    @apply --linkStyle;
    text-align: left;
    &.-white {
      color: white;
    }
  }

  &.-secondary {
    @apply --buttonSecondary;
    fill: white;
  }
  &.-warning {
    @apply --f8;
    background: none;
    border: none;
    outline: none;
    color: var(--colorErrorFlat);
    fill: var(--colorErrorFlat);
    & .copy {
      text-decoration: underline;
    }
    &:hover {
      color: var(--colorErrorFlatHover);
      fill: var(--colorErrorFlatHover);
    }
  }
  &.-unstyled {
    border: none;
    appearance: none;
    background: none;
    padding: 0;
    line-height: 0;
  }
  &.-tiny-secondary {
    @apply --f9;
    border: none;
    outline: none;
    appearance: none;
    text-shadow: none;
    box-shadow: none;
    cursor: pointer;
    background: none;
    color: #314562;
    fill: #314562;
    padding: 0;

    transition: color 0.3s ease;
    transform: translateZ(0);
    will-change: color;

    &:hover {
      color: var(--colorSlate);
      fill: var(--colorSlate);
    }
    &.disabled,
    &.-disabled,
    &[disabled] {
      fill: color-mod(var(--colorSlate) alpha(50%));
      cursor: not-allowed !important;
      pointer-events: none;
      color: color-mod(var(--colorSlate) alpha(50%));
    }
  }
  &.-tiny.-light {
    color: var(--colorManatee);
    fill: var(--colorManatee);
    &:hover {
      color: var(--colorFjord);
      fill: var(--colorFjord);
    }
  }
  &.-tiny.-white {
    color: white;
    fill: white;
    &:hover {
      color: var(--colorGeyser);
      fill: var(--colorGeyser);
    }
  }
  &.-tiny.-dark {
    color: var(--colorSlate);
    fill: var(--colorSlate);
    &:hover {
      color: var(--colorStorm);
      fill: var(--colorStorm);
    }
  }
  &.-ghost.-white {
    color: white;
    background-color: transparent;
    fill: white;
    &:hover {
      color: var(--colorSlate);
      background-color: white;
      fill: var(--colorWhite);
    }
  }
  &.-primary {
    @apply --buttonPrimary;
    fill: var(--colorWhite);
  }
  & .icon {
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: top;
    transition: fill 0.3s ease;
  }
}
</style>
