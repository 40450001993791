import store from '@/core/store';

export default function initCanny () {
  if (window.sbvrenv.NODE_ENV === 'production') {
    store.subscribe((mutation, state) => {
      if (mutation.type === 'setUser') {
        const { avatar_url: avatarURL, email, uuid: id, name } = mutation.payload;
        window.Canny('identify', {
          appID: '5aabfd3bb38415700129800b',
          user: {
            avatarURL,
            email,
            id,
            name,
          },
        });
      }
    });
  }
}
