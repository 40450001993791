import { gql } from '@/core/shared/api';

const couponFragment = `
  id
  type
  active
  code
  amount
  minStations
  startsAt
  endsAt
  displayCoupon
  experience {
    id
    title
  }
  location {
    id
  }
`;

export const getExperiencesForPromos = async ({ commit, dispatch }, locationId) => {
  commit('setLoading', true);
  const query = `{
    experiences (locationId: "${locationId}") {
      id
      title
      location {
        id
      }
    }
  }`;
  try {
    const { experiences } = await gql.request(query);
    dispatch('insertEntities', { name: 'Experience', payload: experiences });
  } finally {
    commit('setLoading', false);
  }
};

export const getLocationCoupons = async ({ commit, dispatch }, locationId) => {
  commit('setLoading', true);
  commit('promos/set', { path: 'isFetchingPromos', value: true });
  const query = `{
    coupons (location: { id: "${locationId}" }) {
      ${couponFragment}
    }
  }`;

  try {
    const data = await gql.request(query);

    dispatch('insertEntities', {
      name: 'Coupon',
      payload: data.coupons,
    });

    return data.coupons;
  } finally {
    commit('setLoading', false);
    commit('promos/set', { path: 'isFetchingPromos', value: false });
  }
};

export const storeCoupon = async ({ dispatch, commit }, coupon) => {
  commit('setLoading', true);
  const query = `
    mutation($coupon: CouponInput) {
      storeCoupon(coupon: $coupon) {
        ${couponFragment}
      }
    }
  `;

  try {
    const data = await gql.request(query, { coupon });
    dispatch('insertEntities', {
      name: 'Coupon',
      payload: data.storeCoupon,
    });
    return data.storeCoupon;
  } finally {
    commit('setLoading', false);
  }
};

export const deleteCoupon = async ({ dispatch }, coupon) => {
  const query = `
    mutation($coupon: CouponInput) {
      deleteCoupon(coupon: $coupon) {
        id
      }
    }
  `;

  dispatch('removeEntities', {
    name: 'Coupon',
    payload: coupon,
  });

  const data = await gql.request(query, { coupon });
  return data.deleteCoupon;
};

const discountFragment = `
  id
  title
  type
  active
  amount
  minStations
  startsAt
  endsAt
  displayDiscount
  experience {
    id
    title
  }
  location {
    id
  }
`;

export const getLocationDiscounts = async ({ commit, dispatch }, locationId) => {
  commit('setLoading', true);
  commit('promos/set', { path: 'isFetchingPromos', value: true });

  const query = `{
    discounts (location: { id: "${locationId}" }) {
      ${discountFragment}
    }
  }`;

  try {
    const data = await gql.request(query);

    dispatch('insertEntities', {
      name: 'Discount',
      payload: data.discounts,
    });

    return data.discounts;
  } finally {
    commit('setLoading', false);
    commit('promos/set', { path: 'isFetchingPromos', value: false });
  }
};

export const storeDiscount = async ({ dispatch, commit }, discount) => {
  commit('setLoading', true);
  const query = `
    mutation($discount: DiscountInput) {
      storeDiscount(discount: $discount) {
        ${discountFragment}
      }
    }
  `;

  try {
    const data = await gql.request(query, { discount });
    dispatch('insertEntities', {
      name: 'Discount',
      payload: data.storeDiscount,
    });
    return data.storeDiscount;
  } finally {
    commit('setLoading', false);
  }
};

export const deleteDiscount = async ({ dispatch }, discount) => {
  const query = `
    mutation($discount: DiscountInput) {
      deleteDiscount(discount: $discount) {
        id
      }
    }
  `;

  dispatch('removeEntities', {
    name: 'Discount',
    payload: discount,
  });

  const data = await gql.request(query, { discount });
  return data.deleteDiscount;
};
