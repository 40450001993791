import { gql } from '@/core/shared/api';

const waiverFragment = `
    id
    booking {
        id
        startTime
    }
    waiverContent {
        id
        language
    }
    firstName
    lastName
    email
    consentMarketing
    createdAt
`;

export const getSignedWaivers = async ({ commit, dispatch }, locationId) => {
  commit('setLoading', true);
  commit('waivers/set', { path: 'isFetchingWaivers', value: true });

  const query = `{
    waivers (location: { id: "${locationId}" }) {
      ${waiverFragment}
    }
  }`;

  try {
    const { waivers } = await gql.request(query);

    dispatch('insertEntities', { name: 'Waiver', payload: waivers });

    return waivers;
  } finally {
    commit('setLoading', false);
    commit('waiverContents/set', { path: 'isFetchingWaivers', value: false });
  }
};
