import { ClientError } from '../GQLClient';

export default ({ onClientError }, { store }) => {
  onClientError.push(error => {
    if (error instanceof ClientError) {
      const { response } = error;
      response.errors &&
        response.errors.forEach(({ message }) => {
          store.commit('setFlash', {
            type: 'error',
            message,
          });
          if (message.startsWith('403')) store.dispatch('logout');
        });
    }
    throw error;
  });
};
