import { get } from 'lodash-es';
import { i18n } from '@/core/setup/i18n';
import { createUserModel } from '@/core/shared/auth/store/models';
import { createOrganizationModel } from '@/core/shared/vuex-modules/organization';
import { createCountryModel } from '@/core/shared/vuex-modules/country';
import { createRegionModel } from '@/core/shared/vuex-modules/region';
import { createTimezoneModel } from '@/core/shared/vuex-modules/timezone';
import { createLanguageModel } from '@/core/shared/vuex-modules/language';
import { createCurrencyModel } from '@/core/shared/vuex-modules/currency';
import { createLocaleModel } from '@/core/shared/vuex-modules/locale';
import { formatAgeRating, formatDisplayDate } from '@/core/helpers/FormatHelpers';
import moment from 'moment';

function computeSrcset (images) {
  return images.map(({ url, width }) => `${url} ${width}`).join(', ');
}

function difficultyIntToString (int) {
  let string;
  switch (int) {
  case 1:
    string = 'Easy';
    break;
  case 2:
    string = 'Medium';
    break;
  case 3:
    string = 'Hard';
    break;
  default:
    string = '';
    break;
  }
  return string;
}
function formatResultAgeRating (result) {
  if (result.ageRating !== null && result.ageRating !== undefined) {
    result.displayAgeRating = formatAgeRating(result.ageRating);
  }
}

function computePriceChanges (result) {
  const computePriceChange = ({ current, upcoming }) => {
    if (!current || !upcoming) return null;
    const priceDiff = upcoming.price - (current ? current.price : 0);
    return {
      kind: priceDiff < 0 ? 'decrease' : 'increase',
      newPriceDisplay: upcoming.displayPrice,
      displayDateChange: formatDisplayDate(upcoming.startsAt, { utc: true }),
      displayMinDateChange: formatDisplayDate(upcoming.startsAt, { utc: true, minFormat: true }),
      priceBeforeDiscount: result.percentageOff && upcoming.displayPrice,
      discountDisplayEndDate: result.percentageOff && current.endsAt && moment(current.endsAt).format('LL'),
    };
  };
  const minute = result.minutePrice && computePriceChange(result.minutePrice);
  const monthly = result.monthlyPrice && computePriceChange(result.monthlyPrice);
  const yearly = result.yearlyPrice && computePriceChange(result.yearlyPrice);
  return {
    ...(minute && { minute }),
    ...(monthly && { monthly }),
    ...(yearly && { yearly }),
  };
}
function formatResponsiveImgUrls (result) {
  if (result.responsiveImageUrls) {
    const svgEntrieIndex = result.responsiveImageUrls.findIndex(
      ({ width }) => width === '32w',
    );
    result.imageSvgUrl =
      svgEntrieIndex === -1
        ? null
        : result.responsiveImageUrls[svgEntrieIndex].url;
    result.responsiveImageUrls.splice(svgEntrieIndex, 1);
    result.imageSrcSet = computeSrcset(result.responsiveImageUrls);
    delete result.responsiveImageUrls;
  }
  return result;
}
function formatDeferredLicenseAndOwnership (result) {
  if (!result.manuallyAdded) {
    if (!result.ownership) {
      delete result.ownership;
    }
    if (!result.license) {
      delete result.license;
    }
  }
  return result;
}
export default schema => {
  const User = createUserModel(schema);
  const Organization = createOrganizationModel(schema);
  const Country = createCountryModel(schema);
  const Region = createRegionModel(schema);
  const Timezone = createTimezoneModel(schema);
  const Language = createLanguageModel(schema);
  const Currency = createCurrencyModel(schema);
  const Locale = createLocaleModel(schema);
  const Tag = new schema.Entity('Tag', {});
  const Game = new schema.Entity(
    'Game',
    {
      organizationTags: [Tag],
    },
    {
      processStrategy (value) {
        const result = {
          ...value,
        };
        formatResultAgeRating(result);
        formatResponsiveImgUrls(result);
        formatDeferredLicenseAndOwnership(result);
        result.difficultyDisplay = difficultyIntToString(result.difficulty);
        return result;
      },
    },
  );
  const Discount = new schema.Entity('Discount', {});
  const Coupon = new schema.Entity('Coupon', {});
  const Widget = new schema.Entity('Widget', {});
  const WaiverContent = new schema.Entity('WaiverContent', {});
  const Waiver = new schema.Entity('Waiver', {});
  const Gateway = new schema.Entity('Gateway', {});
  const Location = new schema.Entity('Location', {
    gateways: [Gateway],
  }, {
    processStrategy (value) {
      if (value.integrations) {
        const hasSpringboardVRBookingIntegration = !!value.integrations.find(integration => {
          return integration.type === 'springboardvr';
        });

        value._hasSpringboardVRBookingIntegration = hasSpringboardVRBookingIntegration;
      }

      return value;
    },
  });
  Gateway.define({
    location: Location,
  });
  Organization.define({
    locations: [Location],
  });
  const Booking = new schema.Entity('Booking', {});
  const Experience = new schema.Entity('Experience', {
    // location: Location
  });
  const Station = new schema.Entity('Station', {
    experience: Experience,
  });
  const GameKey = new schema.Entity('GameKey', {});
  const StationGame = new schema.Entity('StationGame', {
    station: Station,
    game: Game,
    key: GameKey,
  });
  const CommercialLicense = new schema.Entity('CommercialLicense', {});
  const ContentOwnershipLicense = new schema.Entity('ContentOwnershipLicense', {
    game: Game,
    yearlyLicenses: [CommercialLicense],
    monthlyLicenses: [CommercialLicense],
  });
  Experience.define({
    stations: [Station],
  });
  Location.define({
    experiences: [Experience],
    stations: [Station],
  });
  Station.define({
    stationGames: [StationGame],
  });
  const ContentOwnership = new schema.Entity(
    'ContentOwnership',
    {
      operatorGame: Game,
      operatorLicense: ContentOwnershipLicense,
    },
    {
      processStrategy (value) {
        const result = {
          ...value,
        };
        result.difficultyDisplay = difficultyIntToString(result.difficulty);
        formatResultAgeRating(result);
        formatResponsiveImgUrls(result);
        result.priceChanges = computePriceChanges(result);
        result.hasPriceChanges = Object.values(result.priceChanges).length;
        if (get(result, 'minutePrice.current.price') === 0) {
          result.minutePrice.current.displayPrice = i18n.t('Free');
          result.isFree = true;
        }
        return result;
      },
    },
  );
  Game.define({
    ownership: ContentOwnership,
    stationGames: [StationGame],
    license: ContentOwnershipLicense,
  });
  const Genre = new schema.Entity('Genre', {});
  const BundleContent = new schema.Entity('BundleContent', {
    contentOwnership: ContentOwnership,
  });
  const Bundle = new schema.Entity(
    'Bundle',
    {
      contents: [BundleContent],
      contentOwnership: ContentOwnership,
    },
    {
      processStrategy (value) {
        const result = { ...value };
        if (value.contentOwnership) {
          formatResultAgeRating(result.contentOwnership);
          result.priceChanges = computePriceChanges(result);
          result.hasPriceChanges = Object.values(result.priceChanges).length;
          if (get(result, 'minutePrice.current.price') === 0) {
            result.minutePrice.current.displayPrice = i18n.t('Free');
            result.isFree = true;
          }
        }
        return result;
      },
    },
  );
  const BundleLicense = new schema.Entity('BundleLicense', {
    bundle: Bundle,
    operator: Organization,
  });
  ContentOwnership.define({
    bundle: Bundle,
  });
  return {
    User,
    Organization,
    Country,
    Region,
    Timezone,
    Language,
    Currency,
    Locale,
    Location,
    Gateway,
    Game,
    StationGame,
    Experience,
    Station,
    Tag,
    Bundle,
    BundleContent,
    BundleLicense,
    ContentOwnership,
    CommercialLicense,
    ContentOwnershipLicense,
    GameKey,
    Discount,
    Coupon,
    Widget,
    WaiverContent,
    Waiver,
    Genre,
    Booking,
  };
};
