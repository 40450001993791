export const contentOwnershipFragment = `
  id
  title
  description
  imageUrl
  responsiveImageUrls {
    url
    width
  }
  videoWebmUrl
  videoMp4Url
  freeDownload
  headsetHtcVive
  headsetOculusRift
  headsetWindowsMr
  headsetValveIndex
  displayExperienceType
  ageRating
  difficulty
  developers
  publishers
  cds
  currentAgreement {
    id
    content
    endsAt
    startsAt
    updatedAt
    isCurrent
  }
  upcomingAgreement {
    id
    content
    startsAt
    endsAt
    updatedAt
    isCurrent
  }
  genres {
    id
    title
  }
  singleplayer
  multiplayer
  minPlayers
  maxPlayers
  upcomingPrice {
    displayPriceMinute
    priceMinute
    startsAt
  }
  currentPrice {
    displayPriceMinute
    priceMinute
  }
  minutePrice {
    current {
      startsAt
      endsAt
      price
      displayPrice
    }
    upcoming {
      startsAt
      price
      displayPrice
    }
  }
  monthlyPrice {
    current {
      startsAt
      endsAt
      price
      displayPrice
    }
    upcoming {
      startsAt
      price
      displayPrice
    }
  }
  yearlyPrice {
    current {
      startsAt
      endsAt
      price
      displayPrice
    }
    upcoming {
      startsAt
      price
      displayPrice
    }
  }
  steamId
  lbeVersion
  communityUrl
  contentGuideUrl
  reviewCons
  reviewPros
  latestVersionDate
  marketingAssetsUrl
  releaseDate
  operatorGame { id }
  operatorLicense {
    id
    promptForCurrentLicense
    promptForUpcomingLicense
    disabledForOutOfDateAgreement
  }
  badgeTop10
  badgeRecentlyUpdated
  percentageOff
  systemRequirements
  contentOwnershipLanguages {
    displayLanguage
    interface
    fullAudio
    subtitles
  }
  updateNotes
  rating {
    percentage
    text
    count
  }
  regionalPricing
  __typename
  bundle {
    id
  }
`;

export const gameFragment = `
id
title
imageUrl
responsiveImageUrls {
  url
  width
}
videoUrl
installed
manuallyAdded
keysTotal
keysUsed
keysAvailable
cds
ownership {
  ${contentOwnershipFragment}
}
preset {
  id
}
organizationTags {
  id
  text
}
description
difficulty
developers
publishers
singleplayer
multiplayer
minPlayers
maxPlayers
ageRating
license {
  endsAt
  startsAt
  id
  upcomingInvoiceAmount {
    displayValue
    value
  }
  currentTotalUsage {
    displayValue
  }
  monthlyLicensesCount
  yearlyLicensesCount
}
costEstimateMonth {
  displayValue
  value
}
order
upcomingPrice {
  displayPriceMinute
  priceMinute
  startsAt
}
stationsAvailable
stationsInstalled
currentPrice {
  displayPriceMinute
}
installErrorMessage
cdsTransitionError
agreementError
updatedAt
__typename
`;

export const gameFragmentWithDefer = `
id
title
imageUrl
responsiveImageUrls @defer {
  url
  width
}
videoUrl
installed
manuallyAdded
keysTotal
keysUsed
keysAvailable
cds
ownership @defer {
  ${contentOwnershipFragment}
}
preset @defer {
  id
}
organizationTags @defer {
  id
  text
}
description
difficulty
developers
publishers
singleplayer
multiplayer
minPlayers
maxPlayers
ageRating
license @defer {
  endsAt
  startsAt
  id
  upcomingInvoiceAmount {
    displayValue
    value
  }
  currentTotalUsage {
    displayValue
  }
  monthlyLicensesCount
  yearlyLicensesCount
}
costEstimateMonth {
  displayValue
  value
}
order
upcomingPrice @defer {
  displayPriceMinute
  priceMinute
  startsAt
}
stationsAvailable
stationsInstalled
currentPrice @defer {
  displayPriceMinute
}
installErrorMessage
cdsTransitionError
agreementError
updatedAt
__typename
`;
export const gameFragmentWithDetails = `
${gameFragment}
license {
  currentMinuteCost {
    total {
      displayValue
    }
  }
  currentMonthlyCost {
    quantity
    total {
      displayValue
    }
  }
  currentMinuteUsage {
    displayValue
    value
  }
  currentMonthlyUsage {
    displayValue
    value
  }
  currentYearlyUsage {
    displayValue
    value
  }
  monthlyLicenses {
    id
    startsAt
    endsAt
    renewable
  }
  yearlyLicenses {
    id
    startsAt
    endsAt
    renewsAt
    renewable
  }
}
headsetHtcVive,
headsetWindowsMr,
headsetOculusRift,
headsetValveIndex,
launchMethod
arguments
exe
filePath
uri
controlsEnabled
controlsTrigger
controlsGrip
controlsMenu
controlsTouchpad
controlsExtra
organizationTags {
  id
  text
}
preset {
  id
}
stationGames {
  id
  station { id }
  game { id }
  status
  targetStatus
  progress
  eta
  enabled
  errorType
  errorMessage
  key {
    id
    key
  }
  updatedAt
}
`;
