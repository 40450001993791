import { parseQueryString } from '@/core/shared/helpers/url-helper';
import { isUndefined } from 'lodash-es';

export default (store, i18n) => {
  const params = parseQueryString(location.search.substring(1));

  if (!isUndefined(params.isEmbedded)) {
    store.commit('app/set', {
      path: 'isEmbedded',
      value: true,
    });
  }

  if (!isUndefined(params.stationCreate) && params.stationCreate === 'success') {
    store.commit('setFlash', {
      type: 'success',
      message: `${i18n.t('A new station was successfully created.')} <a href="/my-station/index" class="link-style">${i18n.t('View My Stations.')}</a>`,
    });
  }
};
