import * as actions from './actions';

export default {
  strict: true,
  state: {
    isFetchingTags: false,
    filteredGameIds: null,
  },
  actions,
  mutations: {
    'tags/set' (state, { path, value }) {
      state[path] = value;
    },
  },
};
