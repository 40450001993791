import { gql } from '../api';

export const createLanguageModel = schema => {
  return new schema.Entity('Language', {}, {
    idAttribute: 'key',
  });
};

export default {
  strict: true,
  actions: {
    getLanguages: async ({ commit, dispatch }, extra = '') => {
      commit('setLoading', true);
      commit('language/set', { path: 'isFetchingLanguages', value: true });

      try {
        const query = `{
          languages {
            key
            title
            ${extra}
          }
        }`;

        const { languages } = await gql.request(query);

        dispatch('insertEntities', {
          name: 'Language',
          payload: languages,
        });
      } finally {
        commit('setLoading', false);
        commit('language/set', { path: 'isFetchingLanguages', value: false });
      }
    },
  },
  state: {
    isFetchingLanguages: false,
  },
  mutations: {
    'language/set': (state, { path, value }) => {
      state[path] = value;
    },
  },
};
