import { gql } from '@/core/shared/api';
import { tagFragment } from './fragments';
import { debounce } from 'lodash-es';

export const getTags = async ({ commit, dispatch }) => {
  commit('setLoading', true);
  commit('tags/set', {
    path: 'isFetchingTags',
    value: true,
  });
  try {
    const query = `{
      tags {
        ${tagFragment}
      }
    }`;
    const data = await gql.request(query);

    if (data) {
      dispatch('insertEntities', {
        name: 'Tag',
        payload: data.tags,
      });
      return data.tags;
    }
  } finally {
    commit('tags/set', {
      path: 'isFetchingTags',
      value: false,
    });
    commit('setLoading', false);
  }
};

export const saveTagsOrder = async ({ dispatch, commit }, tags) => {
  commit('setLoading', true);

  const newTagOrder = tags.map((tag, i) => {
    return {
      ...tag,
      order: i,
    };
  });

  dispatch('insertEntities', {
    name: 'Tag',
    payload: newTagOrder,
  });

  const storeTagSubQueries = newTagOrder.map(tag => {
    return `tag${tag.order}: storeTag(tag: { id: "${tag.id}", order: ${tag.order} }) {
      id
      order
    }`;
  });

  const query = `mutation {
    ${storeTagSubQueries.join('\n')}
  }`;

  try {
    const data = await gql.request(query, null, 'saveTagsOrder', {
      cancelKey: 'tagOrder',
    });
    if (data) {
      const result = Object.values(data).map(({ id }) => id);
      return result;
    }
  } finally {
    commit('setLoading', false);
  }
};

export const getGamesForTagsRoute = async ({ commit, dispatch }, locationId) => {
  commit('setLoading', true);

  const query = `{
    games (search: "") {
      id
      title
      organizationTags {
        id
        text
      }
    }
  }`;

  try {
    const { games } = await gql.request(query);

    const result = await dispatch('insertEntities', {
      name: 'Game',
      payload: games,
    });

    commit('library/set', {
      path: 'games',
      value: result,
    });
  } finally {
    commit('setLoading', false);
  }
};

const gameSearchDebounced = debounce(async ({ commit }, { search, filter, tag }) => {
  commit('setLoading', true);

  const query = `query {
    tagGames(tag: { id: "${tag.id}" }, search: "${search}", filter: "${filter}") {
      id
    }
  }`;

  try {
    const { tagGames } = await gql.request(query);
    commit('tags/set', {
      path: 'filteredGameIds',
      value: tagGames.map(game => game.id),
    });
  } finally {
    commit('setLoading', false);
  }
}, 500);

export const tagGameSearch = ({ commit }, { search, filter, tag }) => {
  if (search.length === 0 && filter === 'all') {
    commit('tags/set', {
      path: 'filteredGameIds',
      value: null,
    });
  } else {
    gameSearchDebounced({ commit }, { search, filter, tag });
  }
};

export const resetTagGameSearch = ({ commit }) => {
  commit('tags/set', {
    path: 'filteredGameIds',
    value: null,
  });
};

export const storeTag = async ({ dispatch, commit }, tag) => {
  commit('setLoading', true);

  const query = `mutation (
    $tag: TagInput
  ) {
    storeTag (tag: $tag) {
      ${tagFragment}
    }
  }`;

  try {
    const { storeTag } = await gql.request(query, { tag });
    dispatch('insertEntities', {
      name: 'Tag',
      payload: storeTag,
    });
  } catch (e) {
    dispatch('getTags');
  } finally {
    commit('setLoading', false);
    dispatch('getMyLicenses');
  }
};

export const deleteTag = async ({ dispatch, commit }, tag) => {
  commit('setLoading', true);

  const query = `mutation {
    deleteTag (tag: { id: "${tag.id}" }) {
      id
    }
  }`;

  try {
    await gql.request(query, { tag });

    dispatch('removeEntities', {
      name: 'Tag',
      payload: { id: tag.id },
    });
  } catch (e) {
    dispatch('getTags');
  } finally {
    commit('setLoading', false);
    dispatch('getMyLicenses');
  }
};
