import * as getters from './getters';
import * as actions from './actions';

export const createOrganizationModel = schema => {
  return new schema.Entity('Organization', {});
};

export default {
  strict: true,
  state: {
    currentOrganizationId: '',
    isFetchingOrganization: false,
  },
  getters,
  actions,
  mutations: {
    'organization/set': (state, { path, value }) => {
      state[path] = value;
    },
  },
};
