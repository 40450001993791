/* eslint-disable camelcase */
import { gql } from '@/core/shared/api';

const widgetFragment = `
id
name
location {
  id
}
buttonText
mini
dark_mode
submitButtonBackgroundColor
submitButtonTextColor
embedCode
embedLink
emailSetupLink
redirectUrl
uaCode
analyticsPixelUrlStep1
analyticsPixelUrlStep2
analyticsPixelUrlStep3
analyticsPixelUrlConfirm
logoUrl
backgroundImageUrl
`;

export const getLocationWidgets = async ({ commit, dispatch }, locationId) => {
  commit('setLoading', true);
  commit('widgets/set', { path: 'isFetchingWidgets', value: true });

  const query = `{
    bookingWidgets (location: { id: "${locationId}" }) {
      ${widgetFragment}
    }
  }`;

  try {
    const data = await gql.request(query);

    dispatch('insertEntities', {
      name: 'Widget',
      payload: data.bookingWidgets,
    });

    return data.bookingWidgets;
  } finally {
    commit('setLoading', false);
    commit('widgets/set', { path: 'isFetchingWidgets', value: false });
  }
};

export const storeWidget = async ({ dispatch, commit }, widget) => {
  commit('setLoading', true);
  const imageBlob = widget._blob;
  const query = `
    mutation($widgetInput: BookingWidgetInput) {
      storeBookingWidget(bookingWidget: $widgetInput) {
        ${widgetFragment}
      }
    }
  `;

  const {
    id,
    organization,
    location,
    name,
    buttonText,
    mini,
    dark_mode,
    submitButtonBackgroundColor,
    submitButtonTextColor,
    embedCode,
    embedLink,
    emailSetupLink,
    redirectUrl,
    uaCode,
    analyticsPixelUrlStep1,
    analyticsPixelUrlStep2,
    analyticsPixelUrlStep3,
    analyticsPixelUrlConfirm,
  } = widget;
  const widgetInput = {
    id,
    organization,
    location,
    name,
    buttonText,
    mini,
    dark_mode,
    submitButtonBackgroundColor,
    submitButtonTextColor,
    embedCode,
    embedLink,
    emailSetupLink,
    redirectUrl,
    uaCode,
    analyticsPixelUrlStep1,
    analyticsPixelUrlStep2,
    analyticsPixelUrlStep3,
    analyticsPixelUrlConfirm,
  };

  try {
    const files = [];
    if (imageBlob) {
      for (const [key, blob] of Object.entries(imageBlob)) {
        files.push({ name: key, file: blob.file });
      }

      widgetInput.blobFieldNames = Object.keys(imageBlob).map((key) => {
        return {
          name: key,
          type: imageBlob[key].type,
        };
      });
    }

    const data = await gql.requestWithBlobs(query,
      {
        widgetInput,
      },
      imageBlob && files,
    );
    dispatch('insertEntities', {
      name: 'Widget',
      payload: data.storeBookingWidget,
    });
    return data.storeBookingWidget;
  } finally {
    commit('setLoading', false);
  }
};

export const deleteWidget = async ({ dispatch }, widget) => {
  const { id } = widget;

  const query = `
    mutation($widget: BookingWidgetInput) {
      deleteBookingWidget(bookingWidget: $widget) {
        id
      }
    }
  `;

  dispatch('removeEntities', {
    name: 'Widget',
    payload: {
      id,
    },
  });

  const data = await gql.request(query, { widget });
  return data.deleteBookingWidget;
};
