<template>
  <div :class="{'in': loading}" class="loading-bar">
    <span>&nbsp;</span>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="postcss">
@import '../styles';

.loading-bar {
  z-index: var(--zLoadingBar);
  transform: translateY(-100%) translateZ(0);
  will-change: transform, opacity;
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 0;

  &.in {
    transform: translateY(0) translateZ(0);
    opacity: 1;
  }
}
</style>
