export const userFragment = `
  id
  name
  email
  allLocations
  accessLevel
  validated
  locations {
    id
    title
  }
`;
