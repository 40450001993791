export const state = {
  contentOwnershipIds: [],
  fetchedPages: [],
  paginatorInfo: null,
  filterOptions: {
    search: '',
    sortType: 'createdAt',
    sortDirection: 'DESC',
    filterCds: false,
    filterContentTarget: null,
    filterGenres: null,
    filterPromoted: false,
    filterRegional: false,
    filterBundles: false,
  },
};
