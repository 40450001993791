import moment from 'moment-timezone';

export default class DateHelper {
  static formatMillisecondsToHMS (milliseconds) {
    let seconds = (milliseconds / 1000).toFixed(0);
    let minutes = Math.floor(seconds / 60);
    let hours = '';
    if (minutes > 59) {
      hours = Math.floor(minutes / 60);
      hours = hours >= 10 ? hours : '0' + hours;
      minutes = minutes - hours * 60;
      minutes = minutes >= 10 ? minutes : '0' + minutes;
    }

    seconds = Math.floor(seconds % 60);
    seconds = seconds >= 10 ? seconds : '0' + seconds;
    if (hours !== '') {
      return hours + ':' + minutes + ':' + seconds;
    }
    return minutes + ':' + seconds;
  }

  static dateMatch (date1, date2) {
    const a = moment(date1);
    const b = moment(date2);

    return (
      a.year() === b.year() && a.month() === b.month() && a.date() === b.date()
    );
  }

  static getClientNowMoment () {
    return moment.tz(moment.tz.guess());
  }

  static doesLocaleUses24HourTime (locale) {
    const string = new Date().toLocaleString(locale);
    return !isNaN(parseInt(string.slice(string.length - 1)));
  }

  static isISO8601String (dateTimeString) {
    return dateTimeString.match(
      /^([0-9]{4})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])T([0-1][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])(Z|(-|\+)[0-1][0-9]:[0-5][0-9])$/,
    );
  }
}
