import configureEnvironment from '@/core/shared/setup/configureEnvironment';
configureEnvironment();

if (window.sbvrenv.NODE_ENV === 'development') {
  window.sbvrenv.STRIPE_KEY = 'pk_test_51IEdY0Gvz8q3ri0pAjRSJofS9aDYtExQfBtNxkSS5PKiiDBNCKuAGqdH4xvmksEAdTL15vdiSQeuOXHgA3F219sx00ytFAQrm6';
} else if (
  window.sbvrenv.NODE_ENV === 'staging' ||
  window.sbvrenv.NODE_ENV === 'review'
) {
  window.sbvrenv.STRIPE_KEY = 'pk_test_51IEdY0Gvz8q3ri0pAjRSJofS9aDYtExQfBtNxkSS5PKiiDBNCKuAGqdH4xvmksEAdTL15vdiSQeuOXHgA3F219sx00ytFAQrm6';
} else if (window.sbvrenv.NODE_ENV === 'production') {
  window.sbvrenv.STRIPE_KEY = 'pk_live_51IEdY0Gvz8q3ri0p0ZJcwNRjSoovgqULNZSJR53myXC02sa5Y4b0aKA7ujDSMJFdh8BApJ1EXE4Z9xxaRxUiSbsz00dDqG1HM1';
}
