export const setDisableAccountModalVisibility = (state, value) => {
  state.isDisableAccountModalVisible = Boolean(value);
  state.disableAccountModalType = value || null;
};
export const setBillingPlan = (state, value) => {
  state.billingPlan = value;
};
export const setBillingPlans = (state, value) => {
  state.billingPlans = value;
};
export const startBillingLoading = (state, value) => {
  state.loadings[value] = true;
};
export const endBillingLoading = (state, value) => {
  state.loadings[value] = false;
};
export const setProratedAmount = (state, value) => {
  state.proratedAmount = value;
};
export const setSetupIntent = (state, value) => {
  state.setupIntent = value;
};
export const setDefaultCreditCard = (state, value) => {
  state.organization.defaultCreditCard = value;
};
