const abortControllers = new Map();

export default ({ beforeRequest, onClientError, GQLClient }) => {
  beforeRequest.push((fetchOpts, opts) => {
    const { cancelKey } = opts;
    if (cancelKey) {
      const abortController = abortControllers.get(cancelKey);
      abortController && abortController.abort();
      const newAbortController = new AbortController();
      abortControllers.set(cancelKey, newAbortController);
      fetchOpts.signal = newAbortController.signal;
    }
  });
  GQLClient.prototype.cancelLast = function (cancelKey) {
    const abortController = abortControllers.get(cancelKey);
    abortController && abortController.abort();
  };
  onClientError.push((error, opts) => {
    const { cancelKey, throwAbortError = false } = opts;
    if (error.name === 'AbortError' && !throwAbortError) {
      process.env.NODE_ENV !== 'production' &&
          // eslint-disable-next-line no-console
          console.warn(
            `AbortError: a query with cancelKey - ${cancelKey} - was cancelled`,
          );
    } else {
      throw error;
    }
  });
};
