export default (
  { beforeRequest },
  { store, tokenPath = 'auth.token', tenantIdPath = 'auth.tenantId' },
) => {
  beforeRequest.push(({ headers }) => {
    headers.set('Authorization', 'Bearer ' + ref(store.state, tokenPath));
    headers.set('X-Tenant-Id', ref(store.state, tenantIdPath));
  });
};

function ref (obj, str) {
  if (str) {
    return str.split('.').reduce(function (o, x) {
      return o[x];
    }, obj);
  }
}
