export const parseQueryString = () => {
  const url = new URL(location.href);
  const searchParams = new URLSearchParams(url.search.slice(1));

  return Array.from(searchParams.keys()).reduce((params, key) => {
    return {
      ...params,
      [key]: searchParams.get(key),
    };
  }, {});
};

export const addToQueryString = (key, value) => {
  const url = new URL(location.href);
  const searchParams = new URLSearchParams(url.search.slice(1));

  searchParams.set(key, value);

  url.search = searchParams.toString();

  window.history.replaceState({}, '', url);
};

export const removeFromQueryString = (key) => {
  const url = new URL(location.href);
  const searchParams = new URLSearchParams(url.search.slice(1));

  searchParams.delete(key);

  url.search = searchParams.toString();

  window.history.replaceState({}, '', url);
};

export const getCookieDomain = () => {
  if (window && window.sbvrenv.NODE_ENV === 'development') {
    return location.host.includes('docker') ? '.springboardvr.docker' : location.hostname;
  } else {
    return '.springboardvr.com';
  }
};

export const stringify = params => {
  return `?${Object.keys(params).map(paramKey => paramKey + '=' + params[paramKey]).join('&')}`;
};
