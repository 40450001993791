import Vue from 'vue';
import store from '@/core/store';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

export default function initSentry () {
  if (
    window.sbvrenv.NODE_ENV !== 'development' &&
    process.env.NODE_ENV === 'production'
  ) {
    Sentry.init({
      dsn: 'https://2d727220e4664ec8923bb0f93f81aeb6@sentry.io/1201041',
      integrations: [
        new Integrations.Vue({
          Vue,
          attachProps: true,
        }),
      ],
      environment: window.sbvrenv.NODE_ENV,
      debug: true,
    });
    Sentry.configureScope(scope => {
      scope.setTag('git_commit', COMMITHASH);
      if (window.sbvrenv.TRANSACTION_ID) {
        scope.setTag('transaction_id', window.sbvrenv.TRANSACTION_ID);
      }
    });
    const unsub = store.watch(
      (state, { currentOrganization }) => currentOrganization,
      val => {
        if (val) {
          const { id, name: username } = val;
          Sentry.configureScope(scope => {
            scope.setUser({
              id,
              username,
            });
            if (store.getters.currentUser) {
              const { id, email } = store.getters.currentUser;
              scope.setExtra({
                user: {
                  id,
                  email,
                },
              });
            }
          });
          unsub();
        }
      },
    );
  }
}
