import Vue from 'vue';
import Vuex from 'vuex';
import * as getters from './getters';
import * as actions from './actions';
import plugins from './plugins';

import auth from '@/core/shared/auth/store';
import organization from '@/core/shared/vuex-modules/organization';
import country from '@/core/shared/vuex-modules/country';
import region from '@/core/shared/vuex-modules/region';
import language from '@/core/shared/vuex-modules/language';
import timezone from '@/core/shared/vuex-modules/timezone';
import currency from '@/core/shared/vuex-modules/currency';
import locale from '@/core/shared/vuex-modules/locale';
import webPanel from '@/core/shared/web-panel/store';
import net from '@/core/shared/net';

import myStations from '@/my-station/store';

import admin from '@/admin/store';
import billing from '@/admin/billing/store';
import users from '@/admin/users/store';

import marketplace from '@/content/marketplace/store';
import library from '@/content/library/store';
import tags from '@/content/tags/store';

import location from '@/location/store';
import experiences from '@/location/experiences/store';
import stations from '@/location/stations/store';
import promos from '@/location/promos/store';
import widgets from '@/location/widgets/store';
import waiverContents from '@/location/waiver-contents/store';
import waivers from '@/location/waivers/store';

import common from './modules/common';

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: true,
  state: {
    isEmbedded: false,
  },
  mutations: {
    'app/set': (state, { path, value }) => {
      state[path] = value;
    },
  },
  getters,
  actions,
  plugins,
  modules: {
    'vuex-connect': {},
    common,
    auth,
    organization,
    country,
    region,
    language,
    timezone,
    currency,
    locale,
    webPanel,
    net,

    myStations,

    admin,
    billing,
    users,

    marketplace,
    library,
    tags,

    location,
    experiences,
    stations,
    promos,
    widgets,
    waiverContents,
    waivers,
  },
});

if (module.hot) {
  module.hot.accept(['./getters', './actions'], () => {
    store.hotUpdate({
      getters: require('./getters'),
      actions: require('./actions'),
      modules: {
        auth: require('@/core/shared/auth/store'),
        organization: require('@/core/shared/vuex-modules/organization'),
        country: require('@/core/shared/vuex-modules/country'),
        region: require('@/core/shared/vuex-modules/region'),
        language: require('@/core/shared/vuex-modules/language'),
        timezone: require('@/core/shared/vuex-modules/timezone'),
        currency: require('@/core/shared/vuex-modules/currency'),
        locale: require('@/core/shared/vuex-modules/locale'),
        webPanel: require('@/core/shared/web-panel/store'),
        net: require('@/core/shared/net'),

        myStations: require('@/my-station/store'),

        admin: require('@/admin/store'),
        billing: require('@/admin/billing/store'),
        users: require('@/admin/users/store'),

        marketplace: require('@/content/marketplace/store'),
        library: require('@/content/library/store'),
        tags: require('@/content/tags/store'),

        location: require('@/location/store'),
        experiences: require('@/location/experiences/store'),
        stations: require('@/location/stations/store'),
        promos: require('@/location/promos/store'),
        widgets: require('@/location/widgets/store'),
        waiverContents: require('@/location/waiver-contents/store'),
        waivers: require('@/location/waivers/store'),
      },
    });
  });
}

export default store;
