import { gql } from '@/core/shared/api';
import { i18n } from '@/core/setup/i18n';
import { stationFragment, stationDetailFragment } from './fragments';
import { debounce } from 'lodash-es';

export const getLocationStations = async ({ commit, dispatch }, locationId) => {
  commit('setLoading', true);
  commit('stations/set', {
    path: 'isFetchingStations',
    value: true,
  });
  const query = `{
    stations (locationId: "${locationId}") {
      ${stationFragment}
    }
  }`;
  try {
    const { stations } = await gql.request(query);
    const result = await dispatch('insertEntities', {
      name: 'Station',
      payload: stations,
    });
    return result;
  } finally {
    commit('setLoading', false);
    commit('stations/set', {
      path: 'isFetchingStations',
      value: false,
    });
  }
};

export const getLocationExperiencesForStation = async (
  { commit, dispatch },
  locationId,
) => {
  commit('setLoading', true);
  const query = `{
    experiences (locationId: "${locationId}") {
      id
      title
      type
      order
      location { id }
    }
  }`;
  try {
    const { experiences } = await gql.request(query);
    const result = await dispatch('insertEntities', { name: 'Experience', payload: experiences });
    commit('stations/set', {
      path: 'experiencesIds',
      value: result,
    });
    return result;
  } finally {
    commit('setLoading', false);
  }
};

export const getGamesForStation = async ({ commit, dispatch }) => {
  commit('setLoading', true);
  commit('stations/set', {
    path: 'isFetchingGames',
    value: true,
  });
  const query = `{
    games {
      id
      title
      cds
      stationsInstalled
      ownership {
        id
        cds
        freeDownload
        currentPrice {
          displayPriceMinute
        }
        operatorLicense {
          id
        }
        minutePrice {
          current {
            startsAt
          }
        }
      }
      license {
        id
        monthlyLicensesCount
        yearlyLicensesCount
      }
      installErrorMessage
      cdsTransitionError
      agreementError
    }
  }`;
  try {
    const { games } = await gql.request(query);
    const result = await dispatch('insertEntities', {
      name: 'Game',
      payload: games,
    });
    return result;
  } finally {
    commit('setLoading', false);
    commit('stations/set', {
      path: 'isFetchingGames',
      value: false,
    });
  }
};

export const getStationDetail = async ({ commit, dispatch }, stationId) => {
  commit('setLoading', true);
  commit('stations/set', {
    path: 'isFetchingStationDetail',
    value: true,
  });
  const query = `{
    station (stationId: "${stationId}") {
      ${stationDetailFragment}
    }
  }`;
  try {
    const { station } = await gql.request(query);
    dispatch('insertEntities', {
      name: 'Station',
      payload: station,
    });
    return station;
  } finally {
    commit('setLoading', false);
    commit('stations/set', {
      path: 'isFetchingStationDetail',
      value: false,
    });
  }
};

export const saveExperienceOrder = async ({ commit, dispatch }, ids) => {
  await dispatch('insertEntities', {
    name: 'Experience',
    payload: ids.map((id, i) => ({ id, order: i })),
  });
  commit('setLoading', true);
  const storeExperienceSubQuery = ids.map((id, i) => {
    const vars = { id, order: i };
    return `experience${i}: storeExperience(experience: ${JSON.stringify(
      vars,
    ).replace(/"([^(")"]+)":/g, '$1:')}) {
    id
    order
  }`;
  });
  const query = `mutation {
    ${storeExperienceSubQuery.join('\n')}
  }`;
  try {
    const data = await gql.request(query, null, {
      cancelKey: 'saveExperienceOrder',
    });
    if (data) {
      const experiences = Object.values(data);
      await dispatch('insertEntities', { name: 'Experience', payload: experiences });
      return experiences;
    }
  } finally {
    commit('setLoading', false);
  }
};

export const saveStationsOrder = async ({ commit, dispatch }, ids) => {
  await dispatch('insertEntities', {
    name: 'Station',
    payload: ids.map((id, i) => ({ id, order: i })),
  });
  commit('setLoading', true);
  const storeStationSubQuery = ids.map((id, i) => {
    const vars = { id, order: i };
    return `station${i}: storeStation(station: ${JSON.stringify(vars).replace(
      /"([^(")"]+)":/g,
      '$1:',
    )}) {
    id
    order
  }`;
  });
  const query = `mutation {
    ${storeStationSubQuery.join('\n')}
  }`;
  try {
    const data = await gql.request(query, null, {
      cancelKey: 'saveExperienceOrder',
    });
    if (data) {
      const stations = Object.values(data);
      await dispatch('insertEntities', {
        name: 'Station',
        payload: stations,
      });
      return stations;
    }
  } finally {
    commit('setLoading', false);
  }
};

export const storeStation = async (
  { commit, dispatch, rootGetters },
  station,
) => {
  commit('setLoading', true);
  const { hardware, ...stationToSave } = station;

  if (stationToSave.location) {
    stationToSave.location = {
      id: stationToSave.location.id,
    };
  }

  if (stationToSave.games) {
    stationToSave.games = stationToSave.games.map(({ id }) => ({
      id,
    }));
  }

  const query = `
    mutation($station: StationInput) {
      station: storeStation(station: $station) {
        ${stationFragment}
      }
    }
  `;
  try {
    const data = await gql.request(query, { station: stationToSave });
    if (!('id' in stationToSave)) {
      // update global station count across all locations if a new station is created
      const { currentStationCount, id } = rootGetters.currentOrganization;
      dispatch('insertEntities', {
        name: 'Organization',
        payload: {
          id,
          currentStationCount: currentStationCount + 1,
        },
      });
    }
    dispatch('insertEntities', {
      name: 'Station',
      payload: data.station,
    });
    return data.station;
  } finally {
    commit('setLoading', false);
  }
};

export const deleteStation = async ({ getters, dispatch, commit }, station) => {
  commit('setLoadingOverlay', true);
  const query = `
    mutation($station: StationInput) {
      deleteStation(station: $station) {
        id
      }
    }
  `;
  const successMsg = i18n.t('Station successfully deleted.');
  const errorMsg = i18n.t(
    'There was an error deleting your station, please try again.',
  );
  try {
    const data = await gql.request(query, { station });
    dispatch('removeEntities', {
      name: 'Station',
      payload: station,
    });
    // update global station count across all locations
    const { currentStationCount, id } = getters.currentOrganization;
    dispatch('insertEntities', {
      name: 'Organization',
      payload: {
        id,
        currentStationCount: currentStationCount - 1,
      },
    });
    commit('setFlash', {
      message: successMsg,
      type: 'success',
    });
    return data.deleteStation;
  } catch (e) {
    commit('setFlash', {
      message: errorMsg,
      type: 'error',
    });
    throw e;
  } finally {
    commit('setLoadingOverlay', false);
  }
};

const gameSearchDebounced = debounce(
  async ({ commit }, { stationId, search, filter }) => {
    commit('setLoading', true);

    const query = `query {
    stationFilterGames (station: { id: "${stationId}" } search: "${search}", filter: "${filter}") {
      id
    }
  }`;

    try {
      const { stationFilterGames } = await gql.request(query);
      commit('stations/set', {
        path: 'filteredGameIds',
        value: stationFilterGames.map(game => game.id),
      });
    } finally {
      commit('setLoading', false);
    }
  },
  500,
);

export const stationGameSearch = (
  { commit, rootGetters },
  { stationId, search, filter },
) => {
  if (search.length === 0 && filter === 'all') {
    commit('stations/set', {
      path: 'filteredGameIds',
      value: null,
    });
  } else if (stationId === 'new') {
    const stationGames =
      rootGetters[`stationManagement:${stationId}/stationGamesMapKeydByGame`];
    const gameResults = rootGetters['entities/Game/all']().filter(game => {
      const pattern = new RegExp(search, 'i');
      const searchCheck = game.title.match(pattern);
      const filterCheck =
        filter === 'all' ||
        (filter === 'enabled'
          ? stationGames[game.id].enabled
          : !stationGames[game.id].enabled);
      return searchCheck && filterCheck;
    });

    const ids = gameResults.map(({ id }) => id);

    commit('stations/set', {
      path: 'filteredGameIds',
      value: ids,
    });
  } else {
    gameSearchDebounced({ commit }, { stationId, search, filter });
  }
};
