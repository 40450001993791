export const locationBookings = state => locationId => {
  return state.filter(booking => booking.location.id === locationId);
};

export const locationExperiences = state => locationId => {
  return state.filter(experience => experience.location.id === locationId);
};

export const experienceIndexById = state => experienceId => {
  return state.findIndex(experience => experience.id === experienceId);
};

export const experienceById = state => experienceId => {
  return state.find(experience => experience.id === experienceId);
};
