import { gql } from '../api';

export const createRegionModel = schema => {
  return new schema.Entity('Region', {}, {
    idAttribute: 'id',
  });
};

export default {
  strict: true,
  actions: {
    getRegions: async ({ commit, dispatch }) => {
      commit('setLoading', true);
      commit('region/set', { path: 'isFetchingRegions', value: true });

      try {
        const query = `{
          regions {
            isoRegionCode
            countryCode
            name
          }
        }`;

        const { regions } = await gql.request(query);

        // When using isoRegionCode as idAttribute, regions of other countries with
        // the same code will be dropped, so we give them a fake ID.
        let i = 1;
        regions.forEach(x => { x.id = i++; });

        dispatch('insertEntities', {
          name: 'Region',
          payload: regions,
        });
      } finally {
        commit('setLoading', false);
        commit('region/set', { path: 'isFetchingRegions', value: false });
      }
    },
  },
  state: {
    isFetchingRegions: false,
  },
  mutations: {
    'region/set': (state, { path, value }) => {
      state[path] = value;
    },
  },
};
