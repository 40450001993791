import { stringify } from '../helpers/url-helper';
import { crossEnv } from '../setup/configureEnvironment';
function getEnvironmentQueryString (additionalParams = {}) {
  const params = {
    ...additionalParams,
    env: crossEnv.ENV,
    review: crossEnv.REVIEW_PREFIX,
  };
  if (!params.review) delete params.review;
  return stringify(params);
}
export default [
  {
    name: 'login',
    path: '/login',
    beforeEnter (to, from, next) {
      window.location.href = `https://${window.sbvrenv.ACCOUNT_URL}${getEnvironmentQueryString({ redirect: window.location.href })}`;
    },
  },
  {
    name: 'logout',
    path: '/logout',
    beforeEnter (to, from, next) {
      window.location.href = `https://${window.sbvrenv.ACCOUNT_URL}/logout${getEnvironmentQueryString({ redirect: window.location.href })}`;
    },
  },
  {
    name: 'current-tos',
    path: '/accept-tos',
    component: () => import('./CurrentTermsOfServiceRoute'),
  },
  {
    name: 'upcoming-tos',
    path: '/accept-upcoming-tos',
    component: () => import('./UpcomingTermsOfServiceRoute'),
  },
  {
    name: 'user-settings',
    path: '/user-settings',
    component: () => import('./UserSettingsRoute'),
  },
];
