import * as actions from './actions';

export default {
  strict: true,
  state: {
    isFetchingStations: false,
    isFetchingGames: false,
    isFetchingStationDetail: false,
    filteredGameIds: null,
  },
  actions,
  mutations: {
    'stations/set' (state, { path, value }) {
      state[path] = value;
    },
    'resetSearch' (state) {
      state.filteredGameIds = null;
    },
  },
};
