import moment from 'moment';
import { get } from 'lodash-es';
import { i18n } from '@/core/setup/i18n';
import DateHelper from '@/core/shared/helpers/DateHelper';

export function formatAgeRating (ageRating) {
  return ageRating === 0 ? 'None' : `${ageRating}+`;
}

export function hasPriceRecentlyChanged (price) {
  if (!price || !price.startsAt) return;
  const twoWeeksDuration = moment.duration(2, 'weeks');
  return (
    DateHelper.getClientNowMoment().diff(moment(price.startsAt)) <=
    twoWeeksDuration.asMilliseconds()
  );
}
export function isPriceGettingRemoved ({ current, upcoming } = {}) {
  if (!current) return false;
  return !!current.endsAt && !upcoming;
}

export function getFormattedDateMinusOneDay (date) {
  if (!date) return;
  return moment(date)
    .subtract(1, 'days')
    .format('LL');
}

export function formatDisplayDate (date, { nonBreakingSpace = false, utc = false, minFormat = false } = {}) {
  if (!date) return;
  const displayFormat = minFormat ? 'll' : 'LL';
  const momentObj = moment(date);
  if (utc) {
    momentObj.utc();
  }
  let result = momentObj.format(displayFormat);
  if (nonBreakingSpace) result = result.replace(/\s/g, '&nbsp;');
  return result;
}

export function formatLicenses (ownership) {
  if (!ownership) return;
  return [
    {
      type: 'minute',
      name: i18n.t('Per Minute License'),
      count: 1,
      available: !!get(ownership, 'minutePrice.current'),
      priceChange: get(ownership, 'priceChanges.minute'),
      hasRecentlyChanged: hasPriceRecentlyChanged(
        get(ownership, 'minutePrice.current'),
      ),
      price: get(ownership, 'minutePrice.current.price'),
      displayPrice: get(ownership, 'minutePrice.current.displayPrice'),
      usage: get(ownership, 'operatorLicense.currentMinuteUsage.value'),
      displayUsage: get(
        ownership,
        'operatorLicense.currentMinuteUsage.displayValue',
      ),
      percentageOff: ownership.percentageOff,
      priceBeforeDiscount: get(
        ownership,
        'minutePrice.upcoming.displayPrice',
      ),
      discountDisplayEndDate: get(ownership, 'minutePrice.current.endsAt', null) && moment(get(ownership, 'minutePrice.current.endsAt', null)).format('LL'),
    },
    {
      type: 'monthly',
      name: i18n.t('Monthly License'),
      available: !!get(ownership, 'monthlyPrice.current'),
      priceChange: get(ownership, 'priceChanges.monthly'),
      hasRecentlyChanged: hasPriceRecentlyChanged(
        get(ownership, 'monthlyPrice.current'),
      ),
      price: get(ownership, 'monthlyPrice.current.price'),
      displayPrice: get(ownership, 'monthlyPrice.current.displayPrice'),
      count:
        get(ownership, 'operatorLicense.monthlyLicenses.length') ||
        get(ownership, 'operatorLicense.monthlyLicensesCount'),
      notRenewingCount:
        ownership.operatorLicense &&
        ownership.operatorLicense.monthlyLicenses &&
        ownership.operatorLicense.monthlyLicenses.filter(
          ({ renewable }) => !renewable,
        ).length,
      usage: get(ownership, 'operatorLicense.currentMonthlyUsage.value'),
      displayUsage: get(
        ownership,
        'operatorLicense.currentMonthlyUsage.displayValue',
      ),
    },
    {
      type: 'yearly',
      name: i18n.t('Yearly License'),
      available: !!get(ownership, 'yearlyPrice.current'),
      priceChange: get(ownership, 'priceChanges.yearly'),
      hasRecentlyChanged: hasPriceRecentlyChanged(
        get(ownership, 'yearlyPrice.current'),
      ),
      price: get(ownership, 'yearlyPrice.current.price'),
      displayPrice: get(ownership, 'yearlyPrice.current.displayPrice'),
      count:
        get(ownership, 'operatorLicense.yearlyLicenses.length') ||
        get(ownership, 'operatorLicense.yearlyLicensesCount'),
      notRenewingCount:
        ownership.operatorLicense &&
        ownership.operatorLicense.yearlyLicenses &&
        ownership.operatorLicense.yearlyLicenses.filter(
          ({ renewable }) => !renewable,
        ).length,
      usage: get(ownership, 'operatorLicense.currentYearlyUsage.value'),
      displayUsage: get(
        ownership,
        'operatorLicense.currentYearlyUsage.displayValue',
      ),
    },
  ];
}
