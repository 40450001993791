import { gql } from '@/core/shared/api';

export const createLocaleModel = schema => {
  return new schema.Entity('Locale', {}, {
    idAttribute: 'key',
  });
};

export default {
  strict: true,
  actions: {
    getLocales: async ({ commit, dispatch, getters }) => {
      if (getters['entities/Locale/all']() === 'undefined' || getters['entities/Locale/all']().length === 0) {
        commit('setLoading', true);
        commit('locale/set', { path: 'isFetchingLocales', value: true });

        try {
          const query = `{
            locales {
              key
              text
            }
          }`;

          const { locales } = await gql.request(query);

          dispatch('insertEntities', {
            name: 'Locale',
            payload: locales,
          });
        } finally {
          commit('setLoading', false);
          commit('locale/set', { path: 'isFetchingLocales', value: false });
        }
      }
    },
  },
  state: {
    isFetchingLocales: false,
  },
  mutations: {
    'locale/set': (state, { path, value }) => {
      state[path] = value;
    },
  },
};
