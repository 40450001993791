export const token = state => {
  return state.token;
};

export const isTenant = state => {
  return !!state.tenantId;
};

export const currentUser = (state, getters, rootState, rootGetters) => {
  if (rootGetters['entities/User']) return rootGetters['entities/User'](state.currentUserId);
};
