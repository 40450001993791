import { gql } from '../../api';
import EventBus from '../../services/EventBus';
import { user } from './fragments';
import { parseQueryString, stringify } from '../../helpers/url-helper';
import { setupAuthIframe } from '../setup';

async function initializeTenantAndTokenState () {
  let token;
  let tenantId;
  let netToken;
  const params = parseQueryString();
  if (params.tenant_id) {
    tenantId = params.tenant_id;
    delete params.tenant_id;
    if (!window.sbvrenv.CYPRESS) { EventBus.$emit('router:replace', location.pathname + stringify(params)); }
  }
  if (params && params.token) {
    token = params.token;
    delete params.token;
    if (!window.sbvrenv.CYPRESS) { EventBus.$emit('router:replace', location.pathname + stringify(params)); }
  }
  if (params && params.netToken) {
    netToken = params.neToken;
    delete params.netToken;
    if (!window.sbvrenv.CYPRESS) { EventBus.$emit('router:replace', location.pathname + stringify(params)); }
  }
  return setupAuthIframe({ token, tenantId, netToken });
};

export const getUserFromToken = async ({ state, commit, dispatch }) => {
  if (!navigator.onLine) return; // early escape when fully offline to prevent redirect
  let token;
  let tenantId;
  let netToken;
  try {
    const auth = await initializeTenantAndTokenState();
    token = auth.token;
    tenantId = auth.tenantId;
    netToken = auth.netToken;
  } catch {
    EventBus.$emit('router:push', { name: 'login' });
  }

  if (token) {
    commit('setLoading', true);
    commit('setToken', token);
    commit('setTenantId', tenantId);
    commit('setNetToken', netToken);
    const query = `{
      me {
        ${user}
      }
    }`;
    try {
      const { me } = await gql.request(query);
      dispatch('handleLogin', { user: me });
      return me;
    } catch (e) {
      EventBus.$emit('router:push', { name: 'login' });
    } finally {
      commit('setLoading', false);
    }
  } else {
    EventBus.$emit('router:push', { name: 'login' });
  }
};

export const handleLogin = (
  { commit, dispatch, rootState },
  { user },
) => {
  dispatch('insertEntities', {
    name: 'User',
    payload: user,
  });

  commit('setCurrentUserId', user.id);

  if (!user.agreedAt) {
    EventBus.$emit('router:push', { name: 'current-tos' });
  } else if (!user.upcomingAgreedAt && user.upcomingAgreedAtRequiredBy) {
    EventBus.$emit('router:push', { name: 'upcoming-tos' });
  } else {
    if (rootState.route.name === 'current-tos' || rootState.route.name === 'upcoming-tos') {
      EventBus.$emit('router:push', { name: 'home' });
    }

    dispatch('initUser', user);
  }
};

export const logout = ({ commit, dispatch, getters }) => {
  EventBus.$emit('router:push', { name: 'logout' });
};

export const saveUser = async ({ commit, dispatch }, user) => {
  commit('setLoading', true);

  dispatch('insertEntities', {
    name: 'User',
    payload: user,
  });

  const query = `
    mutation saveUser ($name: String, $email: String) {
      updateCurrentUser (name: $name, email: $email) {
        id
      }
    }
  `;

  try {
    const { updateCurrentUser } = await gql.request(query, user);
    return updateCurrentUser;
  } finally {
    commit('setLoading', false);
  }
};

export const changePassword = async ({ commit }, passwordChange) => {
  commit('setLoading', true);
  const query = `
    mutation saveUser (
      $currentPassword: String
      $password: String
      $passwordConfirmation: String
    ) {
      updateCurrentUser (
        currentPassword: $currentPassword
        password: $password
        passwordConfirmation: $passwordConfirmation
      ) {
        id
      }
    }
  `;
  try {
    return gql.request(query, passwordChange);
  } finally {
    commit('setLoading', false);
  }
};

export const getCurrentTermsOfService = async () => {
  const query = `{
    me {
      organization {
        tenant {
          currentTosModal
        }
      }
    }
  }`;

  return gql.request(query);
};

export const getUpcomingTermsOfService = async () => {
  const query = `{
    me {
      organization {
        tenant {
          upcomingTosModal
        }
      }
    }
  }`;

  return gql.request(query);
};

export const acceptCurrentTOS = async ({ dispatch }) => {
  const mutation = `mutation {
    acceptTermsOfService {
      id
    }
  }`;
  try {
    const { acceptTermsOfService } = await gql.request(mutation);
    return acceptTermsOfService;
  } finally {
    dispatch('getUserFromToken');
  }
};

export const acceptUpcomingTOS = async ({ dispatch }) => {
  const mutation = `mutation {
    acceptUpcomingTermsOfService {
      id
    }
  }`;
  try {
    const { acceptUpcomingTermsOfService } = await gql.request(mutation);
    return acceptUpcomingTermsOfService;
  } finally {
    dispatch('getUserFromToken');
  }
};
