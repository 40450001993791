import Vue from 'vue';
import * as LDClient from 'launchdarkly-js-client-sdk';

const features = {};

function VueLaunchDarkly () {
  Vue.util.defineReactive(Vue.prototype, '$features', features);
}

if (process.env.NODE_ENV !== 'test' && Vue.use) {
  Vue.use(VueLaunchDarkly);
}

export function initLaunchDarkly (user, organization) {
  const ldUser = {
    key: user.id,
    email: user.email,
    name: user.name,
    custom: {
      organization: organization.name,
      organizationId: organization.id,
      organizationType: organization.type,
    },
  };

  const ldClient = LDClient.initialize(window.sbvrenv.LD_CLIENT_ID, ldUser);
  Vue.prototype.$featureClient = ldClient;

  ldClient.on('ready', () => {
    const flags = ldClient.allFlags();
    Object.keys(flags).forEach(flagKey => {
      Vue.set(features, flagKey, flags[flagKey]);
    });
  });

  ldClient.on('change', settings => {
    const newFeatures = Object.keys(settings).reduce((newFeatures, feature) => {
      return { ...newFeatures, [feature]: settings[feature].current };
    }, {});

    Object.keys(newFeatures).forEach(flagKey => {
      Vue.set(features, flagKey, newFeatures[flagKey]);
    });
    process.env.NODE_ENV !== 'production' &&
      // eslint-disable-next-line no-console
      console.log(
        '%c New LaunchDarkly feature configuration:',
        'background: #07BEA5; color: #FFFFFF;',
      );
  });
}

export default VueLaunchDarkly;

export function userFeatures () {
  return features;
}
