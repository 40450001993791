import ExtendableError, { CancelRequestError } from '../misc/errorTypes';

export { CancelRequestError, ExtendableError };

export class FetchError extends ExtendableError {
  name = 'FetchError'
}

export const handleQueryError = (msg = 'fetch error') => {
  const newErr = new FetchError(msg); // placed here to get correct stack
  return e => {
    if (e instanceof CancelRequestError) return;
    newErr.originalError = e;
    throw newErr;
  };
};

export const catchQueryErrors = (e, cb) => {
  if (e instanceof FetchError) {
    if (cb && typeof cb === 'function') return cb();
  } else {
    throw e;
  }
};

export const forwardError = (e) => {
  throw e;
};
