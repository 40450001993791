export const swUpdateAvailable = (state, getters) => {
  return state.swUpdateAvailable;
};
export const projectName = (state, getters) => {
  return state.projectName;
};
export const confirm = state => {
  return state.confirm;
};
export const flash = state => {
  return state.flash && state.flash[0];
};
export const loading = state => {
  return state.loading;
};
export const navMenu = state => {
  return state.navMenu;
};
export const showLoadingOverlay = state => {
  return state.loadingOverlay;
};
export const title = state => {
  return state.title;
};
export const showHeader = state => {
  return state.showHeader;
};
