/* eslint-disable camelcase */
import { extend } from 'vee-validate';
import { alpha, alpha_num, alpha_dash, alpha_spaces, min, max, min_value, max_value, numeric, regex, image, mimes, ext, size, double } from 'vee-validate/dist/rules';
import moment from 'moment';

extend('required', {
  validate (value) {
    return {
      required: true,
      valid: ['', null, undefined, false].indexOf(value) === -1,
    };
  },
  computesRequired: true,
  message: 'This field is required.',
});

extend('email', {
  validate (value) {
    // Field is empty, should pass
    if (!value || !value.length) {
      return true;
    }

    // Check if email with RFC 5322 compliant regex pattern
    // eslint-disable-next-line no-control-regex
    if (!/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(value)) {
      return false;
    }
    return true;
  },
  message: 'This field must be a valid email.',
});

extend('alpha', {
  ...alpha,
  message: '{_field_} may only contain alphabetic characters',
});

extend('alphaNumeric', {
  ...alpha_num,
  message: '{_field_} may only contain alphabetic characters or numbers',
});

extend('alphaSpaces', {
  ...alpha_spaces,
  message: '{_field_} may only contain alphabetic characters and spaces',
});

extend('alphaNumericRich', {
  ...alpha_dash,
  message: '{_field_} may only contain alphabetic characters, numbers, dashes or underscores',
});

extend('numeric', {
  validate (value) {
    return {
      // required: true,
      valid: numeric.validate(value),
    };
  },
  // computesRequired: true,
  message: '{_field_} may only contain whole numeric characters (no decimals)',
});

extend('min', {
  ...min,
  message: '{_field_} must have at least {length} characters',
});

extend('max', {
  ...max,
  message: '{_field_} can have at most {length} characters',
});

extend('minValue', {
  ...min_value,
  message: '{_field_} must be bigger than or equal to {min}',
});

extend('maxValue', {
  ...max_value,
  message: '{_field_} must be smaller than or equal to {max}',
});

extend('regex', {
  ...regex,
  message: '{_field_} is not valid',
});

extend('requireNumber', {
  validate (value) {
    return (/\d/.test(value));
  },
  message: '{_field_} must contain a number',
});

extend('requireSpecial', {
  validate (value) {
    return (/[!@#$%^&*]/.test(value));
  },
  message: '{_field_} must contain a special character',
});

extend('between', {
  validate (value, { min, max }) {
    return (+min <= +value && +value <= +max);
  },
  params: ['min', 'max'],
  message: 'The value must be a number between {min} and {max}',
});

extend('dropdown', {
  validate: (value) => {
    if (value == null) return false;
    if (typeof value === 'number') return true;
    if (value === '__placeholder') return false;
    if (Object.keys(value).length > 0) {
      return true;
    }
    return false;
  },
  computesRequired: true,
  message: 'Please select an option',
});

extend('confirmed', {
  params: ['target'],
  validate (value, { target }) {
    if (value == null) return false;
    return value === target;
  },
  message: 'The {_field_} does not match the {target} field',
});

extend('length', {
  validate (value, { length }) {
    if (value == null) return false;
    if (value.length >= length) return true;
    return false;
  },
  params: ['length'],
  message: 'You must select at least {length} items',
});

extend('is', {
  validate (value, { expected }) {
    if (value === expected) return true;
    return false;
  },
  params: ['expected'],
  message: 'You must choose {expected}.',
});

extend('date', {
  validate (value) {
    if (isNaN(Date.parse(value))) return false;
    return true;
  },
  message: 'Invalid date',
});

extend('minDate', {
  params: ['minDate'],
  validate (value, { minDate }) {
    const inputDate = Date.parse(value);
    minDate = Date.parse(minDate);
    if (isNaN(minDate)) return false; // Error handling? This is a programmer error not a user error
    if (isNaN(inputDate)) return false;
    return true;
  },
  message: 'Date must be greater than {minDate}',
});

extend('maxDate', {
  params: ['maxDate'],
  validate (value, { maxDate }) {
    const inputDate = Date.parse(value);
    maxDate = Date.parse(maxDate);
    if (isNaN(maxDate)) return false; // Error handling? This is a programmer error not a user error
    if (isNaN(inputDate)) return false;
    if (inputDate >= maxDate) return false;
    return true;
  },
  message: 'Date must be smaller than {maxDate}',
});

extend('time', {
  validate (value) {
    // Validate against momentjs Long Time format (HH:MM AM) and ISO 8601 (both flatpickr time outputs)
    return moment(value, 'LT', true).isValid() || moment(value, moment.ISO_8601, true).isValid();
  },
  message: 'Invalid time',
});

extend('minTime', {
  validate (value, { minTime }) {
    // Time picker has a quirk where it converts into ISO 8601 when clicked and only into time after input.
    // This case is caught here.
    if (moment(value, moment.ISO_8601, true).isValid()) {
      minTime = moment(minTime, 'HH:mm A');
      return moment(value, moment.ISO_8601).isAfter(minTime);
    }

    return moment(value, 'LT', true).isAfter(moment(minTime, 'LT', true));
  },
  params: ['minTime'],
  message: 'Time must be bigger than {minTime}',
});

extend('maxTime', {
  validate (value, { maxTime }) {
    // Time picker has a quirk where it converts into ISO 8601 when clicked and only into time after input.
    // This case is caught here.
    if (moment(value, moment.ISO_8601, true).isValid()) {
      maxTime = moment(maxTime, 'HH:mm A');
      return moment(value, moment.ISO_8601).isBefore(maxTime);
    }

    return moment(value, 'LT', true).isBefore(moment(maxTime, 'LT', true));
  },
  params: ['maxTime'],
  message: 'Time must be smaller than {maxTime}',
});

extend('image', {
  validate (value) {
    return {
      required: true,
      valid: image.validate(value),
    };
  },
  computedRequired: true,
  message: 'Upload must be an image',
});

extend('mimes', {
  ...mimes,
  message: 'Upload does not match the expected file type',
});

extend('ext', {
  ...ext,
  params: ['extension'],
  message: 'File must be of type .{extension}',
});

extend('alphaNumericSpaces', {
  validate (value) {
    if (!value || !value.length) {
      return true;
    }

    if (!/^[A-Z0-9 ]+$/i.test(value)) {
      return false;
    }

    return true;
  },
  message: '{_field_} may only contain alphabetic characters, numbers and spaces',
});

extend('alphaSpacesNumericRich', {
  validate (value) {
    if (!value || !value.length) {
      return true;
    }

    // TODO(Jack): This should be using the locale from i18n
    if (!/^[A-Z0-9_\-.\s'",]*$/i.test(value)) {
      return false;
    }

    return true;
  },
  // TODO(Jack): This should be using the locale from i18n
  message: '{_field_} may only contain alphabetic characters, numbers, underscores, hyphens, periods, spaces, single or double quotes, and commas',
});

extend('phone', {
  validate (value) {
    if (!value || !value.length) {
      return true;
    }

    if (!/^[0-9()\-+\s]*$/.test(value)) {
      return false;
    }

    return true;
  },
  // TODO(Jack): This should be using the locale from i18n
  message: '{_field_} may only contain numbers, parantheses, hyphens, and spaces',
});

extend('sizeKB', {
  ...size,
  message: 'File size is too large! Cannot surpass {size}KB',
});

extend('sizeMB', {
  validate (files, params) {
    const desiredFileSize = params.size * 1024;
    return size.validate(files, { size: desiredFileSize });
  },
  params: [
    { name: 'size', cast (value) { return Number(value); } },
  ],
  message: 'File size is too large! Cannot surpass {size}MB',
});

extend('decimal', {
  validate (value) {
    if (/^[0-9]+(\.?)$/.test(value)) {
      // Erik: add decimals if it doesn't contain them, or it won't validate
      value = `${value.toString().replace('.', '')}.0`;
    }
    return {
      // required: true,
      valid: double.validate(value),
    };
  },
  params: double.params,
  message (field, { decimals }) {
    const additionalInfo = decimals && decimals !== 0 ? ` (max ${decimals} decimal points)` : '';
    const message = (field !== '{field}') ? `The ${field} field must have a valid decimal value` : 'The field must have a decimal value';
    return message + additionalInfo + '!';
  },
});
