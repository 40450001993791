import { gql } from '@/core/shared/api';
import { i18n } from '@/core/setup/i18n';
import { ExtendableError, FetchError } from '@/core/shared/helpers/ErrorHelper';

const ccFragment = `
id
last4
expiryMonth
expiryYear
nameOnCard
brand
`;
const subscriptionFragment = `
  name
  quantity
  trialEndsAt
  endsAt
  couponCode
  couponAmount {
    displayValue
  }
  couponPercent
  couponEnds
  nextCycle
  billingPlans {
    id
    name
    type
    pivot {
      quantity
    }
    currentPricePerStation {
      displayValue
      value
    }
    totalAmount {
      displayValue
      value
    }
  }
  plan {
    name
    tiersMode
    description
    amount {
      displayValue
    }
    tiers {
      min
      max
      amount {
        displayValue
      }
    }
  }
`;
const orgFragment = `
id
name
isActive
isAccessSuspended
stripePaymentMethod
isOnTrial
trialExpiry
paymentErrorDetail {
  message
  failedCount
  suspended
}
creditCardExpired
latestStatsDate
defaultCreditCard {
  ${ccFragment}
}
currentMonthCost {
  displayValue
}
currentLicensedGamesCount
currentSubscription {
  ${subscriptionFragment}
}
upcomingFees {
  subscriptionFee {
    value
    displayValue
  }
  clFee {
    value
    displayValue
  }
  subscriptionDiscountsTotal {
    value
    displayValue
  }
  subscriptionDiscountsRate
  outstandingFee {
    value
    displayValue
  }
  total {
    value
    displayValue
  }
}
invoices {
  id
  paidAt
  downloadLink
  displayInvoiceDiscountedTotal
  type
  invoiceDate
  invoiceNumber
}
accountCredit {
  displayValue
}
outstandingBalance {
  displayValue
}
currentStationCount
currentStationUnitRate {
  displayValue
}
`;

export async function getBillingInfo ({ dispatch, commit }) {
  const query = `{
    currentOrganization {
      ${orgFragment}
    }
    subscriptionPlans {
      id
      name
      amount {
        value
        displayValue
      }
      interval
      tiers {
        min
        max
        amount {
          displayValue
        }
      }
    }
  }`;
  const { currentOrganization, subscriptionPlans } = await gql.request(query);
  dispatch('insertEntities', {
    name: 'Organization',
    payload: currentOrganization,
  });
  commit('setBillingPlan', subscriptionPlans[0]);
  commit('setBillingPlans', subscriptionPlans);
}

export const getProratedAmount = async ({ commit }) => {
  const query = `
    {
      proratedAmount: getProratedAmount {
        displayValue
      }
    }
  `;
  const { proratedAmount } = await gql.request(query);
  commit('setProratedAmount', proratedAmount.displayValue);
  return proratedAmount;
};

export async function getBillingAccountInfo ({ dispatch, commit }) {
  commit('startBillingLoading', 'account');
  try {
    await dispatch('getBillingInfo');
  } finally {
    commit('endBillingLoading', 'account');
  }
}
export async function getBillingInvoices ({ dispatch, commit }) {
  commit('startBillingLoading', 'invoices');
  try {
    await dispatch('getBillingInfo');
  } finally {
    commit('endBillingLoading', 'invoices');
  }
}

export async function disableAccount (
  { dispatch, getters, commit },
  { deletion, reason, comments },
) {
  const mutationName = `${deletion ? 'delete' : 'disable'}Account`;
  const query = `mutation($id: ID!, $reason: String, $comments: String) {
    currentOrganization: ${mutationName}(id: $id, reason: $reason, comments: $comments) {
      id
      isActive
    }
  }`;

  const successMsg = deletion
    ? i18n.t(
      'Your account was successfully disabled. It will be deleted within 30 days.',
    )
    : i18n.t('Your account was successfully disabled.');
  const errorMsg = i18n.t(
    'There was an error disabling your account, please try again.',
  );
  try {
    const { currentOrganization } = await gql.request(query, {
      id: getters.currentOrganization.id,
      reason,
      comments,
    });
    dispatch('insertEntities', {
      name: 'Organization',
      payload: currentOrganization,
    });

    commit('setFlash', {
      message: successMsg,
      type: 'success',
    });
  } catch (e) {
    commit('setFlash', {
      message: errorMsg,
      type: 'error',
    });
    throw e;
  }
}
class StripeError extends ExtendableError {
  name = 'StripeError';
}
export function notifyCCError ({ commit }) {
  const errorMsg = i18n.t(
    'There was an error with your credit card, please try again.',
  );
  commit('setFlash', {
    message: errorMsg,
    type: 'error',
  });
}
export async function updateCreditCard (
  { dispatch, commit, getters },
  { creditCardToken },
) {
  commit('startBillingLoading', 'updateCC');
  const successMsg = i18n.t('Your credit card was successfully updated.');
  try {
    const query = `mutation($token: String!) {
      creditCard: updateCreditCard(token: $token) {
        ${ccFragment}
      }
    }`;
    const { creditCard } = await gql.request(
      query,
      { token: creditCardToken },
      { defaultErrorHandling: false },
    );
    // inject locally credit card
    dispatch('insertEntities', {
      name: 'Organization',
      payload: {
        id: getters.currentOrganization.id,
        defaultCreditCard: creditCard,
      },
    });
    await dispatch('getBillingInfo'); // refetch the whole billing info to update organization level data
    commit('setFlash', {
      message: successMsg,
      type: 'success',
    });
  } catch (e) {
    if (e instanceof FetchError || e instanceof StripeError) {
      dispatch('notifyCCError');
    } else {
      throw e;
    }
  } finally {
    commit('endBillingLoading', 'updateCC');
  }
}

export async function createSetupIntent ({ commit }) {
  let result = {};
  const query = `mutation {
    setupIntent: createSetupIntent {
      json
    }
  }`;
  const { setupIntent } = await gql.request(query);
  result = JSON.parse(setupIntent.json);
  return result;
};

export async function processSetupIntent ({ commit }, { setupIntent }) {
  commit('setSetupIntent', setupIntent);
  const query = `mutation($setupIntentJson: String!) {
    organization: processSetupIntent(setupIntentJson: $setupIntentJson) {
      id
      stripePaymentMethod
    }
  }`;
  const { organization } = await gql.request(query, {
    setupIntentJson: JSON.stringify(setupIntent),
  });
  return organization.stripePaymentMethod;
}

export async function startSubscription (
  { dispatch, commit },
  { planIds, creditCardToken },
) {
  commit('startBillingLoading', 'subscription');
  try {
    const query = `mutation($planIds: [Int], $token: String) {
      subscription: startSubscription(plans: $planIds, token: $token) {
        id
        organization {
          ${orgFragment}
        }
      }
    }`;
    const { subscription } = await gql.request(query, {
      planIds: planIds.map((id) => Number(id)),
      token: creditCardToken,
    });
    dispatch('insertEntities', {
      name: 'Organization',
      payload: subscription.organization,
    });
    // commit('setFlash', {
    //   message: successMsg,
    //   type: 'success'
    // })
  } catch (e) {
    if (e instanceof StripeError) {
      dispatch('notifyCCError');
    } else {
      throw e;
    }
  } finally {
    commit('endBillingLoading', 'subscription');
  }
}

export const validateVatNumber = async ({ commit }, { organizationId, vatNumber }) => {
  commit('setLoading', true);

  const query = `{
    validateVatNumber(organizationId: "${organizationId}", vatNumber: "${vatNumber}")
  }`;

  try {
    return await gql.request(query);
  } finally {
    commit('setLoading', false);
  }
};

export async function getNetArcadeInvoices ({ dispatch, commit, getters }) {
  commit('startBillingLoading', 'invoices');

  try {
    dispatch('getNetInvoices');
  } finally {
    commit('endBillingLoading', 'invoices');
  }
}
