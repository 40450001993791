export const user = `
id
email
name
agreedAt
upcomingAgreedAt
upcomingAgreedAtRequiredBy
# reservationEmails
# systemEmails
accessLevel
pusherConfiguration {
  authEndpoint
  host
  key
  broadcaster
}
`;
