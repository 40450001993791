import ky from 'ky';
let internalStore;

export function setStore (store) {
  internalStore = store;
}

export default ky.create({
  prefixUrl: `https://${window.sbvrenv.API_URL}/v1/`,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
  },
  hooks: {
    afterResponse: [
      async response => {
        if (response.ok) {
          return response;
        } else {
          const error = await response.json();
          // eslint-disable-next-line no-console
          console.error(error.error);
          if (internalStore) {
            internalStore.commit('setFlash', {
              type: 'error',
              message: error.error,
            });
          }
        }
      },
    ],
  },
});
