<template>
  <div class="loading">
    <div class="three-bounce" :class="{'-tiny': tiny}">
      <div class="child bounce1"></div>
      <div class="child bounce2"></div>
      <div class="child bounce3"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    tiny: Boolean,
  },
};
</script>

<style scoped lang="postcss">
@import '../styles';

.three-bounce {
  margin: var(--spacingMd) 0;

  & .child {
    background-color: var(--colorSBRedFlat);
    width: 20px;
    height: 20px;
    border-radius: 100%;
    display: inline-block;
    animation: three-bounce 1.4s ease-in-out 0s infinite both;

    &.bounce1 {
      animation-delay: -0.32s;
    }

    &.bounce2 {
      animation-delay: -0.16s;
    }
  }
  &.-tiny {
    margin: 0;
    & .child {
      width: 10px;
      height: 10px;
    }
  }
}

@keyframes three-bounce {
  0%, 80%, 100% { transform: scale(0); }
  40% { transform: scale(1); }
}
</style>
