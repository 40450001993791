<template>
  <transition @after-leave="didLeave" :appear="true">
    <div v-if="display === 'modal'" class="modal-mask" v-show="show">
      <div class="modal-wrapper"  @click="closeFlash">
        <div class="modal-flash-container" @click.stop="stopBubble">
          <div class="flash" :class="type">
            <div class="icon">
              <svg>
                <use v-bind:xlink:href="icon"></use>
              </svg>
            </div>
            <div class="message">{{ $t(caption) }}</div>
            <div class="x" @click="closeFlash">
              <svg>
                <use xlink:href="#icon-x"></use>
              </svg>
            </div>
          </div>
          <div class="flash" :class="type">
            <div class="message-container" v-html="message"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="flash-container" v-show="show">
      <div class="flash" :class="type">
        <div class="icon">
          <svg>
            <use v-bind:xlink:href="icon"></use>
          </svg>
        </div>
        <div class="message" v-html="message"></div>
        <div class="x" @click="closeFlash">
          <svg>
            <use xlink:href="#icon-x"></use>
          </svg>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      validator: value => {
        return value === 'success' || value === 'error' || value === 'info';
      },
    },
    display: {
      type: String,
      validator: value => {
        return value === 'modal' || value === 'flash';
      },
    },
    message: String,
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    icon () {
      if (this.type === 'success') return '#icon-check';
      if (this.type === 'error') return '#icon-alert-triangle';
      if (this.type === 'info') return '#icon-alert-circle';
      return '#icon-check';
    },
    caption () {
      if (this.type === 'success') return 'Completed successfully';
      if (this.type === 'error') return 'An error occurred';
      return 'Information';
    },
  },
  methods: {
    stopBubble () {
    },
    closeFlash () {
      this.$store.commit('setFlashShow', false);
    },
    didLeave () {
      this.$store.commit('removeFlash');
    },
  },
  created () {
    if (this.display !== 'modal') {
      let timeout = this.message.length * 50 > 6000 ? this.message.length * 50 : 6000;
      if (timeout > 15000) timeout = 15000;
      this.__timeout = setTimeout(() => this.$store.commit('setFlashShow', false), timeout);
    }
  },
  destroyed () {
    if (this.display !== 'modal') {
      clearTimeout(this.__timeout);
    }
  },
};
</script>

<style scoped lang="postcss">
@import "../styles";

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-flash-container {
  width: 50%;
  margin: 0px auto;
  text-align: center;
  z-index: var(--zFlash);
  will-change: transform;
  transition: transform 0.3s ease, opacity 0.3s ease;
  transform: translateY(0) translateZ(0);
  opacity: 1;
  background-color: transparent;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  @media (--phone) {
    width: 95%;
  }

  @media (--tablet) {
    width: 75%;
  }

  @media (--desktop) {
    width: 60%;
    padding-left: var(--spacingMd);
    transform: translateY(-3rem) translateZ(0);
  }

  @media (--desktopXL) {
    width: 50%;
    padding-left: var(--spacingMd);
    transform: translateY(-3rem) translateZ(0);
  }

  &.v-enter,
  &.v-leave-to {
    transform: translateY(100%) translateZ(0);
    opacity: 0;
  }

  & .flash {
    all: initial;
    @apply --f5;
    @apply --panelDropShadow;

    position: relative;
    display: flex;
    align-items: center;
    margin: 0;
    width: 100%;
    background-color: var(--colorSlate);

    & .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 5rem;
      height: 100%;
    }

    & .message {
      width: 100%;
      color: var(--colorCloud);
      text-align: left;
      padding: var(--spacingSm) var(--spacingMd);

      & >>> a {
        @apply --buttonLink;
      }
    }

    & .x {
      position: absolute;
      right: var(--spacingSm);
      top: 1.25rem;
      cursor: pointer;

      & svg {
        margin-right: 0;
      }
    }

    & svg {
      flex: 0 0 2rem;
      width: 2rem;
      height: 2rem;
      fill: var(--colorCloud);
    }

    &.success {
      & .icon {
        background: var(--colorSuccess);
      }

      & .x svg {
        fill: var(--colorSuccessFlat);
      }
    }

    &.error {
      & .icon {
        background: var(--colorError);
      }

      & .x svg {
        fill: var(--colorErrorFlat);
      }
    }

    &.info {
      & .icon {
        background: var(--colorInfo);
      }

      & .x svg {
        fill: var(--colorInfoFlat);
      }
    }
  }
  & >>> .link-style {
    font-size: inherit !important;
    font-weight: inherit !important;
    color: inherit !important;
    line-height: inherit !important;
  }

  & .message-container {
    @apply --f5;
    flex-basis: 100%;
    background-color: #fff;
    padding: var(--spacingSm) var(--spacingMd);
    text-align: left;
    width: 100%;
  }
}

.flash-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: var(--zFlash);
  will-change: transform;
  transition: transform 0.3s ease, opacity 0.3s ease;
  transform: translateY(0) translateZ(0);
  opacity: 1;
  background-color: transparent;
  display: flex;
  justify-content: flex-end;

  @media (--desktop) {
    max-width: 67.5rem;
    margin-left: 30rem;
    padding-left: var(--spacingMd);
    transform: translateY(-3rem) translateZ(0);
  }

  @media (--desktopXL) {
    max-width: 75rem;
    margin-left: 30rem;
    padding-left: var(--spacingMd);
    transform: translateY(-3rem) translateZ(0);
  }

  &.v-enter,
  &.v-leave-to {
    transform: translateY(100%) translateZ(0);
    opacity: 0;
  }

  & .flash {
    @apply --f6;
    @apply --panelDropShadow;

    position: relative;
    display: flex;
    align-items: center;
    margin: 0;
    width: 100%;
    background-color: var(--colorSlate);

    & .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 5rem;
      height: 100%;
    }

    & .message {
      width: 100%;
      color: var(--colorCloud);
      text-align: left;
      padding: var(--spacingSm) var(--spacingMd);

      & >>> a {
        @apply --buttonLink;
      }
    }

    & .x {
      position: absolute;
      right: var(--spacingSm);
      top: 1.25rem;
      cursor: pointer;

      & svg {
        margin-right: 0;
      }
    }

    & svg {
      flex: 0 0 2rem;
      width: 2rem;
      height: 2rem;
      fill: var(--colorCloud);
    }

    &.success {
      & .icon {
        background: var(--colorSuccess);
      }

      & .x svg {
        fill: var(--colorSuccessFlat);
      }
    }

    &.error {
      & .icon {
        background: var(--colorError);
      }

      & .x svg {
        fill: var(--colorErrorFlat);
      }
    }

    &.info {
      & .icon {
        background: var(--colorInfo);
      }

      & .x svg {
        fill: var(--colorInfoFlat);
      }
    }
  }
  & >>> .link-style {
    font-size: inherit !important;
    font-weight: inherit !important;
    color: inherit !important;
    line-height: inherit !important;
  }
}

</style>
