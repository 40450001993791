export class NetworkService {
  init ({ notify, onlineMessage, offlineMessage }) {
    if (notify) {
      this.online = navigator.onLine;
      this.notify = notify;
      this.onlineMessage = onlineMessage || 'Back online.';
      this.offlineMessage = offlineMessage || 'Network changed, you are now offline.';
      this.setUpListeners();
    } else {
      // eslint-disable-next-line
      console.warn('Network service requires a notify function to be passed in');
    }
  }

  setUpListeners () {
    window.addEventListener('online', () => {
      if (!this.online) {
        this.notify(this.onlineMessage);
        this.online = true;
      }
    });

    window.addEventListener('offline', () => {
      this.online = false;
      this.notify(this.offlineMessage);
    });
  }
}

export default new NetworkService();
