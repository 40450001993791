import { set } from 'lodash-es';

export default class GQLClient {
  client = null;
  beforeRequest = [];
  afterResponse = [];
  onClientError = [];

  constructor (url, ky, opts = {}) {
    this.client = ky.create({
      prefixUrl: `https://${url}`,
      timeout: 60000,
      retry: 0,
      method: 'post',
      mode: 'cors',
      ...opts,
    });
  }

  _createExtendedClientWithHooks (opts) {
    return this.client.extend({
      hooks: {
        beforeRequest: this.beforeRequest.map(hook => fetchOption =>
          hook(fetchOption, opts),
        ),
        afterResponse: this.afterResponse.map(hook => fetchOption =>
          hook(fetchOption, opts),
        ),
      },
    });
  }

  async _request (opts = {}, query, variables) {
    const extendedClient = this._createExtendedClientWithHooks(opts);
    try {
      const response = await extendedClient('graphql', {
        throwHttpErrors: false,
        ...opts,
      });
      const result = await getResult(response);
      if (response.ok && !result.errors && result.data) {
        return result.data;
      } else {
        const errorResult =
          typeof result === 'string' ? { error: result } : result;
        throw new ClientError(
          { ...errorResult, status: response.status },
          { query, variables },
        );
      }
    } catch (error) {
      this._errorHandler(error, opts);
    }
  }

  async request (query, variables, opts = {}) {
    return this._request(
      {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query,
          variables: variables || undefined,
        }),
        ...opts,
      },
      query,
      variables,
    );
  }

  async requestWithBlobs (query, variables, blobs, opts = {}) {
    // comply to the graphql mulitpart request spec. see https://github.com/jaydenseric/graphql-multipart-request-spec#multipart-form-field-structure
    // -F operations='{ "query": "mutation ($file: Upload!) { singleUpload(file: $file) { id } }", "variables": { "file": null } }' \
    // -F map='{ "0": ["variables.file"] }' \
    // -F 0=@a.txt
    const form = new FormData();
    const operations = {
      query,
      variables,
    };
    const filesMap = {};
    let iterableBlobs;
    if (!Array.isArray(blobs)) iterableBlobs = [blobs];
    else iterableBlobs = blobs;
    iterableBlobs.forEach(blob => {
      const file = typeof blob === 'object' ? blob.file : blob;
      const blobFieldName = typeof blob === 'object' ? blob.name : 'blob';
      filesMap[blobFieldName] = [`variables.${blobFieldName}`];
      operations.variables[blobFieldName] = null;
      form.append(blobFieldName, file);
    });
    form.append('operations', JSON.stringify(operations));
    form.append('map', JSON.stringify(filesMap));

    return this._request(
      {
        body: form,
        ...opts,
      },
      query,
      variables,
    );
  }

  async requestWithDefer (query, variables, opts, callback) {
    const extendedClient = this._createExtendedClientWithHooks(opts);
    try {
      const response = await extendedClient('graphql', {
        throwHttpErrors: false,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query,
          variables: variables || undefined,
        }),
        ...opts,
      });

      let textSoFar = '';
      let gqlData = {};
      const reader = response.body.getReader();

      const onFulfilled = ({ done, value }) => {
        if (done) {
          callback(gqlData, done);
          return;
        }

        textSoFar += new TextDecoder('utf-8').decode(value);
        const jsonTexts = [...getMatches(textSoFar)];

        jsonTexts.forEach(jsonText => {
          try {
            const json = JSON.parse(jsonText);

            if (json.path) {
              set(gqlData, json.path.join('.'), json.data);
            } else {
              gqlData = json.data;
            }
          } catch (e) { }
        });

        callback(gqlData, done);

        return reader.read().then(onFulfilled);
      };

      reader.read().then(onFulfilled).catch(e => {
        if (e.name !== 'AbortError') {
          throw e;
        }
      });
    } catch (error) {
      this._errorHandler(error, opts);
    }

    function * getMatches (string, regExp = /({.*})/g, captureGroup = 1) {
      while (true) {
        const match = regExp.exec(string);
        if (match === null) {
          break;
        }
        yield match[captureGroup];
      }
    }
  }

  _errorHandler (error, opts) {
    let exception;
    for (const clientErrorPlugin of this.onClientError) {
      try {
        clientErrorPlugin(exception || error, opts);
        exception = null;
      } catch (err) {
        exception = err;
      }
    }
    if (exception) throw exception;
  }

  use (plugin, pluginOpts) {
    const beforeRequest = {
      push: (...args) => this.beforeRequest.push(...args),
    };
    const afterResponse = {
      push: (...args) => this.afterResponse.push(...args),
    };
    const onClientError = {
      push: (...args) => this.onClientError.push(...args),
    };
    plugin(
      { beforeRequest, afterResponse, onClientError, GQLClient },
      pluginOpts,
    );
  }
}

export class ClientError extends Error {
  constructor (response, request) {
    const message = `${ClientError.extractMessage(response)}: ${JSON.stringify({
      response,
      request,
    })}`;

    super(message);

    this.response = response;
    this.request = request;

    // this is needed as Safari doesn't support .captureStackTrace
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, ClientError);
    }
  }

  static extractMessage (response) {
    try {
      return response.errors[0].message;
    } catch (e) {
      return `GraphQL Error (Code: ${response.status})`;
    }
  }
}
async function getResult (response) {
  const contentType = response.headers.get('Content-Type');
  if (contentType && contentType.startsWith('application/json')) {
    return response.json();
  } else {
    return response.text();
  }
}
