// import UpdatePrompt from './components/UpdatePrompt';
// import Vue from 'vue';
import EventBus from '../services/EventBus';

export default function ({ store, projectName }) {
  // This comes from https://developers.google.com/web/tools/workbox/guides/advanced-recipes
  // It allows activating the new service worker right away on reload
  if (window.sbvrenv.CYPRESS) return;
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', function () {
      navigator.serviceWorker
        .register('/sbvr-service-worker.js')
        .then(function (registration) {
          // Track updates to the Service Worker.
          if (!navigator.serviceWorker.controller) {
            // The window client isn't currently controlled so it's a new service
            // worker that will activate immediately
            return;
          }
          // When the user asks to refresh the UI, we'll need to reload the window
          var preventDevToolsReloadLoop;
          navigator.serviceWorker.addEventListener('controllerchange', function (
            event,
          ) {
            // Ensure refresh is only called once.
            // This works around a bug in "force update on reload".
            if (preventDevToolsReloadLoop) return;
            preventDevToolsReloadLoop = true;
            window.location.reload();
          });
          // setup hourly polling
          setInterval(() => {
            registration.update && registration.update();
          }, 60 * 60 * 1000);

          onNewServiceWorker(registration, function () {
            showRefreshUI(registration);
          });
        });
    });
  }

  function showRefreshUI (registration) {
    if (!registration.waiting) {
      // Just to ensure registration.waiting is available before
      // calling postMessage()
      return;
    }
    store.commit('setProjectName', projectName);
    store.commit('swUpdateAvailable');
    EventBus.$on('sw:skipWaiting', () => {
      registration.waiting.postMessage('skipWaiting');
    });
  }

  function onNewServiceWorker (registration, callback) {
    if (registration.waiting) {
      // SW is waiting to activate. Can occur if multiple clients open and
      // one of the clients is refreshed.
      return callback();
    }

    function listenInstalledStateChange () {
      registration.installing.addEventListener('statechange', function (event) {
        if (event.target.state === 'installed') {
          // A new service worker is available, inform the user
          callback();
        }
      });
    }

    if (registration.installing) {
      return listenInstalledStateChange();
    }

    // We are currently controlled so a new SW may be found...
    // Add a listener in case a new SW is found,
    registration.addEventListener('updatefound', listenInstalledStateChange);
  }
}
