import { gql } from '@/core/shared/api';

const tierFragment = `
  id
  price
  length
  displayPrice
  displayLength
`;
const bannerFragment = `
  id
  position
  size
  type
  side
  imageUrl
  fileName
`;
const hourFragment = `
  id
  open
  close
  monday
  tuesday
  wednesday
  thursday
  friday
  saturday
  sunday
`;
const customHourFragment = `
  id
  open
  startTime
  endTime
  startDate
  endDate
  reason
`;

const experienceFragment = `
  id
  title
  description
  type
  equipment
  refundPolicy
  latePolicy
  bookingConfirmationText
  bookingBirthdateRequired
  bookingAdditionalTermsText
  bookingSetupTime
  bookingCushionTime
  bookingIncrementTime
  maxPeoplePerStation
  cancelHours
  imageUrl
  order
  bookingMinimumWindowTime
  hours {
    ${hourFragment}
  }
  customHours {
    ${customHourFragment}
  }
  location {
    id
  }
  launcher {
    id
  }
  tiers {
    ${tierFragment}
  }
  banners {
    ${bannerFragment}
  }
  gameReviewsEnabled
  launcherTrending
  launcherRecentlyAdded
  launcherDifficulty
  launcherGenre
`;

const upcomingBookingFragment = `
  id
  location {
    id
    timezone
    waiverHostProxyUrl
  }
  startTime
  title
  host {
    firstName
    lastName
    email
    phone
    locale
  }
  numPlayers
  numStations
  paymentStatus
  bookingStationTimes {
    startTime
    endTime
  }
  waiverNumSigned
  waiverNumMinors
  waiverReferenceHost
`;

export const getLocationExperiences = async (
  { commit, dispatch, getters },
  locationId,
) => {
  commit('setLoading', true);
  const query = `{
    experiences (locationId: "${locationId}") {
      ${experienceFragment}
    }
  }`;

  try {
    const existing = getters['entities/Experience/all']();
    dispatch('removeEntities', { name: 'Experience', payload: existing });

    const { experiences } = await gql.request(query);
    dispatch('insertEntities', { name: 'Experience', payload: experiences });
  } finally {
    commit('setLoading', false);
  }
};

export const getExperienceDetails = async (
  { getters, commit, dispatch },
  experienceId,
) => {
  const query = `
    query($experienceId: ID) {
      experience(experienceId: $experienceId) {
        ${experienceFragment}
      }
      launchers {
        id
        title
        imageUrl
      }
    }
  `;
  const data = await gql.request(query, { experienceId });
  dispatch('insertEntities', { name: 'Experience', payload: data.experience });
  return data;
};
export const getLaunchers = async ({ getters, commit }) => {
  const query = `
    query {
      launchers {
        id
        title
        imageUrl
      }
    }
  `;
  const data = await gql.request(query);
  return data;
};

export const storeExperience = async ({ dispatch, commit }, experience) => {
  const query = `
    mutation($experience: ExperienceInput) {
      storeExperience(experience: $experience) {
        ${experienceFragment}
      }
    }
  `;
  const { storeExperience } = await gql.request(query, { experience });
  try {
    dispatch('insertEntities', { name: 'Experience', payload: storeExperience });
  } finally {
    commit('setLoading', false);
  }
  return storeExperience;
};

export const saveExperience = async ({ dispatch, commit }, experience) => {
  const {
    id,
    title,
    description,
    type,
    launcher,
    refundPolicy,
    latePolicy,
    bookingConfirmationText,
    bookingBirthdateRequired,
    bookingAdditionalTermsText,
    bookingCushionTime,
    bookingIncrementTime,
    bookingSetupTime,
    maxPeoplePerStation,
    cancelHours,

    banners,
    tiers,
    hours,
    customHours,

    location,
    blobUrl,
    blobFieldName,
    gameReviewsEnabled,
    launcherTrending,
    launcherRecentlyAdded,
    launcherDifficulty,
    launcherGenre,
    bookingMinimumWindowTime,
    equipment,
  } = experience;
  const experienceInput = {
    id,
    location,
    title,
    description,
    type,
    banners,
    launcher: { id: launcher.id },
    refundPolicy,
    latePolicy,
    bookingConfirmationText,
    bookingBirthdateRequired,
    bookingAdditionalTermsText,
    bookingCushionTime,
    bookingIncrementTime,
    bookingSetupTime,
    maxPeoplePerStation,
    cancelHours,
    blobUrl,
    blobFieldName,
    gameReviewsEnabled,
    launcherTrending,
    launcherRecentlyAdded,
    launcherDifficulty,
    launcherGenre,
    bookingMinimumWindowTime,
    equipment,
  };

  if (!experienceInput.id) {
    const query = `
      mutation($experienceInput: ExperienceInput) {
        experience: storeExperience(
          experience: $experienceInput,
        ) {
          id
        }
      }
    `;
    const { banners, ...experienceWithoutBanners } = experienceInput;
    const imageBlob = experience._blob;
    const data = await gql.requestWithBlobs(
      query,
      {
        experienceInput: experienceWithoutBanners,
      },
      imageBlob && [{ name: experience.blobFieldName, file: imageBlob }],
    );
    experienceInput.id = data.experience.id;
  }

  // const bannerSubQueries = banners.filter(({ _delete }) => !_delete).map((banner, i) => {
  //   const vars = {
  //     id: banner.id,
  //     experience: { id: experienceInput.id },
  //     position: banner.position,
  //     size: banner.size,
  //     side: banner.side,
  //     type: banner.type,
  //     imageUrl: banner.imageUrl
  //   };
  //   return `banner${i}: storeExperienceBanner(experienceBanner: ${JSON.stringify(vars).replace(/"([^(")"]+)":/g, '$1:')}) {
  //     id
  //   }`;
  // });
  // const bannerToDeleteSubQueries = banners.filter(({ _delete }) => _delete).map((banner, i) => {
  //   if (!banner.id) return; // don't delete newly created ones
  //   const vars = {
  //     id: banner.id,
  //     experience: { id: experienceInput.id }
  //   };
  //   return `deletedBanner${i}: deleteExperienceBanner(experienceBanner: ${JSON.stringify(vars).replace(/"([^(")"]+)":/g, '$1:')}) {
  //     id
  //   }`;
  // });
  const tierSubQueries = tiers
    .filter(({ _delete }) => !_delete)
    .map((tier, i) => {
      const vars = {
        id: tier.id,
        experience: { id: experienceInput.id },
        price: tier.price,
        length: tier.length,
      };
      return `tier${i}: storeExperienceTier(experienceTier: ${JSON.stringify(
        vars,
      ).replace(/"([^(")"]+)":/g, '$1:')}) {
      id
    }`;
    });
  const tierToDeleteSubQueries = tiers
    .filter(({ _delete }) => _delete)
    .map((tier, i) => {
      const vars = {
        id: tier.id,
        experience: { id: experienceInput.id },
      };
      return `deletedTier${i}: deleteExperienceTier(experienceTier: ${JSON.stringify(
        vars,
      ).replace(/"([^(")"]+)":/g, '$1:')}) {
      id
    }`;
    });
  const hourToDeleteSubQueries = hours
    .filter(({ _delete }) => _delete)
    .map((hour, i) => {
      const vars = {
        id: hour.id,
        experience: { id: experienceInput.id },
      };
      return `deletedHour${i}: deleteExperienceHour(experienceHour: ${JSON.stringify(
        vars,
      ).replace(/"([^(")"]+)":/g, '$1:')}) {
      id
    }`;
    });
  const hourSubQueries = hours
    .filter(({ _delete }) => !_delete)
    .map((hour, i) => {
      const vars = {
        id: hour.id,
        experience: { id: experienceInput.id },
        monday: hour.monday,
        tuesday: hour.tuesday,
        wednesday: hour.wednesday,
        thursday: hour.thursday,
        friday: hour.friday,
        saturday: hour.saturday,
        sunday: hour.sunday,
        open: hour.open,
        close: hour.close,
      };
      return `hour${i}: storeExperienceHour(experienceHour: ${JSON.stringify(
        vars,
      ).replace(/"([^(")"]+)":/g, '$1:')}) {
      id
    }`;
    });
  const customHourToDeleteSubQueries = customHours
    .filter(({ _delete }) => _delete)
    .map((customHour, i) => {
      const vars = {
        id: customHour.id,
        experience: { id: experienceInput.id },
      };
      return `deletedCustomHour${i}: deleteExperienceCustomHour(experienceCustomHour: ${JSON.stringify(
        vars,
      ).replace(/"([^(")"]+)":/g, '$1:')}) {
      id
    }`;
    });
  const customHourSubQueries = customHours
    .filter(({ _delete }) => !_delete)
    .map((customHour, i) => {
      const vars = {
        id: customHour.id,
        experience: { id: experienceInput.id },
        startTime: customHour.startTime,
        endTime: customHour.endTime,
        startDate: customHour.startDate,
        endDate: customHour.endDate,
        open: customHour.open,
        reason: customHour.reason,
      };
      return `customHour${i}: storeExperienceCustomHour(experienceCustomHour: ${JSON.stringify(
        vars,
      ).replace(/"([^(")"]+)":/g, '$1:')}) {
      id
    }`;
    });

  const query = `
    mutation($experienceInput: ExperienceInput) {
      ${tierSubQueries.join('\n')}
      ${tierToDeleteSubQueries.join('\n')}
      ${hourToDeleteSubQueries.join('\n')}
      ${hourSubQueries.join('\n')}
      ${customHourToDeleteSubQueries.join('\n')}
      ${customHourSubQueries.join('\n')}
      experience: storeExperience(
        experience: $experienceInput,
      ) {
        ${experienceFragment}
      }
    }
  `;
  const files = banners.reduce((files, banner) => {
    if (banner._blob) {
      files.push({ name: banner.blobFieldName, file: banner._blob });
    }
    return files;
  }, []);
  const imageBlob = experience._blob;
  if (imageBlob) {
    files.push({ name: experience.blobFieldName, file: imageBlob });
  }
  const data = await gql.requestWithBlobs(query, { experienceInput }, files);
  try {
    dispatch('insertEntities', { name: 'Experience', payload: data.experience });
  } finally {
    commit('setLoading', false);
  }
  return data.experience;
};

export const deleteExperience = async ({ dispatch }, experience) => {
  const query = `
    mutation($experience: ExperienceInput) {
      deleteExperience(experience: $experience) {
        id
      }
    }
  `;
  const data = await gql.request(query, { experience: { id: experience.id } });
  dispatch('removeEntities', {
    name: 'Experience',
    payload: { id: experience.id },
  });
  return data.experience;
};

export const getExperienceTypes = async ({ getters, commit }) => {
  const query = `
    query {
      experienceTypes {
        key
        value
      }
    }
  `;
  const data = await gql.request(query);
  return data;
};

export const getUpcomingBookings = async (
  { commit, dispatch },
  { locationId, startDate, endDate },
) => {
  commit('setLoading', true);
  const query = `query {
    upcomingBookings (locationId: "${locationId}", startDate: "${startDate}", endDate: "${endDate}") {
      ${upcomingBookingFragment}
    }
  }`;

  try {
    const { upcomingBookings } = await gql.request(query);
    return upcomingBookings;
  } finally {
    commit('setLoading', false);
  }
};
