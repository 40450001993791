<script>
import Vue from 'vue';
import ModelMixin from './ModelMixin';
import UiFormItem from './UIFormItem';
import Component from 'vue-class-component';
import { ValidationProvider } from 'vee-validate';

@Component({
  name: 'UISelect',
  mixins: [
    ModelMixin,
  ],
  inheritAttrs: false,
  components: {
    UiFormItem,
    ValidationProvider,
  },
  props: {
    placeholder: {
      type: String,
    },
    ...UiFormItem.options.props,
    rules: {
      type: [String, Object],
      default: '',
    },
    vid: {
      type: String,
      default: undefined,
    },
    fieldNameOverride: {
      type: String,
    },
  },
})
export default class UISelect extends Vue {
  get internalValueWithoutPlaceholder () {
    if (this.placeholder) {
      if (this.internalValue === null || this.internalValue === undefined) return '__placeholder';
      return this.internalValue;
    } else {
      return this.internalValue;
    }
  }

  set internalValueWithoutPlaceholder (value) {
    if (value !== '__placeholder') this.internalValue = value;
  }
}
</script>

<template>
  <ValidationProvider tag="div" :rules="rules" v-slot="{ errors }" :vid="vid" :name="!fieldNameOverride ? title : fieldNameOverride">
    <UiFormItem :title="title" :instructions="instructions" :required="required" :error-msg="errors[0]" :error="!!errors[0]" :root="true">
      <select class="ui-select w-100" v-bind="$attrs" v-on="$listeners" v-model="internalValueWithoutPlaceholder" :class="{ '-placeholder': internalValueWithoutPlaceholder === '__placeholder', '-error': !!errors[0] }">
        <option v-if="placeholder" disabled="disabled" value="__placeholder">{{ placeholder }}</option>
        <slot></slot>
      </select>

    </UiFormItem>
  </ValidationProvider>
</template>

<style scoped lang="postcss">
@import "../../styles";

.ui-select {
  @apply --select;
  min-width: 100%;
  display: block;
  &.-placeholder {
    color: var(--colorManatee);
  }
  &.-error {
    border-color: var(--colorErrorFlat);
    box-shadow: none;
    border-width: 2px;
  }
}
</style>
