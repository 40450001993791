import { gql } from '@/core/shared/api';

export const createTimezoneModel = schema => {
  return new schema.Entity('Timezone', {}, {
    idAttribute: 'key',
  });
};

export default {
  strict: true,
  actions: {
    getTimezones: async ({ commit, dispatch }) => {
      commit('setLoading', true);
      commit('timezone/set', { path: 'isFetchingTimezones', value: true });

      try {
        const query = `{
          timezones {
            key
            title
          }
        }`;

        const { timezones } = await gql.request(query);

        dispatch('insertEntities', {
          name: 'Timezone',
          payload: timezones,
        });
      } finally {
        commit('setLoading', false);
        commit('timezone/set', { path: 'isFetchingTimezones', value: false });
      }
    },
  },
  state: {
    isFetchingTimezones: false,
  },
  mutations: {
    'timezone/set': (state, { path, value }) => {
      state[path] = value;
    },
  },
};
