<template>
  <div class="modal" :class="{ open }">
    <div class="white-overlay" @click="checkForMobileToClose"></div>
    <div class="panel">
      <svg
        v-if="showCloseBtn"
        :class="{ white: title }"
        class="close"
        title="Close Modal"
        @click="$emit('closeModal')"
      >
        <use xlink:href="#icon-x"></use>
      </svg>
      <div v-if="title" class="modal-header">
        <h1 class="title">{{ title }}</h1>
        <div class="line"></div>
      </div>
      <div class="main-content">
        <slot></slot>
      </div>
    </div>
    <div class="spacer flex-auto"></div>
  </div>
</template>

<script>
import Vue from 'vue';
import DomHelper from '../helpers/DomHelper';
import Component from 'vue-class-component';

@Component({
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    showCloseBtn: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      required: false,
    },
    preventScroll: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    $route (to, from) {
      if (!to.meta.keepOpen && this.open && (to.name !== from.name || to.path !== from.path)) this.close();
    },
  },
})
export default class Modal extends Vue {
  get isMobileDevice () {
    return (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1);
  }

  checkForMobileToClose () {
    if (!this.isMobileDevice) {
      this.close();
    }
  }

  close (reason) {
    this.$emit('closeModal', reason);
  }

  created () {
    this.$watch(
      () => this.preventScroll && this.open,
      () => {
        this.$nextTick(() => {
          const el = document.body;
          if (this.open && this.preventScroll) DomHelper.addClass(el, '-no-scroll');
          else DomHelper.removeClass(el, '-no-scroll');
        });
      },
      { immediate: true },
    );
  }

  destroyed () {
    const el = document.body;
    DomHelper.removeClass(el, '-no-scroll');
  }
}
</script>

<style scoped lang="postcss">
@import "../styles";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: var(--zModal);
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media (--tablet) {
    padding: 6rem var(--spacingXS);
  }

  & .modal-header {
    background-color: var(--colorSlate);
    margin: 0 0 0 0;

    & .title {
      @apply --f3;

      color: var(--colorWhite);
      margin: 0;
      padding: var(--spacingSm);
    }

    & .line {
      height: 0.5rem;
      background: var(--colorSBRed);
    }
  }

  &.no-top-space {
    padding-top: 0;
  }

  & .white-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: -1;
  }

  & .panel {
    position: relative;
    background-color: var(--colorWhite);
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.3);
    z-index: 2;
    min-height: 5rem;
    width: 100%;
    flex-shrink: 0;

    @media (--tablet) {
      width: auto;
      max-width: 80%;
    }

    & > svg {
      position: absolute;
      top: calc(var(--spacingSm) - 0.3rem);
      right: var(--spacingSm);
      cursor: pointer;
      width: 3rem;
      height: 3rem;
      fill: var(--colorDarkGrey);
      z-index: 1;

      &.white {
        fill: var(--colorWhite);
      }
    }
  }

  &.show {
    opacity: 0;
    pointer-events: none;

    &.open {
      opacity: 1;
      pointer-events: auto;
    }
  }
}
</style>
