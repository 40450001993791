export const setToken = (state, token) => {
  state.token = token;
};
export const setTenantId = (state, id) => {
  state.tenantId = id;
};
export const setNetToken = (state, netToken) => {
  state.netToken = netToken;
};
export const clearToken = (state, token) => {
  state.token = null;
};
export const setCurrentUserId = (state, currentUserId) => {
  state.currentUserId = currentUserId;
};
