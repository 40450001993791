
import { host } from 'rimless';
import { crossEnv } from '../setup/configureEnvironment';
import { stringify } from '../helpers/url-helper';
import { once } from 'lodash-es';

const setupAuthIframe = once(async function setupAuthIframe ({
  token,
  tenantId,
  netToken,
} = {}) {
  const iframe = document.createElement('iframe');
  const params = stringify({
    env: crossEnv.ENV,
    api: crossEnv.API_URL,
    safari: 'safari' in window ? 1 : 0,
    ...(crossEnv.REVIEW_PREFIX && { review: crossEnv.REVIEW_PREFIX }),
  });
  iframe.src = `https://${crossEnv.ACCOUNT_URL}/auth.html${params}`;
  iframe.style.visibility = 'hidden';
  iframe.style.position = 'absolute';
  iframe.style.left = '-900000px';
  iframe.setAttribute('width', '0');
  iframe.setAttribute('height', '0');
  iframe.setAttribute('border', '0');
  document.body.appendChild(iframe);
  const connection = await host.connect(iframe, {
    authChanged () {
      window.location.reload();
    },
  });
  const auth = await connection.remote.auth({
    token,
    tenantId,
    netToken,
  });
  return auth;
});

export { setupAuthIframe };
