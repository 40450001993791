import { gql } from '@/core/shared/api';
import { stationFragment } from './fragements';

export const getStations = async ({ commit, dispatch }, locationId) => {
  commit('setLoading', true);
  commit('myStation/set', {
    path: 'isFetchingStations',
    value: true,
  });
  const query = `{
    stations {
      ${stationFragment}
    }
  }`;
  try {
    const { stations } = await gql.request(query);
    const result = await dispatch('insertEntities', {
      name: 'Station',
      payload: stations,
    });
    return result;
  } finally {
    commit('setLoading', false);
    commit('myStation/set', {
      path: 'isFetchingStations',
      value: false,
    });
  }
};
