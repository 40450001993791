import { gql } from '../api';

export const createCountryModel = schema => {
  return new schema.Entity('Country', {}, {
    idAttribute: 'cca3',
  });
};

export default {
  strict: true,
  actions: {
    getCountries: async ({ commit, dispatch }, extra = '') => {
      commit('setLoading', true);
      commit('country/set', { path: 'isFetchingCountries', value: true });

      try {
        const query = `{
          countries {
            name
            cca3
            ${extra}
          }
        }`;

        const { countries } = await gql.request(query);

        dispatch('insertEntities', {
          name: 'Country',
          payload: countries,
        });
      } finally {
        commit('setLoading', false);
        commit('country/set', { path: 'isFetchingCountries', value: false });
      }
    },
  },
  state: {
    isFetchingCountries: false,
  },
  mutations: {
    'country/set': (state, { path, value }) => {
      state[path] = value;
    },
  },
};
