import Vue from 'vue';
import { generateId } from '../../helpers/VariousHelper.js';

export const setLoading = (state, loading) => {
  state.loading = loading;
};

export const setNavMenuOpen = (state, open) => {
  state.navMenu.open = open;
};

export const setNavMenuCollapsed = (state, collapsed) => {
  state.navMenu.collapsed = collapsed;
};

export const setNavMenuHide = (state, hide) => {
  state.navMenu.hide = hide;
};

export const setFlash = (state, { message, type, display }) => {
  state.flash.push({
    type,
    display: !display ? 'modal' : display,
    message,
    show: true,
    id: generateId(),
  });
};

export const setLoadingOverlay = (state, value) => {
  state.loadingOverlay = value;
};

export const setGlobalMessage = (state, { message, buttonText, action }) => {
  state.globalMessages.push({
    message,
    buttonText,
    action,
    id: generateId(),
  });
};

export const setFlashShow = (state, show) => {
  if (state.flash.length) {
    Vue.set(state.flash[0], 'show', show);
  }
};

export const removeFlash = state => {
  state.flash.shift();
};

export const setConfirm = (
  state,
  { title, buttons, message, show, icon, closable, preventScroll, onClose },
) => {
  if (typeof show === 'undefined') show = true;
  state.confirm.buttons = buttons ? [...buttons] : [];
  state.confirm.message = message;
  state.confirm.title = title;
  state.confirm.show = show;
  state.confirm.icon = icon;
  state.confirm.closable = closable;
  state.confirm.preventScroll = preventScroll;
  state.confirm.onClose = onClose;
};

export const setTitle = (state, title) => {
  state.title = title;
};

export const setBreadcrumbItems = (state, items) => {
  state.breadcrumbItems = items;
};

export const setLocales = (state, locales) => {
  state.locales = locales;
};

export const swUpdateAvailable = state => {
  state.swUpdateAvailable = true;
};

export const setProjectName = (state, name) => {
  state.projectName = name;
};

export const setShowHeader = (state, showHeader) => {
  state.showHeader = showHeader;
};
export const setCountries = (state, countries) => {
  state.countries = countries;
};
