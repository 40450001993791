import moment from 'moment';
import { sortBy } from 'lodash-es';
export const libraryErrorsCount = (state, getters) => {
  return (
    getters.currentOrganization &&
    getters.currentOrganization.libraryErrorsCount
  );
};
export const pendingAgreementsCount = (state, getters) => {
  return getters.pendingAgreements && getters.pendingAgreements.length;
};
export const remainingDaysToAcceptEULA = (state, getters) => {
  const date =
    getters.currentOrganization &&
    getters.currentOrganization.contentOwnershipLicensesPendingAgreementDate;
  return date && moment(date).diff(moment(), 'days');
};
export const pendingAgreements = (state, getters) => {
  return (
    getters.currentOrganization &&
    getters.currentOrganization.contentOwnershipLicensesPendingAgreements
  );
};
export const allTagsSortedByAlpha = (
  state,
  getters,
  rootState,
  rootGetters,
) => {
  return sortBy(rootGetters['entities/Tag/all'](), 'text') || [];
};
