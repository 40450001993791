import * as actions from './actions';

export default {
  strict: true,
  state: {
    isFetchingLocations: false,
  },
  actions,
  mutations: {
    'admin/set': (state, { path, value }) => {
      state[path] = value;
    },
  },
};
