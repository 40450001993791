export default {
  home: (name, disable) => ({
    name,
    icon: 'icon-home',
    routerLink: { name: 'home' },
    disable,
  }),
  myStations: (name, disable) => ({
    name,
    icon: 'icon-monitor',
    routerLink: { name: 'my-station-index' },
    disable,
  }),
  location: (name, locationId, disable) => ({
    name,
    icon: 'icon-map-pin',
    routerLink: { name: 'location', params: { locationId } },
    disable,
  }),
  monitor: (name, monitorURL, locationId, disable) => ({
    name,
    icon: 'icon-monitor',
    externalLink: `//${monitorURL}/${locationId}/overview`,
    disable,
  }),
  bookings: (name, locationId, disable) => ({
    name,
    routerLink: { name: 'location-bookings', params: { locationId } },
    disable,
  }),
  analytics: (name, locationId, disable) => ({
    name,
    routerLink: { name: 'location-analytics', params: { locationId } },
    disable,
  }),
  experienceIndex: (name, locationId, disable) => ({
    name,
    routerLink: { name: 'experiences-index', params: { locationId } },
    disable,
  }),
  stationIndex: (name, locationId, disable) => ({
    name,
    routerLink: { name: 'station-index', params: { locationId } },
    disable,
  }),
  couponIndex: (name, locationId, disable) => ({
    name,
    routerLink: { name: 'coupon-index', params: { locationId } },
    disable,
  }),
  discountIndex: (name, locationId, disable) => ({
    name,
    routerLink: { name: 'discount-index', params: { locationId } },
    disable,
  }),
  widgetIndex: (name, locationId, disable) => ({
    name,
    routerLink: { name: 'widget-index', params: { locationId } },
    disable,
  }),
  waiverContentIndex: (name, locationId, disable) => ({
    name,
    routerLink: { name: 'waiver-content-index', params: { locationId } },
    disable,
  }),

  content: (name, disable) => ({
    name,
    icon: 'icon-layers',
    routerLink: { name: 'content' },
    disable,
  }),
  marketplace: (name, disable) => ({
    name,
    routerLink: { name: 'marketplace' },
    disable,
  }),
  contentLibrary: (name, disable) => ({
    name,
    routerLink: { name: 'content-library' },
    disable,
  }),
  tags: (name, disable) => ({
    name,
    routerLink: { name: 'tags' },
    disable,
  }),

  admin: (name) => ({
    name,
    icon: 'icon-sliders',
    routerLink: { name: 'admin' },
  }),
  organizationAnalytics: (name, disable) => ({
    name,
    routerLink: { name: 'organization-analytics' },
    disable,
  }),
  organizationSettings: (name, disable) => ({
    name,
    routerLink: { name: 'organization-settings' },
    disable,
  }),
  locationIndex: (name, disable) => ({
    name,
    routerLink: { name: 'location-index' },
    disable,
  }),
  billing: (name) => ({
    name,
    routerLink: { name: 'billing' },
  }),
  users: (name, disable) => ({
    name,
    routerLink: { name: 'users' },
    disable,
  }),
};
