import VueI18n from 'vue-i18n';
import Vue from 'vue';
import rest from '@/core/restAPI';

let i18n;

const fallbackLocale = 'en-US';

export const loadLocale = async (i18n, locale, tags = '') => {
  if (fallbackLocale !== locale) {
    const data = await rest.get(`locale/${locale}`).then(res => res.json());

    if (data) i18n.setLocaleMessage(locale, data);
  }
  i18n.locale = locale;
  if (document && document.querySelector('html')) {
    document.querySelector('html').setAttribute('lang', locale);
  }
};

export default (locale, tags) => {
  Vue.use(VueI18n);
  i18n = new VueI18n({
    locale,
    fallbackLocale,
    messages: {},
    silentTranslationWarn: true,
    formatFallbackMessages: true,
    missing (locale, msg) {
      i18n.setLocaleMessage(locale, {
        ...i18n.messages[locale],
        [msg]: msg,
      });
    },
  });

  loadLocale(i18n, locale, tags);

  return i18n;
};

export function useI18N () {
  return i18n;
}
