export default {
  title: 'SpringboardVR',
  projectName: null,

  swUpdateAvailable: false,

  loading: false,
  loadingOverlay: false,

  showHeader: true,

  globalMessages: [],

  locales: [
    {
      key: 'en-US',
      text: 'English (U.S.)',
    },
  ],

  navMenu: {
    open: false,
    collapsed: false,
    hide: false,
  },

  flash: [],
  confirm: {
    title: '',
    message: '',
    buttons: [],
    show: false,
    icon: null,
    onClose: null,
  },

  breadcrumbItems: [],
};
