<template>
  <div class="global-message" :class="`-${background}`">
    <p class="message" v-if="html" v-html="message"></p>
    <p class="message" v-else>{{ message }}</p>
    <button
      class="btn"
      type="button"
      name="button"
      @click="$emit('click')"
      v-if="!readonly"
    >{{ buttonText }}</button>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      default: 'OK',
    },
    readonly: {
      default: false,
    },
    html: {
      default: false,
    },
    background: {
      type: String,
      default: 'yellow-gradient',
      validator (val) {
        return [
          'yellow-gradient',
          'orange-flat',
        ].includes(val);
      },
    },
  },
};
</script>

<style lang="postcss">
@import "../styles";

.global-message {
  display: flex;
  align-items: center;
  & .btn {
    @apply --buttonSecondary;
    border-radius: 0;
    font-weight: 600;
    width: auto;
    min-width: 15%;
    border: none;
    flex-grow: 0;
    white-space: normal;

    /* font-size: 1.2rem;
    @media (--tablet) {
      font-size: 1.4rem;
    } */
  }
  & .message {
    @apply --f5;
    font-weight: 600;
    padding: 1rem;
    margin: 0;
    text-align: left;
    flex: 1 1 auto;
    & svg {
      cursor: pointer;
      margin-right: 1rem;
      width: 2rem;
      display: inline-block;
      height: 2.3rem;
      vertical-align: middle;
    }
  }
  & .dismissbtn {
    @apply --buttonTinyPrimary;
  }
  z-index: 100;
  @apply --f3;
  margin: 0;
  text-align: center;
  padding: 0;
  padding-left: var(--spacingSm);
  &.-yellow-gradient {
    background: linear-gradient(314.99deg, #fcd95a 0%, #fb8f1e 100%);
  }
  &.-orange-flat {
    background: var(--colorSBRedFlat);
  }
  @apply --f3;
  color: white;
  /* @media (--tablet) {
    font-size: 1.8rem;
  } */
}
</style>
