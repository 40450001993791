export default (
  { beforeRequest },
  { store, localePath = 'locale', locale },
) => {
  beforeRequest.push(({ headers }) => {
    headers.set('Accept-Language', locale || ref(store.state, localePath));
  });
};
function ref (obj, str) {
  if (str) {
    return str.split('.').reduce(function (o, x) {
      return o[x];
    }, obj);
  }
}
