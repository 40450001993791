<template>
  <modal
    class="show confirm"
    @closeModal="close"
    :open="show"
    :prevent-scroll="preventScroll"
    :show-close-btn="closable"
  >
    <div class="padding">
      <UIIcon class="icon" :name="iconComputed" />
      <h5 class="f4" v-if="title">{{ title }}</h5>
      <p v-if="message" v-html="message"></p>
      <slot name="content"></slot>
      <div class="buttons">
        <UIButton
          v-for="button in buttons"
          :key="button.name"
          :ghost="!isPrimary(button.type)"
          :class="(button.type) ? button.type : 'confirm'"
          :disabled="button.disabled || (isPrimary(button.type) ? disableConfirm : false)"
          v-bind="getTo(button)"
          @click="buttonAction(button)"
        >{{ button.name }}</UIButton>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from './Modal';
import UIButton from './ui/UIButton';
import UIIcon from './ui/UIIcon';

export default {
  props: {
    message: String,
    title: String,
    buttons: {
      type: Array,
      validator: buttons => {
        return buttons.findIndex(button => !('name' in button)) === -1;
      },
    },
    show: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: true,
    },
    preventScroll: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'icon-alert-triangle',
    },
    onClose: {
      type: Function,
      required: false,
    },
    disableConfirm: {
      type: Boolean,
      default: false,
    },
  },
  component: {
    Modal,
  },
  computed: {
    iconComputed () {
      return this.icon && this.icon.replace(/^icon-/, '');
    },
  },
  methods: {
    isPrimary (type) {
      return !type || type === 'confirm';
    },
    created () {
      this.title = this.title || this.$t('Are You Sure?');
    },
    async buttonAction (button) {
      if (button.action) {
        // NOTE(Jack): Not all buttons that have an action have to return a Promise, since they will be wrapped with a Promise by this await call.
        //              See calls to setConfirm on the $store to see that they don't require a Promise.
        await button.action();
      }
      this.$emit('closeConfirm');
    },
    getTo (button) {
      return (button.to && { to: button.to });
    },
    close () {
      if (this.closable) {
        this.onClose && this.onClose();
        this.$emit('closeConfirm');
      }
    },
  },
  components: {
    Modal,
    UIButton,
    UIIcon,
  },
};
</script>

<style scoped lang="postcss">
@import "../styles";

.confirm {
  text-align: center;

  @media (--tablet) {
    & >>> .panel {
      min-width: 48rem !important;
    }
  }

  & .padding {
    padding: var(--spacingMd);
  }
  @media (--tablet) {
    & >>> .main-content {
      max-width: 56vw;
    }
  }
  @media (--desktop) {
    & >>> .main-content {
      max-width: 48vw;
    }
  }
  & .icon {
    width: 6rem;
    height: 6rem;
    margin: var(--spacingMd) 0;
    fill: var(--colorFjord) !important;
  }

  /* Expection based on sometimes wanting a confirm to be left aligned. Sorry future devs for this hack */
  & >>> .left-align {
    text-align: left;
  }

  & p {
    @apply --f5;

    margin: var(--spacingSm) 0 var(--spacingLg);
    text-align: center;

    & >>> a {
      @apply --buttonLink;
    }
  }

  & >>> ul {
    list-style-type: disc;

    & li {
      margin: var(--spacingSm);
    }
  }

  & .buttons {
    display: block;

    @media (--tablet) {
      display: flex;
    }

    & .confirm {
      width: 100%;
      flex: 1 1 50%;
    }

    & .cancel,
    & .secondary {
      width: 100%;
      flex: 1 1 50%;
    }

    & .ui-button {
      width: 100%;
      margin-bottom: var(--spacingSm);
      flex: 1 1 auto;

      @media (--tablet) {
        margin-right: var(--spacingSm);
        margin-bottom: 0;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
