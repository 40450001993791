import { normalize, schema } from 'normalizr';
import Vue from 'vue';

const EntitySchema = schema.Entity;
const generateDefaultSchema = name => new EntitySchema(name, {});

class EntityManager {
  store;
  namespace;
  schemaMap = new Map();
  relationsMap = new Map();
  entitiesProxyMap = new WeakMap();

  get entitiesState () {
    return this.store && this.store.state[this.namespace];
  }

  constructor (store, namespace) {
    this.store = store;
    this.namespace = namespace;
  }

  registerEntity (name, schema) {
    if (this.schemaMap.has(name)) return; // TODO allow force or override
    this.schemaMap.set(name, schema);
    this.relationsMap.set(schema, this.createSchemaRelationsSet(schema));
    this.store.registerModule([this.namespace, name], {
      state: () => ({}),
      getters: {
        [`entities/${name}`]: this.createEntityGetter(name, schema),
        [`entities/${name}/all`]: (state, getters) => opts =>
          getters[`entities/${name}`](
            Object.keys(this.store.state[this.namespace][name]),
            opts,
          ),
      },
    });
  }

  registerEntities (collection) {
    for (const name in collection) this.registerEntity(name, collection[name]);
  }

  createSchemaRelationsSet (_schema) {
    const { schema } = _schema;
    const relationsMap = new Map();
    function findRelations (schema, path = '') {
      if (Array.isArray(schema) && schema[0] instanceof EntitySchema) {
        relationsMap.set(path, {
          path,
          schema: [schema[0]],
        });
      } else if (schema instanceof EntitySchema) {
        relationsMap.set(path, {
          path,
          schema,
        });
      } else if (schema === Object(schema)) {
        for (const k in schema) {
          findRelations(schema[k], path ? `${path}.${k}` : k);
        }
      }
    }
    findRelations(schema);
    return relationsMap;
  }

  createEntityGetter (name, schema) {
    return () => {
      const entitiesStore = this.store.state[this.namespace];
      const entityMap = entitiesStore[name];
      const retrieveEntityById = (id) => {
        const item = entityMap[id];
        if (!item) return;
        if (!this.entitiesProxyMap.has(item)) {
          this.entitiesProxyMap.set(
            item,
            new Proxy(item, {
              get: (entity, key) => {
                if (key === 'toJSON') {
                  return () => {
                    return entity;
                  };
                }
                const relations = this.relationsMap.get(schema);
                if (relations.has(key)) {
                  const { schema } = relations.get(key);
                  const relationName = Array.isArray(schema)
                    ? schema[0].key
                    : schema.key;
                  const relationId = entity[key];
                  if (relationId !== null && relationId !== undefined) {
                    return this.store.getters[`entities/${relationName}`](
                      relationId,
                    );
                  }
                }
                return entity[key];
              },
              set () {
                return false;
              },
            }),
          );
        }
        return this.entitiesProxyMap.get(item);
      };
      return (idOrIds, opts = {}) => {
        if (idOrIds === undefined || idOrIds === null) {
          return;
        }
        if (Array.isArray(idOrIds)) {
          return idOrIds.map(id => retrieveEntityById(id, opts));
        } else if (typeof idOrIds === 'function') {
          return Object.values(entityMap).reduce((arr, val) => {
            if (idOrIds(val)) arr.push(retrieveEntityById(val.id, opts));
            return arr;
          }, []);
        } else {
          return retrieveEntityById(idOrIds, opts);
        }
      };
    };
  }

  insertEntities (name, payload) {
    if (!payload || (Array.isArray(payload) && !payload.length)) return [];
    if (!this.schemaMap.has(name)) {
      process.env.NODE_ENV !== 'production' &&
        // eslint-disable-next-line no-console
        console.warn(
          `No schema defined for ${name}: falling to default schema`,
        );
      this.registerEntity(name, generateDefaultSchema(name));
    }
    const schema = this.schemaMap.get(name);
    const { entities: normalizedEntities, result } = normalize(
      payload,
      Array.isArray(payload) ? [schema] : schema,
    );
    for (const [_type, entities] of Object.entries(normalizedEntities)) {
      this.store.commit('entitySet', {
        _type,
        entities,
      });
    }
    return result;
  }

  removeEntities (name, payload, { softDelete } = {}) {
    if (!payload || (Array.isArray(payload) && !payload.length)) return [];
    const items = Array.isArray(payload) ? payload : [payload];
    const target = this.entitiesState[name];
    if (target) {
      const schema = this.schemaMap.get(name);
      const { _idAttribute: idAttribute } = schema;
      items.forEach(payload => {
        let id;
        if (payload === Object(payload) && idAttribute in payload) {
          id = payload[idAttribute];
        } else {
          id = payload;
        }
        if (softDelete) {
          this.store.commit('entitySet', {
            _type: name,
            entities: [{ id, _$deleted: true }],
          });
        } else {
          this.store.commit('entityDelete', {
            target,
            key: id,
          });
        }
      });
    }
  }
}

export default ({
  namespace = 'entities',
  defineSchema = () => ({}),
} = {}) => store => {
  const manager = new EntityManager(store, namespace);
  store.registerModule(
    namespace,
    {
      state: () => ({}),
      getters: {
        entities (state) {
          return state;
        },
      },
      mutations: {
        createEntityScope (state, name) {
          state[name] = {};
        },
        entitySet (state, { entities, _type }) {
          const target = state[_type];
          const { idAttribute } = manager.schemaMap.get(_type);
          const iterableEntities = Object.entries(entities);
          for (const [key, newItem] of iterableEntities) {
            if (newItem[idAttribute] === null) continue;
            const inStoreItem = target[key];
            if (inStoreItem) {
              for (const [key, value] of Object.entries(newItem)) {
                if (key in inStoreItem) inStoreItem[key] = value;
                else Vue.set(inStoreItem, key, value);
              }
            } else Vue.set(target, key, newItem);
          }
        },
        entityDelete (state, { target, key }) {
          Vue.delete(target, key);
        },
      },
      actions: {
        registerEntities (_, fn) {
          return manager.registerEntities(fn(schema, manager.schemaMap));
        },
        insertEntities (_, { name, payload }) {
          return manager.insertEntities(name, payload);
        },
        removeEntities (
          _,
          { name, payload, softDelete },
        ) {
          return manager.removeEntities(name, payload, { softDelete });
        },
      },
    },
    { preserveState: !!store.state.namespace },
  );
  manager.registerEntities(defineSchema(schema, store));
};
