/* eslint-disable import/first */
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import '@/core/shared/setup/polyfills';
import '@/core/setup/env';
import '@/core/setup/vue';

import Vue from 'vue';
import router from '@/core/routes';
import store from '@/core/store';
import gql from '@/core/setup/api';

import '@/core/shared/setup/launchDarkly';
import '@/core/shared/setup/permissions';

import registerServiceWorker from '@/core/shared/setup/registerServiceWorker';
import initSentry from '@/core/setup/sentry';
import initCanny from '@/core/setup/canny';
import initUrlParams from '@/core/setup/urlParams';
import { initStore, initGQL, initRouter, initI18n } from '@/core/shared/compositions';

import { localizeApp, i18n } from '@/core/setup/i18n';

import App from './App';
import Icons from '@/core/components/Icons';

initSentry();
initCanny();
localizeApp(window.sbvrenv.BROWSER_LOCALE);
initUrlParams(store, i18n);

initStore(store);
initGQL(gql);
initRouter(router);
initI18n(i18n);

registerServiceWorker({
  store,
  i18n,
  projectName: 'Operator Panel',
});

/* eslint-disable no-new */

const app = new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render (h) {
    return h(App, [h(Icons)]);
  },
});

if (window.sbvrenv.CYPRESS) {
  window.__VUE_DEBUG__ = {
    app,
    router,
    store,
  };
}
