// see https://stackoverflow.com/questions/31089801/extending-error-in-javascript-with-es6-syntax-babel
export default function ExtendableError (message) {
  this.name = this.constructor.name;
  this.message = message;
  this.stack = (new Error(message)).stack;
}

ExtendableError.prototype = Object.create(Error.prototype);
ExtendableError.prototype.constructor = ExtendableError;

export class CancelRequestError extends ExtendableError {}
export class UploadError extends ExtendableError {}
export class RetryError extends ExtendableError {}
export class TimeoutError extends ExtendableError {}
