import * as actions from './actions';

export default {
  strict: true,
  state: {
    isFetchingStations: false,
  },
  actions,
  mutations: {
    'myStation/set' (state, { path, value }) {
      state[path] = value;
    },
  },
};
