<template>
  <div class="nav-menu-container">
    <div class="darken-overlay" :class="{open}" @click="$emit('closeNavMenu')"></div>
    <div class="nav-menu" :class="{open, collapsed}">
      <div class="stay-put-container">
        <div class="top-of-navigation">
          <div class="logo"></div>
          <router-link class="top-link" v-if="topLink" :to="topLink"></router-link>
        </div>
        <main-navigation
          :class="{open, collapsed}"
          :navigation="navigation"
          :activeSection="activeSection"
          @setActiveSection="setActiveSection"
        />
        <div class="bottom-items">
          <slot name="bottom-items"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainNavigation from './nav-menu/MainNavigation';

export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
    navigation: {
      type: Array,
      required: true,
    },
    topLink: {
      type: [Object, Boolean],
      requred: false,
    },
    applications: Array,
  },
  data () {
    return {
      activeSection: '',
    };
  },
  methods: {
    setActiveSection (id) {
      this.$emit('setCollapsed', false);
      this.activeSection = id;
    },
  },
  watch: {
    open (open) {
      if (!open) {
        this.setActiveSection(null);
      }
    },
  },
  components: {
    MainNavigation,
  },
};
</script>

<style scoped lang="postcss">
@import "../styles";

.nav-menu {
  position: sticky;
  top: 0;
  left: 0;
  height: 100vh;
  width: 85vw;
  max-width: 30rem;
  background-color: var(--colorSlate);
  overflow: hidden;
  transition: transform 0.3s ease;
  transform: translateX(-100%) translateZ(0);
  will-change: transform;
  z-index: var(--zNavMenu);

  & .top-of-navigation {
    position: relative;
    height: 6.7rem;
    display: flex;
    padding: var(--spacingXS) var(--spacingSm);
    background-color: color-mod(#000000 alpha(20%));
    align-items: center;

    & .logo {
      flex: 1 1 100%;
      height: 4.6rem;
      background-image: url(./nav-menu/springboard-logo.svg);
      background-repeat: no-repeat;
      margin-right: var(--spacingSm);
    }

    & .top-link {
      z-index: 3;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  & .main-navigation {
    z-index: 2;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    padding-top: 67px;
  }

  & .stay-put-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    transition: transform 0.3s ease;
    transform: translateX(0) translateZ(0);
    will-change: transform;
  }

  &.open {
    transform: translateX(0) translateZ(0);
  }
}

.darken-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  /* background-color: color-mod(var(--colorSlate) alpha(40%)); */
  z-index: 399;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;
  will-change: opacity;
  transform: translateZ(0);
  z-index: var(--zDarkenOverlay);

  &.open {
    opacity: 1;
    pointer-events: auto;
  }
}
</style>
